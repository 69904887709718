import classNames from "classnames";
import { colord } from "colord";
import { useThemeContext } from "@/react/providers";
import type { MemberTag as MemberTagProps } from "@circle-react/types";
import { EmojiRenderer } from "@circle-react-shared/EmojiRenderer";
import { BadgeV2 } from "@circle-react-shared/uikit/BadgeV2";

const colorTextWhite = "#ffffff";
const colorTextBlack = "#000000";

export const MemberTag = ({
  memberTag,
  className,
}: {
  memberTag: MemberTagProps;
  className?: string;
}) => {
  const { currentAppearance } = useThemeContext();
  const {
    name,
    emoji,
    color,
    display_format: displayFormat,
    custom_emoji_url: customEmojiUrl,
    custom_emoji_dark_url: customEmojiDarkUrl,
    is_public: isPublic,
    is_background_enabled: isBackgroundEnabled,
  } = memberTag || {};

  if (!isPublic) {
    return null;
  }

  interface Styles {
    borderColor?: string;
    backgroundColor?: string;
    color?: string;
  }

  const styles: Styles = {};

  if (isBackgroundEnabled) {
    styles.borderColor = color;
    styles.backgroundColor = color;
    styles.color =
      color && colord(color).isDark() ? colorTextWhite : colorTextBlack;
  }

  return (
    <BadgeV2
      className={classNames(className, {
        "cursor-pointer": displayFormat === "icon",
        "font-medium": true,
        "px-2": true,
      })}
      {...((emoji || customEmojiUrl || customEmojiDarkUrl) && {
        emoji: (
          <EmojiRenderer
            emoji={emoji}
            className="!h-3"
            appearance={currentAppearance}
            customEmojiUrl={customEmojiUrl}
            customEmojiDarkUrl={customEmojiDarkUrl}
          />
        ),
      })}
      {...(displayFormat === "icon"
        ? {
            tooltipText: name,
          }
        : {
            label: name,
          })}
      {...(color && {
        styles: { ...styles },
      })}
    />
  );
};
