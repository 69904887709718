import { useState } from "react";
import classNames from "classnames";
import { usePunditUserContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { DownloadButtonMenu } from "./DownloadButtonMenu";
import { ManageTranscriptMenu } from "./ManageTranscriptMenu";
import { MediaSettingPopover } from "./MediaSettingPopover";
import { ThumbnailButtonMenu } from "./ThumbnailButtonMenu";
import { useMediaSettings } from "./useMediaSettings";

interface MediaSettingsProps {
  mediaTranscript?: any;
  canEditTranscript?: boolean;
  updateAsyncTranscript?: (values: any) => void;
  isUpdatingTranscript?: boolean;
  signedId?: string;
  contentType?: string;
}

export const MediaSettings = ({
  mediaTranscript,
  canEditTranscript,
  updateAsyncTranscript,
  isUpdatingTranscript,
  signedId,
  contentType,
}: MediaSettingsProps) => {
  const { currentCommunityMember } = usePunditUserContext();
  const isAdmin = isCommunityAdmin(currentCommunityMember);
  const isAudioFile = contentType?.match("audio.*");
  const isInsideWebMobileview = window.webview?.isInsideMobileWebview;
  const shouldShowMediaSettings = isAdmin && !isInsideWebMobileview;
  const {
    mediaSettings,
    update: updateMediaSettings,
    isUpdating: isUpdatingMediaSettings,
  } = useMediaSettings(signedId);

  const [openMenu, setOpenMenu] = useState(null);

  const handleMenuToggle = (menuName: any) => {
    setOpenMenu(openMenu === menuName ? null : menuName);
  };

  return (
    <div
      className={classNames(
        "invisible absolute z-10 flex gap-3 group-hover:visible",
        {
          "!visible": openMenu,
          "right-4 top-4": !isAudioFile,
          "bottom-12 right-2.5": isAudioFile,
        },
      )}
    >
      {mediaSettings && shouldShowMediaSettings && (
        <>
          {!isAudioFile && (
            <MediaSettingPopover
              isOpen={openMenu === "thumbnail"}
              onToggle={() => handleMenuToggle("thumbnail")}
              iconType="16-file-image"
            >
              <ThumbnailButtonMenu
                mediaSettings={mediaSettings}
                updateMediaSettings={updateMediaSettings}
                isUpdatingMediaSettings={isUpdatingMediaSettings}
                closePopover={() => handleMenuToggle("thumbnail")}
              />
            </MediaSettingPopover>
          )}
          <MediaSettingPopover
            isOpen={openMenu === "download"}
            onToggle={() => handleMenuToggle("download")}
            iconType="16-download"
          >
            <DownloadButtonMenu
              mediaSettings={mediaSettings}
              updateMediaSettings={updateMediaSettings}
              isUpdatingMediaSettings={isUpdatingMediaSettings}
              closePopover={() => handleMenuToggle("download")}
            />
          </MediaSettingPopover>
        </>
      )}
      {canEditTranscript && (
        <MediaSettingPopover
          isOpen={openMenu === "transcript"}
          onToggle={() => handleMenuToggle("transcript")}
          iconType="16-transcript"
        >
          <ManageTranscriptMenu
            mediaTranscript={mediaTranscript}
            updateAsync={updateAsyncTranscript}
            isUpdating={isUpdatingTranscript}
          />
        </MediaSettingPopover>
      )}
    </div>
  );
};
