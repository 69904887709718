import { Row } from "@circle-react-uikit/SkeletonLoader/components";

export const Transcription = () => (
  <div className="h-10 w-full">
    <div className="flex cursor-pointer items-start gap-3 p-1">
      <Row lg short />
      <Row lg almostFull />
    </div>
    <div className="flex cursor-pointer items-start gap-3 p-1">
      <Row lg short />
      <Row lg almostFull />
    </div>
    <div className="flex cursor-pointer items-start gap-3 p-1">
      <Row lg short />
      <Row lg almostFull />
    </div>
  </div>
);
