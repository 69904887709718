import { t } from "i18n-js";

export const findGroupIndexAndInsideGroupIndex = (
  flatIndex: any,
  groupSizes: any,
) => {
  let groupIndex = 0;
  let insideGroupIndex = 0;
  let totalSize = 0;

  for (const size of groupSizes) {
    totalSize += size;
    if (flatIndex < totalSize) {
      insideGroupIndex = flatIndex - (totalSize - size);
      break;
    }
    groupIndex++;
  }

  return { groupIndex, insideGroupIndex };
};

export const getEntityTypeLabel = (type: any) => {
  const entityTypes: any = {
    space: t("tiptap.extensions.entities.spaces"),
    post: t("tiptap.extensions.entities.posts"),
    event: t("tiptap.extensions.entities.events"),
    lesson: t("tiptap.extensions.entities.lessons"),
    course: t("tiptap.extensions.entities.courses"),
  };

  return entityTypes[type];
};
