import type { ReactNode } from "react";
import { DragOverlay as DndDragOverlay } from "@dnd-kit/core";
import type { DragOverlayProps as DndDragOverlayProps } from "@dnd-kit/core";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import { createPortal } from "react-dom";
import { useFrame } from "react-frame-component";
import { useSortableListData } from "../Provider/SortableListDataProvider";
import { ListItemMemo } from "./ListItemMemo";

export interface DragOverlayProps extends DndDragOverlayProps {
  portalTo?: HTMLElement | null;
  children: ReactNode;
  shouldRestrictToParent?: boolean;
}

export const DragOverlay = ({
  children,
  portalTo = null,
  shouldRestrictToParent = false,
  ...rest
}: DragOverlayProps) => {
  const frame = useFrame();
  const documentElement = frame?.document?.documentElement;
  const { activeItem, useAnimation: hasAnimation } = useSortableListData();
  const target = portalTo || documentElement || window.document.body;

  return (
    <>
      {createPortal(
        <DndDragOverlay
          adjustScale
          dropAnimation={{
            duration: hasAnimation ? 100 : 0,
          }}
          {...(shouldRestrictToParent && {
            modifiers: [restrictToParentElement],
          })}
          {...rest}
        >
          {activeItem ? (
            <ListItemMemo item={activeItem} isDragOverlay>
              {children}
            </ListItemMemo>
          ) : null}
        </DndDragOverlay>,
        target,
      )}
    </>
  );
};
