import { useCallback, useEffect, useRef, useState } from "react";
import AwsS3 from "@uppy/aws-s3";
import Uppy from "@uppy/core";
import { MAX_RECORDING_DURATION_MINUTES, RECORDER_STATUS } from "../constants";
import { getStatusProperties, isPermissionGranted } from "../helpers";
import { processFileRequest, signedUploadUrlRequest } from "../requests";

export const useRecorder = ({ recordingId }) => {
  const [status, setStatus] = useState(RECORDER_STATUS.RECORDING);
  const uppy = useRef(null);
  const streamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);
  const recordingTimeoutRef = useRef(null);
  const cancelRecordingRef = useRef(false);
  const objectIdRef = useRef(null);
  const { isRecording, isBusy, isError } = getStatusProperties(status);

  const startRecording = useCallback(async () => {
    try {
      isPermissionGranted(setStatus);

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      streamRef.current = stream;
      mediaRecorderRef.current = new MediaRecorder(stream);

      mediaRecorderRef.current.addEventListener("dataavailable", e => {
        chunksRef.current.push(e.data);
      });

      mediaRecorderRef.current.addEventListener("stop", () => {
        if (cancelRecordingRef.current) {
          cancelRecordingRef.current = false;
          return;
        }
        setStatus(RECORDER_STATUS.UPLOADING);
        const audioBlob = new Blob(chunksRef.current, { type: "audio/mp4" });
        uppy.current = new Uppy();
        uppy.current.use(AwsS3, {
          getUploadParameters: file =>
            signedUploadUrlRequest(file, setUploadError),
        });

        uppy.current.addFile({
          data: audioBlob,
        });

        uppy.current.upload();

        uppy.current.on("upload-success", (_file, response) => {
          const objectId = response.uploadURL.split(".com/")[1].split("/")[0];
          objectIdRef.current = objectId;
          processFileRequest(objectId, recordingId, setServerError);
          setStatus(RECORDER_STATUS.PROCESSING);
        });

        uppy.current.on("upload-error", () => setUploadError());
      });

      mediaRecorderRef.current.start();
      setStatus(RECORDER_STATUS.RECORDING);

      recordingTimeoutRef.current = setTimeout(
        () => stopRecording(),
        MAX_RECORDING_DURATION_MINUTES * 60 * 1000,
      );
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  }, [stopRecording, recordingId]);

  const stopRecording = useCallback(() => {
    if (mediaRecorderRef.current && streamRef.current) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current = null;
      streamRef.current.getTracks().forEach(track => track.stop());
      streamRef.current = null;
      chunksRef.current = [];
      clearTimeout(recordingTimeoutRef.current);
    }
  }, []);

  const cancelRecording = useCallback(() => {
    cancelRecordingRef.current = true;
    stopRecording();
  }, [stopRecording]);

  const reupload = () => {
    setStatus(RECORDER_STATUS.UPLOADING);
    uppy.current.retryAll();
  };

  const reprocess = () => {
    setStatus(RECORDER_STATUS.PROCESSING);
    processFileRequest(objectIdRef.current, recordingId);
  };

  const setServerError = () => setStatus(RECORDER_STATUS.SERVER_ERROR);

  const setUploadError = () => setStatus(RECORDER_STATUS.UPLOAD_ERROR);

  const onSubmit = () => {
    if (isError) {
      if (status === RECORDER_STATUS.UPLOAD_ERROR) {
        reupload();
      } else {
        reprocess();
      }
    } else {
      stopRecording();
    }
  };

  useEffect(() => {
    startRecording();
    return () => {
      cancelRecording();
    };
  }, [startRecording, cancelRecording]);

  return {
    setServerError,
    status,
    isRecording,
    isBusy,
    isError,
    onSubmit,
  };
};
