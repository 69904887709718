import PropTypes from "prop-types";
import { uniq } from "lodash";
import { useQuery } from "react-query";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { LinkItem } from "./LinkItem";
import { MessageItem } from "./MessageItem";

export const LinkPreviews = ({ linkMarks, messageAttachments }) => {
  const linkHrefs = uniq(linkMarks.map(mark => mark?.attrs?.href));
  const { data: unfurledLinks, isLoading } = useQuery(
    internalApi.richTextFields.unfurlURLs({ params: { urls: linkHrefs } }),
    {
      enabled: linkHrefs.length > 0,
      // This is to prevent empty screen when new items are fetched
      keepPreviousData: true,
      initialData: [],
    },
  );

  const hasAttributesForPreview = linkPreview =>
    linkPreview.title || linkPreview.description;

  // unfurledLinks object will hold all previously unfurled links
  // Show just the ones that are present in editor content
  const filteredUnfurledLinks = unfurledLinks.filter(
    unfurledLink =>
      linkHrefs.includes(unfurledLink.url) &&
      hasAttributesForPreview(unfurledLink),
  );

  if (
    !(filteredUnfurledLinks.length || messageAttachments.length) ||
    isLoading
  ) {
    return null;
  }

  return (
    <div className="flex flex-wrap gap-2 pb-2">
      {messageAttachments.map(message => (
        <MessageItem key={message.id} message={message} />
      ))}
      {filteredUnfurledLinks.map(({ url, ...meta }) => (
        <LinkItem key={url} url={url} meta={meta} />
      ))}
    </div>
  );
};

LinkPreviews.propTypes = {
  linkMarks: PropTypes.arrayOf(PropTypes.object).isRequired,
};
