import { forwardRef } from "react";
import type { ReactNode } from "react";
import classNames from "classnames";

interface NoticeProps {
  children: ReactNode;
  iconEl?: ReactNode;
  actionButtonEl?: ReactNode;
  className?: string;
}

export const Notice = forwardRef<HTMLDivElement, NoticeProps>(function Notice(
  { children, iconEl, actionButtonEl, className },
  ref,
) {
  return (
    <div
      ref={ref}
      className={classNames("flex gap-3 rounded-lg px-4 py-3", className)}
    >
      {iconEl}
      <div className="flex-1">{children}</div>
      {actionButtonEl}
    </div>
  );
});
