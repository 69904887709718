import { Node } from "@tiptap/core";
import { ReactNodeViewRenderer, mergeAttributes } from "@tiptap/react";
import { Renderer } from "./Renderer";

export const FileExtension = Node.create({
  name: "file",
  group: "block",
  atom: true,
  addAttributes: () => ({
    signed_id: {
      default: null,
    },
  }),
  parseHTML: () => [{ tag: "file-react" }],
  renderHTML: ({ HTMLAttributes }) => [
    "file-react",
    mergeAttributes(HTMLAttributes),
  ],
  addNodeView: () => ReactNodeViewRenderer(Renderer),
});
