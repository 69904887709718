import classnames from "classnames";
import { useBEMClasses } from "@circle-react/hooks/utils/useBEMClasses";

export interface RowProps {
  // Width & Height
  badge?: boolean;
  square?: boolean;
  squareLarge?: boolean;
  squareExtraLarge?: boolean;
  // Width
  short?: boolean;
  long?: boolean;
  extraLong?: boolean;
  half?: boolean;
  almostFull?: boolean;
  full?: boolean;
  // Height
  xs?: boolean;
  sm?: boolean;
  md?: boolean;
  lg?: boolean;
  xl?: boolean;
  xxl?: boolean;
  x2l?: boolean;
  className?: string;
  isDark?: boolean;
}

export const Row = ({ className = "", isDark = false, ...props }: RowProps) => {
  const blockElement = classnames("skeleton-loader__row", className);
  const { modifiers } = useBEMClasses(blockElement, props);

  return (
    <div
      className={classnames(blockElement, modifiers, {
        "!bg-tertiary": !isDark,
        "!bg-overlay-dark": isDark,
      })}
    />
  );
};
