import PropTypes from "prop-types";
import { Icon } from "@circle-react/components/shared/Icon";

export const LinkItem = ({ url, meta }) => {
  const formattedUrl = url.replace(/(^\w+:|^)\/\//, "");
  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      className="border-primary flex min-w-[150px] max-w-[300px] shrink-0 flex-col rounded-md border px-4 py-3 md:max-w-[246px]"
    >
      <div className="text-dark mb-0.5 truncate text-sm font-medium">
        {meta.title}
      </div>
      {meta.description && (
        <div className="text-light mb-1.5 line-clamp-2 flex-1 text-xs">
          {meta.description}
        </div>
      )}
      <div className="text-default flex items-center">
        {meta.favicon ? (
          <img
            loading="eager"
            alt=""
            src={meta.favicon}
            className="mr-1.5 h-4 w-4"
          />
        ) : (
          <Icon type="12-globe" size={12} className="mr-1 !w-3" />
        )}
        <div className="flex-1 truncate text-xs">{formattedUrl}</div>
      </div>
    </a>
  );
};

LinkItem.propTypes = {
  meta: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    favicon: PropTypes.string,
  }),
  url: PropTypes.string.isRequired,
};
