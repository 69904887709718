import type { ComponentPropsWithoutRef, Ref } from "react";
import classnames from "classnames";
import type { ButtonVariant } from "@circle-react-uikit/Button";

type HTMLAnchor = ComponentPropsWithoutRef<"a">;

export interface LinkButtonProps extends HTMLAnchor {
  href: string;
  full?: boolean;
  small?: boolean;
  large?: boolean;
  extraLarge?: boolean;
  forwardedRef?: Ref<HTMLAnchorElement>;
  variant?: ButtonVariant;
  onClick?: never;
}

export const LinkButton = ({
  href,
  variant = "primary",
  children,
  full,
  small,
  extraLarge,
  large,
  className,
  forwardedRef,
  rel = "noopener noreferrer",
  ...rest
}: LinkButtonProps) => (
  <a
    {...rest}
    href={href}
    rel={rel}
    ref={forwardedRef}
    className={classnames(
      "focus-visible:!outline-secondary inline-block rounded-md font-bold leading-5 transition-colors duration-150 focus-visible:!outline focus-visible:!outline-2 focus-visible:!outline-offset-2",
      {
        "px-8 py-3 leading-normal": extraLarge,
        "px-6 py-2 leading-normal": large,
        "px-[18px] py-[6px] text-sm": !large && !small && !extraLarge,
        "px-2 py-1 text-xs": small,
        "w-full": full,
        "bg-brand !text-brand-button hover:opacity-90": variant === "primary",
        "border-secondary bg-primary !text-darkest hover:bg-tertiary border !font-medium":
          variant === "secondary",
        "border-hover bg-primary !text-darkest hover:bg-primary border":
          variant === "secondary-light",
        "!text-link bg-transparent": variant === "plain",
        "border-v2-danger bg-v2-danger border !text-white hover:opacity-90":
          variant === "danger",
        "border-v2-danger bg-primary !text-v2-danger border !font-medium hover:opacity-90":
          variant === "danger-outline",
        "border-circle-button bg-circle-button !text-circle-button hover:border-circle-button-hover hover:bg-circle-button-hover border":
          variant === "circle",
        "!text-default hover:!text-darkest bg-transparent !p-0 font-medium transition-colors":
          variant === "text",
        "!text-darkest hover:bg-tertiary !font-semibold":
          variant === "circle-plain",
        "!text-circle !p-0 !font-semibold": variant === "circle-text",
      },
      className,
    )}
  >
    {children}
  </a>
);
