import { Portal } from "@material-ui/core";
import type { Editor } from "@tiptap/react";
import { t } from "i18n-js";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { EmojiPickerV3 } from "@circle-react-shared/uikit/EmojiPickerV3";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import {
  getCurrentRange,
  showSlashCommandMenu,
} from "@circle-react-shared/uikit/TipTapBlockEditor/Toolbar/helpers";

interface CommonHandlerProps {
  editor: Editor;
  range: {
    from: number;
    to: number;
  };
}
interface TiptapToolbarProps {
  editor: Editor;
  portalId: string;
  showImagePickerModal: (props: CommonHandlerProps) => void;
  showFileUploadModal: (
    props: CommonHandlerProps & {
      type?: "file" | "video" | "audio";
    },
  ) => void;
  showGiphyPickerModal: (props: CommonHandlerProps) => void;
  showPollModal: (props: CommonHandlerProps) => void;
  disabledExtensions: any;
}

export const Toolbar = ({
  editor,
  portalId,
  showImagePickerModal,
  showFileUploadModal,
  showGiphyPickerModal,
  showPollModal,
  disabledExtensions,
}: TiptapToolbarProps) => {
  if (!editor || !portalId) return null;
  return (
    <Portal container={() => document.getElementById(portalId)}>
      <ul className="flex list-none items-center gap-1.5">
        <li>
          <TippyV2
            placement="top"
            interactive={false}
            content={t("tiptap.open_slash_commands_menu")}
            dataTestId="open_slash_commands_menu"
          >
            <IconButton
              iconSize={20}
              iconClassName="!text-dark"
              name="20-add-circle"
              ariaLabel={t("tiptap.open_slash_commands_menu")}
              onClick={() => {
                showSlashCommandMenu({ editor });
              }}
            />
          </TippyV2>
        </li>
        <li>
          {!disabledExtensions["entities"] && (
            <TippyV2
              placement="top"
              interactive={false}
              content={t("tiptap.add_entity")}
              dataTestId="add_entity_list"
            >
              <IconButton
                iconSize={20}
                iconClassName="!text-dark"
                ariaLabel={t("tiptap.add_entity")}
                name="20-tiptap-entity"
                onClick={() => {
                  editor.chain().focus().insertContent(" #").run();
                }}
              />
            </TippyV2>
          )}
        </li>
        <li className="hidden md:flex">
          <TippyV2
            placement="top"
            interactive={false}
            content={t("tiptap.attach_file")}
            dataTestId="attach_file"
          >
            <IconButton
              iconSize={20}
              iconClassName="!text-dark"
              ariaLabel={t("tiptap.attach_file")}
              name="20-attach"
              onClick={() =>
                showFileUploadModal({
                  editor,
                  range: getCurrentRange({ editor }),
                })
              }
            />
          </TippyV2>
        </li>
        <li>
          <TippyV2
            placement="top"
            interactive={false}
            content={t("tiptap.attach_video")}
            dataTestId="attach_video"
          >
            <IconButton
              iconSize={20}
              iconClassName="!text-dark"
              name="20-video"
              ariaLabel={t("tiptap.attach_video")}
              onClick={() =>
                showFileUploadModal({
                  editor,
                  range: getCurrentRange({ editor }),
                  type: "video",
                })
              }
            />
          </TippyV2>
        </li>
        <li className="hidden md:flex">
          <TippyV2
            placement="top"
            interactive={false}
            content={t("tiptap.add_gif")}
            dataTestId="add_gif"
          >
            <IconButton
              iconSize={20}
              iconClassName="!text-dark"
              name="20-gif"
              ariaLabel={t("tiptap.add_gif")}
              onClick={() =>
                showGiphyPickerModal({
                  editor,
                  range: getCurrentRange({ editor }),
                })
              }
            />
          </TippyV2>
        </li>
        <li>
          <TippyV2
            placement="top"
            interactive={false}
            content={t("tiptap.add_image")}
            dataTestId="add_image"
          >
            <IconButton
              iconSize={20}
              iconClassName="!text-dark"
              ariaLabel={t("tiptap.add_image")}
              onClick={() =>
                showImagePickerModal({
                  editor,
                  range: getCurrentRange({ editor }),
                })
              }
              name="20-file-image"
            />
          </TippyV2>
        </li>
        {!disabledExtensions["emojipicker"] && (
          <TippyV2
            placement="top"
            interactive={false}
            content={t("tiptap.add_emoji")}
            dataTestId="add_emoji"
          >
            <EmojiPickerV3
              placement="top-start"
              onEmojiSelect={emojiData =>
                editor.chain().focus().insertContent(emojiData.emoji).run()
              }
              as="li"
              shouldEmojiPickerRenderAsPortal={false}
              targetWrapperClassName="!flex"
              target={
                <IconButton
                  iconSize={20}
                  ariaLabel={t("tiptap.add_emoji")}
                  iconClassName="!text-dark"
                  name="20-emoji"
                />
              }
            />
          </TippyV2>
        )}
        <li>
          {!disabledExtensions["poll"] && (
            <TippyV2
              placement="top"
              interactive={false}
              content={t("tiptap.add_poll")}
              dataTestId="add_poll"
            >
              <IconButton
                iconSize={20}
                iconClassName="!text-dark"
                name="20-editor-poll"
                ariaLabel={t("tiptap.add_poll")}
                onClick={() =>
                  showPollModal({
                    editor,
                    range: getCurrentRange({ editor }),
                  })
                }
              />
            </TippyV2>
          )}
        </li>
      </ul>
    </Portal>
  );
};
