import PropTypes from "prop-types";
import { RadioGroup } from "@headlessui/react";
import classNames from "classnames";

export const RadioCard = ({ children, value, className = "" }) => (
  <RadioGroup.Option
    value={value}
    className={({ checked }) =>
      classNames(
        "bg-primary hover:border-secondary relative flex cursor-pointer rounded-lg border p-6 shadow-sm transition-colors focus:outline-none",
        {
          "border-secondary": checked,
          "border-primary": !checked,
        },
        className,
      )
    }
  >
    {({ checked }) => (
      <>
        <span className="flex flex-1">
          <span className="flex flex-col">{children}</span>
        </span>
        <span
          className={classNames(
            "mt-1.5 flex h-4 w-4 shrink-0 items-center justify-center rounded-full border border-transparent",
            {
              "bg-toggle-active": checked,
              "bg-primary": !checked,
            },
          )}
          aria-hidden="true"
        >
          <span className="bg-primary h-1.5 w-1.5 rounded-full" />
        </span>
      </>
    )}
  </RadioGroup.Option>
);

RadioCard.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
};
