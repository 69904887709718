import PropTypes from "prop-types";
import classnames from "classnames";
import "./styles.scss";

export const variants = {
  brand: "brand",
  default: "default",
  success: "success",
  warning: "warning",
  danger: "danger",
  info: "info",
  white: "white",
};

/** @deprecated Please use the new Badge at "@circle-react-uikit/BadgeV2" */
export const Badge = ({ variant = variants.default, children, ...props }) => {
  const rootName = "react-badge";
  return (
    <span
      {...props}
      className={classnames(rootName, {
        [`${rootName}--brand`]: variant === variants.brand,
        [`${rootName}--default`]: variant === variants.default,
        [`${rootName}--info`]: variant === variants.info,
        [`${rootName}--success`]: variant === variants.success,
        [`${rootName}--warning`]: variant === variants.warning,
        [`${rootName}--danger`]: variant === variants.danger,
        ["bg-primary !text-black"]: variant === variants.white,
      })}
    >
      {children}
    </span>
  );
};

Badge.propTypes = {
  variant: PropTypes.oneOf(Object.values(variants)),
  children: PropTypes.node,
};
