import PropTypes from "prop-types";
import classnames from "classnames";
import classNames from "classnames";
import { useBEMClasses } from "@circle-react/hooks/utils/useBEMClasses";

export const Avatar = props => {
  const blockElement = "skeleton-loader__avatar";
  const { modifiers } = useBEMClasses(blockElement, props);
  const { isFullRounded = true } = props;

  return (
    <div className={classnames(blockElement, modifiers)}>
      <div
        className={classNames(
          "skeleton-loader__avatar-content !bg-tertiary",
          isFullRounded ? "!rounded-full" : "!rounded-md",
        )}
      />
    </div>
  );
};

Avatar.propTypes = {
  sm: PropTypes.bool,
  lg: PropTypes.bool,
};
