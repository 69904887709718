import { useContext } from "react";
import NiceModal from "@ebay/nice-modal-react";
import { useCommunityAiUpgradeModal } from "@/react/components/SettingsApp/CommunityAi/UpgradeModal";
import { COPILOT_FEATURE } from "@/react/components/SettingsApp/CommunityAi/UpgradeModal/constants";

interface PlanUpgradeModalReturn {
  openUpgradeModal: () => void;
}

export const usePlanUpgradeModal = (): PlanUpgradeModalReturn => {
  const communityAiModal = useCommunityAiUpgradeModal();

  const closeAllModals = async () => {
    await Promise.all(Object.keys(modals).map(modal => NiceModal.hide(modal)));
  };

  const modals = useContext(NiceModal.NiceModalContext);

  const openUpgradeModal = () => {
    communityAiModal
      .show({
        feature: COPILOT_FEATURE,
        onSuccess: closeAllModals,
      })
      .catch(error => {
        console.error("Error showing Community AI Modal:", error);
      });
  };

  return { openUpgradeModal };
};
