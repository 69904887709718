import { useState } from "react";
import { useTipTapEditorContext } from "..";
import { Popover } from "@headlessui/react";
import { t } from "i18n-js";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";
import { TippyV2 as Tippy } from "@circle-react-shared/TippyV2";
import { GiphyPicker } from "../GiphyPicker";
import { getPortalTarget } from "../utilities/getPortalTarget";
import { MenuIconButton } from "./MenuIconButton";

export interface GifPopoverProps {
  isLight: boolean;
}

export const GifPopover = ({ isLight }: GifPopoverProps) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "top",
    modifiers: [
      {
        name: "offset",
        enabled: true,
        options: {
          offset: [80, 10],
        },
      },
    ],
  });

  const { portalTargetId } = useTipTapEditorContext();

  const portalTo = getPortalTarget(portalTargetId);

  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Tippy
            placement="bottom"
            interactive={false}
            content={t("tiptap.add_gif")}
            dataTestId="add_gif_message"
          >
            <Popover.Button
              as={MenuIconButton}
              ref={setReferenceElement}
              isLight={isLight}
              icon="20-gif-2"
              aria-label={t("tiptap.add_gif")}
            />
          </Tippy>
          {open &&
            createPortal(
              <Popover.Panel
                static
                /* NOTE: z-index is set here to ensure that the popover appears above ModalV2 */
                className="z-[1060]"
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
              >
                <GiphyPicker close={close} />
              </Popover.Panel>,
              portalTo || document.body,
            )}
        </>
      )}
    </Popover>
  );
};
