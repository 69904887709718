import { NodeViewWrapper } from "@tiptap/react";
import { LoaderRow } from "@circle-react-shared/LoaderRow";
import { PollWrapper } from "@circle-react-shared/uikit/TipTapBlockEditor/PollExtension/PollDisplay/PollWrapper";
import { usePoll } from "@circle-react-shared/uikit/TipTapBlockEditor/PollExtension/PollDisplay/api";

export const PollDisplay = ({ node, editor, deleteNode }: any) => {
  const { data: poll, isLoading } = usePoll({
    sgid: node.attrs?.sgid,
    options: {
      enabled: !!node.attrs?.sgid,
    },
  });

  return (
    <NodeViewWrapper as="div">
      {isLoading || !poll ? (
        <div className="my-4">
          <LoaderRow />
        </div>
      ) : (
        <PollWrapper
          deleteNode={deleteNode}
          isEditable={editor.isEditable}
          poll={poll}
        />
      )}
    </NodeViewWrapper>
  );
};
