export const DROPDOWN_SIZE = {
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
  EXTRA_LARGE: "extra-large",
  FULL: "full",
} as const;

export type DropdownSize = (typeof DROPDOWN_SIZE)[keyof typeof DROPDOWN_SIZE];

export const DROPDOWN_DIRECTION = [
  "auto",
  "auto-start",
  "auto-end",
  "top",
  "top-start",
  "top-end",
  "bottom",
  "bottom-start",
  "bottom-end",
  "right",
  "right-start",
  "right-end",
  "left",
  "left-start",
  "left-end",
] as const;

export type DropdownDirection = (typeof DROPDOWN_DIRECTION)[number];

export const defaultAnimations: DropdownAnimation = {
  enter: "transition ease-out duration-100",
  enterFrom: "transform opacity-0 scale-95",
  enterTo: "transform opacity-100 scale-100",
  leave: "transition ease-in duration-75",
  leaveFrom: "transform opacity-100 scale-100",
  leaveTo: "transform opacity-0 scale-95",
};

export interface DropdownAnimation {
  enter: string;
  enterFrom: string;
  enterTo: string;
  leave: string;
  leaveFrom: string;
  leaveTo: string;
}
