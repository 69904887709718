import { isTextSelection } from "@tiptap/react";

export const shouldShowToolbar = ({ view, state, from, to }: any) => {
  const { doc, selection } = state;
  const { empty: isSelectionEmpty } = selection;
  const node = selection.$from.node();

  const isEmptyTextBlock =
    !doc.textBetween(from, to).length && isTextSelection(selection);

  const hasEditorFocus = view.hasFocus();
  const isValidBlock = ["paragraph", "heading"].includes(node.type.name);

  return (
    hasEditorFocus && !isSelectionEmpty && isValidBlock && !isEmptyTextBlock
  );
};
