import { Container } from "./components/Container";
import { DragOverlay } from "./components/DragOverlay";
import { Item } from "./components/Item";
import { List } from "./components/List";

const SortableList = Object.assign(Container, {
  List,
  Item,
  DragOverlay,
});

export { SortableList };

export * from "./Provider";
