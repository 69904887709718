import classnames from "classnames";
import isEmpty from "lodash/isEmpty";
import { Link } from "react-router-dom";
import { BadgeComponent } from "./BadgeComponent";

export interface TabProps {
  active: boolean;
  label: string;
  variant: string;
  onClick: any;
  badge?: any;
  to?: string;
  disabled?: boolean;
  className?: string;
}

export const Tab = ({
  active,
  label,
  variant,
  onClick,
  badge = {},
  to = "",
  disabled = false,
  className = "",
}: TabProps) => {
  const tabClassnames = classnames(
    "react-tabs__tab",
    `react-tabs__tab--${variant}`,
    "!text-dark",
    {
      "react-tabs__tab--active": active,
      "react-tabs__tab--disabled": !active && disabled,
    },
    className,
  );

  const spanClassNames = classnames({
    "text-light font-normal": !active && !disabled,
    "!text-dark font-semibold": active,
    "text-secondary-muted": !active && disabled,
  });

  const onClickHandler = () => {
    if (!disabled) {
      onClick(label);
    }
  };

  if (!disabled && to) {
    return (
      <Link
        className={tabClassnames}
        to={to}
        onClick={onClick}
        role="tab"
        aria-selected={active}
      >
        <span className={spanClassNames}>{label}</span>
        {!isEmpty(badge) && <BadgeComponent badge={badge} />}
      </Link>
    );
  }
  return (
    <button
      type="button"
      className={tabClassnames}
      onClick={onClickHandler}
      aria-selected={active}
      role="tab"
      tabIndex={0}
    >
      <span className={spanClassNames}>{label}</span>
      {!isEmpty(badge) && <BadgeComponent badge={badge} />}
    </button>
  );
};
