import { Icon } from "@circle-react-shared/Icon";

export const LoadingIcon = () => (
  <span className="flex animate-spin items-center overflow-hidden">
    <Icon
      type="20-stardust-gradient"
      size={20}
      useWithFillCurrentColor
      useWithStrokeCurrentColor
    />
  </span>
);
