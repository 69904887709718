import PropTypes from "prop-types";
import { t } from "i18n-js";
import { ErrorTemplate } from "./ErrorTemplate";

//404 error
export const PageNotFound = ({ errorMessage, ...passThroughProps }) => (
  <ErrorTemplate
    title={errorMessage || t("error_page.page_not_found")}
    errorCode={404}
    {...passThroughProps}
  />
);

//500 error
export const SomethingWentWrong = props => (
  <ErrorTemplate
    title={t("error_page.something_went_wrong")}
    errorCode={500}
    {...props}
  />
);

PageNotFound.propTypes = {
  errorMessage: PropTypes.string,
};
