import { useRef } from "react";
import I18n, { t } from "i18n-js";
import * as yup from "yup";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { Button } from "@circle-react-shared/uikit/Button";
import { Form } from "@circle-react-uikit/Form";
import { Modal } from "@circle-react-uikit/ModalV2";

const VALIDATION_SCHEMA = yup.object().shape({
  url: yup.lazy(value => {
    if (value.startsWith("mailto:")) {
      return yup
        .string()
        .trim()
        .matches(
          /([mailto:\w.\-pL]+@\w+.[\w.\-pL]+)/,
          I18n.t("link_url_invalid"),
        )
        .required(I18n.t("link_url_required"));
    }
    return yup
      .string()
      .url(I18n.t("link_url_invalid"))
      .required(I18n.t("link_url_required"));
  }),
});

export interface AddTipTapLinkModalProps {
  editor: any;
  isAdminUsageContext?: boolean;
}

export const AddTipTapLinkModalComponent = ({
  editor,
  isAdminUsageContext = false,
}: AddTipTapLinkModalProps) => {
  const drawer = useModal();
  const isEdit = editor.isActive("link");
  const inputRef = useRef(null);

  const previousURL = editor.getAttributes("link").href;

  const handleRemoveLink = () => {
    editor.chain().focus().extendMarkRange("link").unsetLink().run();
    void drawer.hide();
  };

  const handleOnSubmit = ({ url }: { url: string }) => {
    editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
    void drawer.hide();
  };

  return (
    <Modal
      isOpen={drawer.visible}
      onClose={drawer.hide}
      initialFocus={inputRef}
    >
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title className="text-center" size="md">
            {isEdit
              ? t("tiptap.extensions.link.edit")
              : t("tiptap.extensions.link.add")}
          </Modal.Title>
          <Modal.HeaderActions className="absolute right-0 top-0 mr-6 mt-6">
            <Modal.CloseButton onClick={drawer.hide} />
          </Modal.HeaderActions>
        </Modal.Header>
        <Form
          onSubmit={handleOnSubmit}
          defaultValues={{ url: previousURL }}
          validationSchema={VALIDATION_SCHEMA}
          autoComplete="off"
        >
          <Modal.Body>
            <Form.Item
              hideDescription
              hideBorder
              fullWidth
              name="url"
              label={t("tiptap.extensions.link.link")}
            >
              <Form.Input ref={inputRef} autoFocus />
            </Form.Item>
          </Modal.Body>
          <Modal.Footer className="!px-5 md:!px-8">
            <Button
              full
              type="submit"
              variant={isAdminUsageContext ? "circle" : "primary"}
            >
              {t("tiptap.extensions.link.save")}
            </Button>

            {isEdit && (
              <div className="mt-4 flex justify-end">
                <Button full variant="secondary" onClick={handleRemoveLink}>
                  {t("tiptap.extensions.link.remove")}
                </Button>
              </div>
            )}
          </Modal.Footer>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

const AddTipTapLinkModal = ModalManager.create(
  ({ editor, isAdminUsageContext }: AddTipTapLinkModalProps) => (
    <AddTipTapLinkModalComponent
      editor={editor}
      isAdminUsageContext={isAdminUsageContext}
    />
  ),
);

export const useAddTipTapLinkModal = () => useModal(AddTipTapLinkModal);
