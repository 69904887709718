import { createContext, useContext } from "react";
import { useState } from "react";
import PropTypes from "prop-types";

const AiBlockContext = createContext({});
AiBlockContext.displayName = "AiBlockContext";

export const useAiBlockContext = () => useContext(AiBlockContext);

export const AiBlockContextProvider = ({
  children,
  editor,
  node,
  getPos,
  deleteNode,
}) => {
  const { range } = node.attrs;
  const [isLoadingResponse, setIsLoadingResponse] = useState(false);

  const cleanupNode = () => {
    if (range) {
      editor.commands.setTextSelection({
        from: 0,
        to: editor.state.doc.content.size,
      });
      editor.chain().focus().unsetHighlight().run();
    }
    editor.commands.setTextSelection(getPos() + 1);
    deleteNode();
  };

  const value = {
    editor,
    node,
    getPos,
    deleteNode,
    isLoadingResponse,
    setIsLoadingResponse,
    cleanupNode,
  };

  return (
    <AiBlockContext.Provider value={value}>{children}</AiBlockContext.Provider>
  );
};

AiBlockContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
