import { joinPoint } from "prosemirror-transform";

// TODO: Remove after upgrading Tiptap to 2.1.0.
//
// This code was taken from the latest version of Tiptap (2.1.0.rc9) with a few
// modifications to avoid type errors due to multiple versions of ProseMirror
// packages.
//
// Source: https://github.com/ueberdosis/tiptap/blob/75f0418f03fb040dc4c12c104dfe457342b4e4b4/packages/extension-list-item/src/commands/joinListItemBackward.ts#L4
export const joinListItemBackward =
  () =>
  ({ tr, state, dispatch }: any) => {
    try {
      const point = joinPoint(state.doc, state.selection.$from.pos, -1);

      if (point === null || point === undefined) {
        return false;
      }

      tr.join(point, 2);

      if (dispatch) {
        dispatch(tr);
      }

      return true;
    } catch {
      return false;
    }
  };
