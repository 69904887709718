export const getMentionScope = ({
  spaceId,
  isDirectMessaging,
  isThread,
  editorType,
  isUpdate,
  disableGroupMentions,
}: any) => {
  // Note: this is temporary until group mentions are supported in all contexts
  if (editorType === "block") return "post";
  // Note: this is temporary until group mentions are supported in case of edit message
  if (isThread || isUpdate || disableGroupMentions) return "thread";
  if (spaceId) return "space";
  if (isDirectMessaging) return "direct";
  return "group_chat";
};
