import { Extension } from "@tiptap/core";
import { ReactRenderer } from "@tiptap/react";
import Suggestion from "@tiptap/suggestion";
import { uniqueId } from "lodash";
import { PluginKey } from "prosemirror-state";
import tippy from "tippy.js";
import { getPortalTarget } from "@circle-react-shared/uikit/TipTap/utilities/getPortalTarget";
import { EmojiPicker } from "./EmojiPicker";

export const InlineEmojiExtension = portalTargetId =>
  Extension.create({
    addOptions() {
      const appendTarget = getPortalTarget(portalTargetId || null);
      return {
        suggestion: {
          char: ":",
          pluginKey: new PluginKey("emoji"),
          render: () => {
            let reactRenderer;
            let popup;

            return {
              onStart: props => {
                reactRenderer = new ReactRenderer(EmojiPicker, {
                  props,
                  editor: props.editor,
                  onClose: popup?.[0]?.hide,
                });

                popup = tippy("body", {
                  getReferenceClientRect: props.clientRect,
                  appendTo: () => appendTarget || props.editor.options.element,
                  content: reactRenderer.element,
                  showOnCreate: true,
                  interactive: true,
                  trigger: "manual",
                  placement: "auto-end",
                  theme: "light-border",
                });
              },

              onUpdate(props) {
                reactRenderer.updateProps({ ...props, key: uniqueId() });

                if (props.query) {
                  popup[0].show();
                }
                popup[0].setProps({
                  getReferenceClientRect: props.clientRect,
                });
              },

              onKeyDown(props) {
                if (props.event.key === "Escape") {
                  popup[0].hide();
                  return true;
                }
                return reactRenderer.ref?.onKeyDown(props);
              },

              onExit() {
                popup?.[0]?.hide();
                reactRenderer?.destroy();
              },
            };
          },
        },
      };
    },
    addProseMirrorPlugins() {
      return [
        Suggestion({
          editor: this.editor,
          ...this.options.suggestion,
        }),
      ];
    },
  });
