import { Suspense } from "react";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { Loader } from "@circle-react-uikit/Loader";
import type { AddToCalendarProps } from "./Component";

const AddToCalendarComponent = lazyWithRetry(
  () => import(/* webpackChunkName: "AddToCalendar" */ "./Component"),
);

export const AddToCalendar = (props: AddToCalendarProps) => (
  <Suspense fallback={<Loader />}>
    <AddToCalendarComponent {...props} />
  </Suspense>
);

export type { AddToCalendarProps };
