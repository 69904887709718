import { createContext, useContext } from "react";
import PropTypes from "prop-types";

const ListItemData = createContext({
  item: {},
  listeners: {},
});
ListItemData.displayName = "ListItemData";

export const useListItemDataProvider = () => useContext(ListItemData);

export const ListItemDataProvider = ({ item, listeners, children }) => (
  <ListItemData.Provider
    value={{
      item,
      listeners,
    }}
  >
    {children}
  </ListItemData.Provider>
);

ListItemDataProvider.propTypes = {
  item: PropTypes.object,
  listeners: PropTypes.object,
  children: PropTypes.node,
};
