import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";

interface GenerateLocalSgidToObjectMapProps {
  sgids: string[];
  setLocalSgidToObjectMap: (data: object) => void;
}

export const getSgidValuesFromHTML = (html: any) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  const elements = doc.querySelectorAll("[data-sgid]");
  return Array.from(elements).map(element =>
    String(element.getAttribute("data-sgid")),
  );
};

export const generateLocalSgidToObjectMap = async ({
  sgids,
  setLocalSgidToObjectMap,
}: GenerateLocalSgidToObjectMapProps) => {
  try {
    const data = await reactQueryGet(
      internalApi.richTextFields.fetchSgidRecords({ params: { sgids } }),
    );
    setLocalSgidToObjectMap((prev: any) => ({
      ...prev,
      ...data,
    }));
  } catch (err) {
    console.error(err);
  }
};
