import { useEffect } from "react";
import { useQuery } from "react-query";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { useBlockEditorContext } from "@circle-react-shared/uikit/TipTapBlockEditor";
import { useTipTapEditorContext } from "@circle-react-uikit/TipTap/index";
import { useOpenChatRoomUuid } from "./useOpenChatRoomUuid";

interface Props {
  href: string;
  command: (item: any) => void;
}

interface UnfurlMessageLink {
  type: string;
  sgid: string;
  is_private_message?: boolean;
}

export const ChatMessagePaste = ({ href, command }: Props): any => {
  const tiptapEditorContext = useTipTapEditorContext();
  const tiptapBlockEditorContext = useBlockEditorContext();
  const { addToLocalSgidToObjectMap } =
    tiptapEditorContext || tiptapBlockEditorContext;

  const { chatRoomUuid } = useOpenChatRoomUuid();

  const { data: unfurledLinks } = useQuery(
    internalApi.richTextFields.unfurlURLs({
      params: { urls: [href], open_chat_room_uuid: chatRoomUuid },
    }),
    {
      initialData: [],
    },
  );

  const chatMessageLink: UnfurlMessageLink = unfurledLinks?.[0] ?? {
    type: "",
    sgid: "",
  };

  useEffect(() => {
    if (!chatMessageLink.type) return;

    addToLocalSgidToObjectMap({
      sgid: chatMessageLink.sgid,
      object: chatMessageLink,
    });
    command(chatMessageLink.sgid);
  }, [chatMessageLink]);

  return null;
};

ChatMessagePaste.displayName = "ChatMessagePaste";
