import { forwardRef } from "react";
import type { ComponentPropsWithRef } from "react";
import classNames from "classnames";
import { Icon } from "@circle-react/components/shared/Icon";
import type { IconType } from "@circle-react/components/shared/Icon";

export interface MenuIconButtonProps extends ComponentPropsWithRef<"button"> {
  icon: IconType;
  ["aria-label"]: string;
  isLight?: boolean;
  shouldAnimateSpin?: boolean;
}

export const MenuIconButton = forwardRef<
  HTMLButtonElement,
  MenuIconButtonProps
>(
  (
    { icon, isLight, shouldAnimateSpin, className, disabled, ...props },
    ref,
  ) => (
    <button
      ref={ref}
      type="button"
      className={classNames(
        "hover:bg-tertiary focus-visible:bg-tertiary flex h-7 w-7 items-center justify-center rounded p-1",
        {
          "cursor-not-allowed": disabled,
        },
        className,
      )}
      disabled={disabled}
      {...props}
    >
      <Icon
        type={icon}
        size={20}
        className={classNames("h-5 w-5", {
          "text-default dark:text-dark": isLight,
          "text-lightest dark:text-default": !isLight,
          "animate-spin": shouldAnimateSpin,
        })}
      />
    </button>
  ),
);

MenuIconButton.displayName = "MenuIconButton";
