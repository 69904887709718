import type { ReactNode } from "react";
import {
  SortableContext as DnDSortableContext,
  horizontalListSortingStrategy,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useSortableListData } from "./SortableListDataProvider";

export interface SortableContextProps {
  id?: string;
  items?: any[];
  children: ReactNode;
}

export const SortableContext = ({
  id,
  items,
  children,
}: SortableContextProps) => {
  const {
    items: allItems,
    lockedVerticalAxis: hasLockedVerticalAxis,
    lockedHorizontalAxis: hasLockedHorizontalAxis,
  } = useSortableListData();

  let strategy;
  if (hasLockedVerticalAxis) {
    strategy = verticalListSortingStrategy;
  } else if (hasLockedHorizontalAxis) {
    strategy = horizontalListSortingStrategy;
  }

  return (
    <DnDSortableContext id={id} items={items ?? allItems} strategy={strategy}>
      {children}
    </DnDSortableContext>
  );
};
