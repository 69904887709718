import { Icon } from "@circle-react-shared/Icon";

export interface LoaderProps {
  condition?: boolean;
}

export const Loader = ({ condition }: LoaderProps) => {
  if (!condition) return null;

  return (
    <div className="quickpost__loader-wrapper">
      <Icon type="loader" />
    </div>
  );
};
