import PropTypes from "prop-types";
import { times } from "lodash";
import {
  Row,
  RowContainer,
} from "@circle-react-uikit/SkeletonLoader/components";

export const CardView = ({ count = 1, withContainer = true }) => {
  const Container = ({ withContainer, children }) => {
    const container = (
      <div className="skeleton-loader--card-view__container">{children}</div>
    );
    return withContainer ? container : <>{children}</>;
  };

  return (
    <Container withContainer={withContainer}>
      {times(count, index => (
        <div
          key={index}
          className="skeleton-loader skeleton-loader--card-view !bg-primary !border-primary !rounded-2xl"
        >
          <div className="skeleton-loader--card-view__cover !bg-secondary" />
          <div className="skeleton-loader--card-view__content">
            <div className="skeleton-loader--card-view__body">
              <RowContainer lg>
                <Row md almostFull />
                <Row md half />
              </RowContainer>
            </div>
            <div className="skeleton-loader--card-view__footer">
              <RowContainer lg inline>
                <Row badge />
                <Row badge />
              </RowContainer>
            </div>
          </div>
        </div>
      ))}
    </Container>
  );
};

CardView.propTypes = {
  count: PropTypes.number,
  withContainer: PropTypes.bool,
};
