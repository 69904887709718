export const insertNodeAtRange = ({ editor, range, name, attrs }: any) => {
  const nodeAfter = editor.view.state.selection.$to.nodeAfter;
  const shouldOverrideSpace = nodeAfter?.text?.startsWith(" ");
  if (shouldOverrideSpace) {
    range.to += 1;
  }
  editor
    .chain()
    .focus()
    .insertContentAt(range, [
      {
        type: name,
        attrs,
      },
      {
        type: "text",
        text: " ",
      },
    ])
    .run();
};

export const checkIfContentMatches = ({ state, range, name }: any) => {
  const $from = state.doc.resolve(range.from);
  const type = state.schema.nodes[name];
  return !!$from.parent.type.contentMatch.matchType(type);
};
