import {
  Row,
  RowContainer,
} from "@circle-react-uikit/SkeletonLoader/components";

export const EventPopover = () => (
  <div className="w-full p-6">
    <RowContainer full>
      <Row lg short className="!h-6" />
    </RowContainer>
    <div className="mt-6 flex justify-start gap-4">
      <Row md long className="!w-32" />
      <Row md long className="!w-32" />
    </div>
    <div className="mt-6 flex justify-between gap-4">
      <Row xxl full />
      <Row xxl full />
    </div>
  </div>
);
