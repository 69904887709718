import { Icon } from "../../Icon";
import "./styles.scss";

export interface ImageInputBannerProps {
  title?: string;
  description?: string;
}

export const ImageInputBanner = ({
  title,
  description,
}: ImageInputBannerProps) => (
  <div className="image-input-banner__add">
    <div className="image-input-banner__add__icon-wrapper">
      <Icon type="plus-big" viewBox="0 0 20 20" />
    </div>
    {title && <span className="image-input-banner__label">{title}</span>}
    {description && (
      <span className="image-input-banner__description !text-dark">
        {description}
      </span>
    )}
  </div>
);
