import { Fragment, Node, Slice } from "prosemirror-model";

export const clipboardTextParser = (text, context) => {
  const blocks = text.replace().split(/(?:\r\n?|\n)/);
  const nodes = [];
  blocks.forEach((line, index) => {
    if (line.length > 0) {
      const newNode = {
        type: "paragraph",
        content: [{ type: "text", text: line }],
      };
      // If the next line is empty, add a hard break
      if (blocks[index + 1]?.length == 0) {
        newNode.content.push({
          type: "hardBreak",
        });
      }
      nodes.push(Node.fromJSON(context.doc.type.schema, newNode));
    }
  });

  const fragment = Fragment.from(nodes);
  return Slice.maxOpen(fragment);
};
