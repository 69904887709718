import { useRef } from "react";
import { useQueryClient } from "react-query";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { PollForm } from "@circle-react-shared/uikit/TipTapBlockEditor/PollExtension/PollModal/PollForm";
import { Modal } from "@circle-react-uikit/ModalV2";

interface EditPollModalProps {
  pollSgid: string;
}

const EditPollModalComponent = ({ pollSgid }: EditPollModalProps) => {
  const modal = useModal();
  const initialFocusRef = useRef<HTMLInputElement>(null);
  const queryClient = useQueryClient();
  const resetQueryCache = () => queryClient.removeQueries(["poll", pollSgid]);
  return (
    <Modal
      isOpen={modal.visible}
      onClose={modal.remove}
      initialFocus={initialFocusRef}
    >
      <Modal.Overlay />
      <Modal.Content size="lg" shouldCloseOnOutsideClick>
        <PollForm
          isEdit
          pollSgid={pollSgid}
          closeModal={modal.remove}
          onComplete={resetQueryCache}
          initialFocusRef={initialFocusRef}
        />
      </Modal.Content>
    </Modal>
  );
};

export const useEditPollModal = () =>
  useModal(
    ModalManager.create((props: EditPollModalProps) => (
      <EditPollModalComponent {...props} />
    )),
  );
