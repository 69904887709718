import classNames from "classnames";
import { times } from "lodash";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import {
  Row,
  RowContainer,
} from "@circle-react-uikit/SkeletonLoader/components";

export interface CommunitySidebarV2Props {
  count?: number;
}

export const CommunitySidebarSkeleton = ({
  count = 5,
}: CommunitySidebarV2Props) => {
  const { isV3Enabled } = useIsV3();
  const marginClassName = isV3Enabled ? "ml-6" : "ml-4";

  return (
    <div
      className={classNames(
        "relative grid h-[calc(100vh-65px)] grid-rows-[1fr]",
        {
          "left-0": !isV3Enabled,
        },
      )}
    >
      <div className="h-full max-w-[18.5rem] overflow-hidden pb-0 pl-0">
        <div
          className={classNames("flex h-20 min-h-[5rem] items-center", {
            "px-4": !isV3Enabled,
            "px-6": isV3Enabled,
          })}
        >
          <Row long />
        </div>
        {!isV3Enabled && (
          <div className={classNames(isV3Enabled ? "my-6" : "my-4")}>
            <RowContainer xl full>
              <Row almostFull className={marginClassName} />
              <Row almostFull className={marginClassName} />
              <Row almostFull className={marginClassName} />
              <Row almostFull className={marginClassName} />
              <Row almostFull className={marginClassName} />
              <Row almostFull className={marginClassName} />
            </RowContainer>
          </div>
        )}
        <div className={classNames(isV3Enabled ? "space-y-5" : "space-y-6")}>
          {times(count, index => (
            <div
              key={index}
              className={classNames("items-center", {
                "px-4": !isV3Enabled,
                "px-6": isV3Enabled,
              })}
            >
              <RowContainer xl>
                <Row half lg />
                <Row almostFull className={marginClassName} />
                <Row almostFull className={marginClassName} />
                <Row almostFull className="mb-2" />
              </RowContainer>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
