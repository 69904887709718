import PropTypes from "prop-types";
import classNames from "classnames";
import isFunction from "lodash/isFunction";
import { Tippy } from "@circle-react-shared/Tippy";
import { TextModifierIcon } from "./TextModifierIcon";

export const TextModifierButton = ({
  label,
  isActive,
  onClick,
  tooltipPlacement = "top",
  iconType,
}) => {
  const IconButton = (
    <button
      type="button"
      onClick={() => isFunction(onClick) && onClick()}
      className={classNames(
        "rounded border p-1.5",
        "text-dark",
        "hover:bg-secondary",
        {
          "bg-tertiary text-dark border-light": isActive,
          "border-transparent": !isActive,
        },
      )}
    >
      <TextModifierIcon type={iconType} />
    </button>
  );

  if (label) {
    return (
      <Tippy
        content={label}
        placement={tooltipPlacement}
        theme="text-modifiers-tooltip"
      >
        {IconButton}
      </Tippy>
    );
  }
  return <>{IconButton}</>;
};

TextModifierButton.propTypes = {
  label: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  tooltipPlacement: PropTypes.string,
  iconType: PropTypes.string,
};
