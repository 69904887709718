export const lineClampClasses = {
  1: "line-clamp-1",
  2: "line-clamp-2",
  3: "line-clamp-3",
  4: "line-clamp-4",
  5: "line-clamp-5",
  6: "line-clamp-6",
  7: "line-clamp-7",
  8: "line-clamp-8",
} as const;

/**
 * Use fixed height because safari doesn't support line-clamp:
 * https://stackoverflow.com/questions/70679732/css-line-clamp-does-not-work-in-safari-on-inner-block-level-elements
 */
export const safariLineClampClasses = {
  1: "overflow-hidden max-h-6 h-6",
  2: "overflow-hidden max-h-12 h-12",
  3: "overflow-hidden max-h-18 h-18",
  4: "overflow-hidden max-h-24 h-24",
  5: "overflow-hidden max-h-30 h-30",
  6: "overflow-hidden max-h-36 h-36",
  7: "overflow-hidden max-h-42 h-42",
  8: "overflow-hidden max-h-48 h-48",
} as const;

export type LineClampLevel = keyof typeof lineClampClasses;
