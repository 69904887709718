import type { CSSProperties, ReactElement } from "react";
import classNames from "classnames";
import { TippyV2 } from "@circle-react-shared/TippyV2";

interface BadgeV2Props {
  emoji?: ReactElement<any>;
  label?: string;
  truncate?: boolean;
  truncateLength?: number;
  tooltipCursor?: string;
  tooltipText?: string;
  inverted?: boolean;
  square?: boolean;
  styles?: CSSProperties;
  className?: string;
  dataTestId?: string;
  textSizeClassName?: string;
}

const paddingVerticalClassName = "py-[3px]";

export const BadgeV2 = ({
  emoji,
  label,
  truncate = true,
  truncateLength = 20,
  tooltipCursor = "pointer",
  tooltipText = "",
  inverted,
  square,
  styles,
  className,
  dataTestId,
  textSizeClassName = "text-[0.6rem]",
}: BadgeV2Props) => {
  if (!emoji && !label) {
    return null;
  }

  let truncatedLabel;

  if (label && truncate && label.length > truncateLength) {
    truncatedLabel = label.substring(0, truncateLength);
  }

  return (
    <span
      style={styles}
      className={classNames(
        "text-default inline-flex h-5 items-center gap-1 border",
        paddingVerticalClassName,
        {
          "pr-2": emoji && (label || truncatedLabel),
          "border-secondary bg-badge": inverted,
          "border-dark bg-primary": !inverted,
          "rounded px-1": square,
          "rounded-2xl px-1.5": !square,
        },
        className,
      )}
      data-testid={classNames("member-tag", dataTestId)}
    >
      {emoji && (
        <span
          className={classNames("leading-none", textSizeClassName)}
          data-testid="member-tag-emoji"
        >
          {tooltipText ? (
            <TippyV2 role="tooltip" content={tooltipText} interactive={false}>
              {emoji}
            </TippyV2>
          ) : (
            emoji
          )}
        </span>
      )}
      {label && (
        <span
          className={classNames("font-medium", {
            "text-badge": inverted,
            "text-xxs": square,
            [textSizeClassName]: !square,
          })}
          {...(truncatedLabel && {
            style: {
              cursor: tooltipCursor,
            },
          })}
          data-testid="member-tag-label"
        >
          {truncatedLabel ? (
            <TippyV2 role="tooltip" content={label}>
              <>{truncatedLabel}...</>
            </TippyV2>
          ) : (
            label
          )}
        </span>
      )}
    </span>
  );
};
