import { t } from "i18n-js";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { mediaSettingsApi } from "@circle-react/api";
import { useToast } from "@circle-react-uikit/ToastV2";

export const useMediaSettings = signedId => {
  const { success, error } = useToast();
  const queryClient = useQueryClient();
  const {
    data: mediaSettings,
    isLoading: isLoadingMediaSettings,
    refetch: refetchMediaSettings,
  } = useQuery(
    ["mediaSettings", signedId],
    () => mediaSettingsApi.show(signedId),
    { enabled: !!signedId },
  );

  const { mutate: updateMediaSettings, isLoading: isUpdatingMediaSettings } =
    useMutation(formData => mediaSettingsApi.update(signedId, formData), {
      onSuccess: () => {
        success(t("media_settings.success"));
        refetchMediaSettings();
        void queryClient.invalidateQueries(["mediaSettings", signedId]);
      },
      onError: responseError => {
        error(t("media_settings.error"));
        console.error(responseError);
      },
    });

  return {
    mediaSettings,
    isLoading: isLoadingMediaSettings,
    isUpdating: isUpdatingMediaSettings,
    update: updateMediaSettings,
    refetch: refetchMediaSettings,
  };
};
