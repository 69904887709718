import classNames from "classnames";
import { isEmpty } from "lodash";
import { useMutation, useQueryClient } from "react-query";
import { reactQueryPost } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { Radio } from "@circle-react-shared/uikit/TipTapBlockEditor/PollExtension/PollDisplay/Radio";
import type {
  PollOptionType,
  PollResponse,
} from "@circle-react-shared/uikit/TipTapBlockEditor/PollExtension/PollModal/api";
import { usePollVoteListModal } from "@circle-react-shared/uikit/TipTapBlockEditor/PollExtension/PollVoteListModal";
import { Typography } from "@circle-react-shared/uikit/Typography";

interface PollOptionProps {
  option: PollOptionType;
  selectedOptionId?: number;
  setSelectedOptionId: (id: number) => void;
  canRespond: boolean;
  poll: PollResponse;
  shouldShowResults: boolean;
}

export const PollOption = ({
  option,
  selectedOptionId,
  setSelectedOptionId,
  canRespond,
  poll,
  shouldShowResults,
}: PollOptionProps) => {
  const queryClient = useQueryClient();
  const { show: showPollVoteListModal } = usePollVoteListModal();
  const respondMutation = useMutation(
    (pollOptionId: number) =>
      reactQueryPost(internalApi.polls.respond(poll.id), {
        poll_option_id: pollOptionId,
      }),
    {
      onSuccess: results => {
        if (isEmpty(results)) return;
        queryClient.setQueryData(["poll", poll.sgid], oldData => {
          if (!oldData) return;
          return {
            ...oldData,
            community_member_response_option_id: option.id,
            results,
          };
        });
      },
    },
  );

  const handleClick = () => {
    if (canRespond) {
      setSelectedOptionId(option.id);
    }
    respondMutation.mutate(option.id);
  };

  const percentage =
    poll.results?.detailed_results?.find(
      result => result.poll_option_id === option.id,
    )?.percentage ?? 0;
  const isActive = selectedOptionId === option.id;
  const isClosed = poll.status === "closed";
  const shouldDisable = !canRespond || isClosed || !!selectedOptionId;
  return (
    <div
      className={classNames(
        "relative flex w-full items-start overflow-hidden rounded-lg border p-3.5",
        {
          "hover:border-dark hover:bg-secondary focus:border-dark group":
            !shouldDisable,
          "border-darkest": isActive,
          "border-primary": !isActive,
        },
      )}
      data-testid="poll-option"
    >
      <button
        type="button"
        disabled={shouldDisable}
        onClick={handleClick}
        key={option.id}
        className="flex w-full items-start"
        data-testid="select-poll-option"
      >
        {shouldShowResults && (
          <div
            className="bg-tertiary absolute inset-y-0 left-0 -z-10 h-full w-full"
            style={{
              width: `${percentage}%`,
            }}
          />
        )}
        {!isClosed && <Radio checked={isActive} />}
        <div className="ml-3 mr-4 flex-1 text-left">
          <Typography.LabelMd weight="regular">
            {option.value}
          </Typography.LabelMd>
        </div>
      </button>
      {shouldShowResults && (
        <div
          className="flex shrink-0 items-center space-x-1"
          data-testid="poll-option-info"
        >
          <Typography.LabelSm weight="regular">
            {percentage}%
          </Typography.LabelSm>
          <IconButton
            ariaLabel=""
            name="16-chevron-right"
            iconSize={16}
            onClick={() => {
              void showPollVoteListModal({
                pollId: poll.id,
                pollOptionId: option.id,
                pollValue: option.value,
                percentage,
              });
            }}
            dataTestId="open-poll-option-info"
          />
        </div>
      )}
    </div>
  );
};
