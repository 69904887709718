import { Extension } from "@tiptap/core";
import { ReactRenderer } from "@tiptap/react";
import Suggestion from "@tiptap/suggestion";
import { isEmpty } from "lodash";
import { PluginKey } from "prosemirror-state";
import tippy from "tippy.js";
import { CommandsList } from "./CommandsList";
import { getMenuItems } from "./getMenuItems";

// Adding one extension name as key and many commands as value would
// disable all the commands in the value array if the extension is disabled.
// For example, if headings extension is disabled, h2 and h3 commands could be disabled as well.
const EXTENSION_COMMAND_DEPENDENCIES = {
  ai_copilot: ["ai_copilot"],
  mention: ["mention"],
  poll: ["poll"],
  cta: ["cta"],
};

export default {
  configure: ({
    showEmbedModal,
    showImagePickerModal,
    showFileUploadModal,
    showGiphyPickerModal,
    showPollModal,
    showCTASettingsModal,
    disabledExtensions,
    disabledSlashMenuCategories,
    disabledSlashMenuCommands,
    shouldShowPlanUpgradeModal,
    openUpgradeModal,
    headingLevels,
  }) => {
    Object.entries(EXTENSION_COMMAND_DEPENDENCIES).forEach(dependencyItem => {
      const [extensionKey, dependencies] = dependencyItem;
      if (disabledExtensions[extensionKey]) {
        dependencies.forEach(dependency => {
          disabledSlashMenuCommands[dependency] = true;
        });
      }
    });

    const filterOutHeadingLevels = (acc, category) => {
      if (category.name === "Basic") {
        const commands = category.commands.filter(command => {
          if (!headingLevels.includes(1)) {
            return command.name !== "h1";
          }
          if (!headingLevels.includes(2)) {
            return command.name !== "h2";
          }
          if (!headingLevels.includes(3)) {
            return command.name !== "h3";
          }
          return true;
        });
        if (commands.length > 0) {
          acc.push({
            ...category,
            commands,
          });
          return acc;
        }
      }
      acc.push(category);
      return acc;
    };

    return Extension.create({
      addOptions() {
        return {
          suggestion: {
            char: "/",
            pluginKey: new PluginKey("slash-commands"),
            items: ({ query }) => {
              const menuItems = Object.values(
                getMenuItems({
                  showEmbedModal,
                  showImagePickerModal,
                  showFileUploadModal,
                  showGiphyPickerModal,
                  showPollModal,
                  showCTASettingsModal,
                  shouldShowPlanUpgradeModal,
                  openUpgradeModal,
                }),
              );

              return menuItems
                .map(category => ({
                  ...category,
                  commands: category.commands.filter(
                    command =>
                      !disabledSlashMenuCommands[command.name] &&
                      command.title.toLowerCase().includes(query.toLowerCase()),
                  ),
                }))
                .filter(
                  category =>
                    !disabledSlashMenuCategories[category.name] &&
                    !isEmpty(category.commands),
                )
                .reduce(filterOutHeadingLevels, []);
            },
            render: () => {
              let reactRenderer;
              let popup;

              return {
                onStart: props => {
                  reactRenderer = new ReactRenderer(CommandsList, {
                    props,
                    editor: props.editor,
                  });

                  popup = tippy("body", {
                    getReferenceClientRect: props.clientRect,
                    appendTo: () => props.editor.options.element,
                    content: reactRenderer.element,
                    showOnCreate: true,
                    interactive: true,
                    trigger: "manual",
                    placement: "auto-end",
                    theme: "light-border",
                    zIndex: 999,
                    onHidden: () => {
                      reactRenderer.destroy();
                    },
                  });
                },
                onUpdate(props) {
                  reactRenderer.updateProps(props);
                  if (props.items.length < 1) {
                    popup[0].hide();
                    reactRenderer && reactRenderer.destroy();
                  } else {
                    popup[0].show();
                    popup[0].setProps({
                      getReferenceClientRect: props.clientRect,
                    });
                  }
                },
                onKeyDown(props) {
                  if (props.event.key === "Escape") {
                    popup[0].hide();
                    props.event.preventDefault();
                    props.event.stopPropagation();
                    return true;
                  }
                  return reactRenderer.ref?.onKeyDown(props);
                },
                onExit() {
                  popup && popup[0].destroy();
                  reactRenderer && reactRenderer.destroy();
                },
              };
            },
          },
        };
      },

      addProseMirrorPlugins() {
        return [
          Suggestion({
            editor: this.editor,
            ...this.options.suggestion,
          }),
        ];
      },
    });
  },
};
