import { useEffect, useRef } from "react";
import classNames from "classnames";
import Plyr from "plyr";
import { usePunditUserContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { AudioTranscriptions } from "./AudioTranscription";
import "./audio-player.scss";

export interface AudioPlayerProps {
  src: string;
  type: string;
  controls?: string[];
  isVoiceMessage?: boolean;
  transcriptionId?: number;
  transcriptionPreview?: string;
}

export const AudioPlayer = ({
  src,
  type,
  controls = ["play", "progress", "current-time", "mute", "volume", "settings"],
  isVoiceMessage = false,
  transcriptionId,
  transcriptionPreview,
}: AudioPlayerProps) => {
  const { currentCommunityMember } = usePunditUserContext();
  const isAdmin = isCommunityAdmin(currentCommunityMember);
  const audioElementRef = useRef(null);
  const playerControls =
    isAdmin && !isVoiceMessage ? [...controls, "download"] : controls;

  useEffect(() => {
    if (!audioElementRef.current) {
      return;
    }

    const player = new Plyr(audioElementRef.current, {
      controls: playerControls,
      invertTime: false,
      speed: { selected: 1, options: [0.5, 1, 1.5, 2] },
      storage: {
        enabled: false,
      },
    });

    return () => player.destroy();
  }, []);

  return (
    <div>
      <div
        className={classNames("post-audio-player", {
          "default mt-2": !isVoiceMessage,
          "voice-message": isVoiceMessage,
        })}
      >
        <audio ref={audioElementRef} preload="none" controls>
          <source src={src} type={type} />
        </audio>
      </div>
      {transcriptionId && transcriptionPreview && audioElementRef?.current && (
        <AudioTranscriptions
          transcriptionId={transcriptionId}
          transcriptionPreview={transcriptionPreview}
          audioPlayer={audioElementRef.current}
        />
      )}
    </div>
  );
};
