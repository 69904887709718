import Bold from "@tiptap/extension-bold";
import BulletList from "@tiptap/extension-bullet-list";
import CharacterCount from "@tiptap/extension-character-count";
import Document from "@tiptap/extension-document";
import HardBreak from "@tiptap/extension-hard-break";
import History from "@tiptap/extension-history";
import Italic from "@tiptap/extension-italic";
import OrderedList from "@tiptap/extension-ordered-list";
import Paragraph from "@tiptap/extension-paragraph";
import Placeholder from "@tiptap/extension-placeholder";
import Strike from "@tiptap/extension-strike";
import Text from "@tiptap/extension-text";
import { InlineEmojiExtension } from "@circle-react/components/shared/uikit/TipTapBlockEditor/InlineEmojiExtension";
import { isMobileOs } from "@circle-react/helpers/browserHelpers";
import { ChatKeymap } from "@circle-react-shared/uikit/TipTap/Extensions/CustomKeymaps/Chat";
import { CommentKeymap } from "@circle-react-shared/uikit/TipTap/Extensions/CustomKeymaps/Comment";
import { CustomizedUnderline } from "@circle-react-shared/uikit/TipTap/Extensions/CustomizedUnderline";
import { CustomizedListItem } from "@circle-react-shared/uikit/TipTapBlockEditor/CustomizedListItemExtension";
import { DEFAULT_VARIABLES } from "@circle-react-uikit/InsertVariableButton";
import { ChatMessageLinkExtension } from "./CustomExtensions/ChatMessageLink";
import { CustomizedLink } from "./CustomExtensions/CustomizedLink";
import { EditedLabelExtension } from "./CustomExtensions/EditedLabel";
import Embeds from "./CustomExtensions/Embeds";
import { EmojiReplacer } from "./CustomExtensions/EmojiReplacer";
import { EntitiesExtension } from "./CustomExtensions/Entities";
import { Mentions } from "./CustomExtensions/Mentions";

interface TiptapExtensionProps {
  placeholder: string;
  openInternalLinksInCurrentTab: boolean;
  maxLength: null | number;
  portalTargetId: string;
  menubarProps: any;
  type: any;
}

export const getTiptapExtensions = ({
  placeholder,
  openInternalLinksInCurrentTab,
  maxLength = null,
  portalTargetId,
  menubarProps,
  type,
}: TiptapExtensionProps): any => {
  const isCommentType = type == "comment";
  const isWorkflowType = type == "workflow";
  const isChatType = type == "chat";
  return [
    Document,
    Paragraph,
    Text,
    Bold,
    Italic,
    Strike,
    CustomizedUnderline,
    EditedLabelExtension,
    History,
    // Using comment keymap for mobile os for the enter button on the keyboard to create
    // a new line instead of submitting the form similar to the comment box on the web
    !(isCommentType || isMobileOs()) ? ChatKeymap : CommentKeymap,
    CustomizedLink.configure({
      openInternalLinksInCurrentTab,
      openOnClick: false,
      autolink: true,
      validate: (href: any) => {
        const dynamicVariableLabels: any = (
          menubarProps.dynamicVariables || DEFAULT_VARIABLES
        ).map(({ label }: any) => label.replace(/{{|}}/g, ""));
        return !dynamicVariableLabels.some((variable: any) =>
          variable.includes(href),
        );
      },
    }),
    Placeholder.configure({
      placeholder,
      emptyEditorClass: "is-editor-empty",
    }),
    Embeds,
    { ...(!isWorkflowType && Mentions(portalTargetId)) },
    EntitiesExtension(portalTargetId),
    {
      ...(isChatType && ChatMessageLinkExtension()),
    },
    HardBreak.extend({
      addKeyboardShortcuts() {
        // Disable all keyboard shortcuts for hard break
        return {};
      },
    }),
    EmojiReplacer,
    CharacterCount.configure({
      limit: maxLength,
    }),
    BulletList,
    OrderedList,
    CustomizedListItem.configure({ type }),
    InlineEmojiExtension(portalTargetId),
  ];
};
