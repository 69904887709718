import type { ReactNode } from "react";

export interface MediaElementProps {
  id: string;
  contentType: string;
  children: ReactNode;
  poster?: string;
  preload?: "none" | "metadata" | "auto";
}

export const MediaElement = ({
  id,
  contentType,
  children,
  poster,
  preload = "none",
}: MediaElementProps) => {
  const isAudioMode = contentType?.match("audio.*");

  if (isAudioMode) {
    return (
      <audio id={id} preload={preload} controls>
        {children}
      </audio>
    );
  }

  return (
    <video
      id={id}
      preload={preload}
      controls
      poster={poster}
      data-poster={poster}
      data-testid="media-player"
    >
      {children}
    </video>
  );
};
