import { forwardRef, useEffect, useState } from "react";
import type { Editor, Range } from "@tiptap/core";
import { CommandItem } from "@circle-react-uikit/TipTapBlockEditor/SlashCommands/CommandItem";
import { Typography } from "@circle-react-uikit/Typography";
import type { CommandItem as CommandItemType } from "./types";
import { useShortcuts } from "./useShortcuts";

export interface CommandsListProps {
  items: CommandItemType[];
  editor: Editor;
  range: Range;
}

export const CommandsList = forwardRef<unknown, CommandsListProps>(
  ({ items, editor, range }, ref) => {
    const [activeMenuIndex, setActiveMenuIndex] = useState(0);
    const flattenedCommands = items.flatMap(item => item.commands);

    useShortcuts({
      ref,
      editor,
      range,
      items: flattenedCommands,
      activeMenuIndex,
      setActiveMenuIndex,
    });

    useEffect(() => {
      if (!document.exitPointerLock) return;

      const handleMouseMove = () => {
        document.exitPointerLock();
      };

      document.addEventListener("mousemove", handleMouseMove);

      return () => {
        document.removeEventListener("mousemove", handleMouseMove);
      };
    }, []);

    if (!items.length) return null;

    return (
      <div
        className="bg-primary border-primary relative max-h-[50vh] min-w-[250px] max-w-[320px] overflow-auto rounded border py-2 text-sm shadow-md"
        data-testid="slash-commands-list"
      >
        <div className="flex flex-col">
          {items.map(category => {
            if (!category.commands.length) return null;
            return (
              <>
                <div
                  key={category.name}
                  className="flex items-center space-x-2 px-5 py-2 uppercase first:pt-2"
                >
                  <Typography.LabelXxs color="text-light">
                    {category.name}
                  </Typography.LabelXxs>
                </div>
                {category.commands.map(item => (
                  <CommandItem
                    key={item.name}
                    activeMenuIndex={activeMenuIndex}
                    setActiveMenuIndex={setActiveMenuIndex}
                    flattenedCommands={flattenedCommands}
                    item={item}
                    editor={editor}
                    range={range}
                  />
                ))}
              </>
            );
          })}
        </div>
      </div>
    );
  },
);

CommandsList.displayName = "CommandsList";
