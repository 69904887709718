import { usePunditUserContext } from "@circle-react/contexts";

export const useShowCopilot = () => {
  const { currentCommunityMember, currentCommunity, isLoading } =
    usePunditUserContext();

  const shouldShowCopilot =
    !isLoading && Boolean(currentCommunityMember?.policies?.can_use_copilot);

  const shouldShowPlanUpgradeModal =
    !isLoading &&
    !currentCommunity?.community_ai_feature_flag_enabled &&
    !currentCommunity?.content_copilot_ui_enabled &&
    currentCommunityMember?.is_admin;

  return { shouldShowCopilot, shouldShowPlanUpgradeModal };
};
