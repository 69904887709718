import { useEffect, useState } from "react";
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  format,
} from "date-fns";
import { t } from "i18n-js";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import type { PollResponse } from "@circle-react-shared/uikit/TipTapBlockEditor/PollExtension/PollModal/api";
import { Typography } from "@circle-react-shared/uikit/Typography";

export const PollFooter = ({
  poll,
  canUndo,
  undo,
}: {
  poll: PollResponse;
  canUndo: boolean;
  undo: () => void;
}) => {
  const canViewTotalResponses =
    poll.results && "total_responses" in poll.results;
  const [timeRemaining, setTimeRemaining] = useState<string | undefined>();

  const getTimeRemaining = () => {
    if (!poll.closes_at) return;

    const closesAtDate = new Date(poll.closes_at);
    const now = new Date();

    const days = differenceInDays(closesAtDate, now);
    const hours = differenceInHours(closesAtDate, now) % 24;
    const minutes = differenceInMinutes(closesAtDate, now) % 60;
    const seconds = differenceInSeconds(closesAtDate, now) % 60;

    if (days > 0) {
      return t("tiptap.extensions.poll.days_left", { count: days });
    }
    if (hours > 0) {
      return t("tiptap.extensions.poll.hours_left", { count: hours });
    }
    if (minutes > 0) {
      return t("tiptap.extensions.poll.minutes_left", { count: minutes });
    }
    if (seconds > 0) {
      return t("tiptap.extensions.poll.seconds_left", { count: seconds });
    }
    return t("tiptap.extensions.poll.closed");
  };

  // If the poll closes in less than 10 minutes
  // we want to update the time remaining every second
  useEffect(() => {
    if (poll.closes_at) {
      setTimeRemaining(getTimeRemaining());
      if (differenceInMinutes(new Date(poll.closes_at), new Date()) < 10) {
        const interval = setInterval(
          () => setTimeRemaining(getTimeRemaining()),
          1000,
        );
        return () => clearInterval(interval);
      }
    }
    return undefined;
  }, []);

  return (
    <div className="flex items-center space-x-1" data-testid="poll-footer">
      {canViewTotalResponses && (
        <Typography.LabelSm weight="regular" data-testid="poll-total-votes">
          {t("tiptap.extensions.poll.votes", {
            count: poll.results?.total_responses || 0,
          })}
        </Typography.LabelSm>
      )}
      {canViewTotalResponses && poll.closes_at && (
        <span className="mx-px">{t("separator_dot")}</span>
      )}
      {poll.closes_at && (
        <TippyV2
          className="flex items-center"
          content={t("tiptap.extensions.poll.closes_at", {
            date: format(new Date(poll.closes_at), "MMMM do, yyyy, h:mm a"),
          })}
          dataTestId="poll-closes-at"
        >
          <Typography.LabelSm weight="regular">
            {timeRemaining}
          </Typography.LabelSm>
        </TippyV2>
      )}
      {canUndo && (
        <>
          <span className="mx-px">{t("separator_dot")}</span>
          <button
            type="button"
            className="focus:bg-secondary hover:bg-secondary flex items-center rounded px-0.5"
            onClick={undo}
          >
            <Typography.LabelSm weight="medium" color="text-link">
              {t("tiptap.extensions.poll.undo")}
            </Typography.LabelSm>
          </button>
        </>
      )}
    </div>
  );
};
