import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { ImagePickerModalV2 } from "../../ImagePickerModalV2";

export const ImagePickerModalComponent = ({
  editor,
  range,
  addToLocalInlineAttachments,
}: any) => {
  const drawer = useModal();
  const createImageNode = ({ url, signed_id, content_type }: any) => {
    addToLocalInlineAttachments({ url, signed_id, content_type });
    editor
      .chain()
      .focus()
      .insertContentAt(range, [
        {
          type: "image",
          attrs: { url, signed_id, content_type, width_ratio: 1 },
        },
        {
          type: "hardBreak",
        },
      ])
      .run();
    drawer.remove();
  };

  return (
    <ImagePickerModalV2
      onComplete={createImageNode}
      isOpen={drawer.visible}
      onClose={drawer.remove}
    />
  );
};

const ImagePickerModal = ModalManager.create(
  ({ editor, range, addToLocalInlineAttachments }: any) => (
    <ImagePickerModalComponent
      editor={editor}
      range={range}
      addToLocalInlineAttachments={addToLocalInlineAttachments}
    />
  ),
);

export const useImagePickerModal = () => useModal(ImagePickerModal);
