import PropTypes from "prop-types";
import { Form } from "@circle-react-uikit/Form";
import { TranscriptFormBody } from "./TranscriptFormBody";

const getDefaultValues = mediaTranscript => {
  if (!mediaTranscript) {
    return {};
  }

  return {
    enabled: mediaTranscript.enabled,
    selected_file: mediaTranscript.selected_file,
  };
};

export const ManageTranscriptMenu = ({
  mediaTranscript,
  updateAsync,
  isUpdating,
}) => {
  if (!mediaTranscript) {
    return null;
  }
  const defaultValues = getDefaultValues(mediaTranscript);

  const onSubmit = values => {
    updateAsync(values);
  };

  return (
    <Form
      id="media-transcript"
      onSubmit={onSubmit}
      defaultValues={defaultValues}
    >
      <TranscriptFormBody
        isUpdating={isUpdating}
        supportsAi={!!mediaTranscript?.supports_ai}
        aiFileDownloadUrl={
          mediaTranscript?.is_ai_ready ? mediaTranscript?.webvtt_file_url : null
        }
      />
    </Form>
  );
};

ManageTranscriptMenu.propTypes = {
  mediaTranscript: PropTypes.object,
  updateAsync: PropTypes.func,
  isUpdating: PropTypes.bool,
};
