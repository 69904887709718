import { Node } from "@tiptap/core";
import { ReactNodeViewRenderer, mergeAttributes } from "@tiptap/react";
import { PollDisplay } from "@circle-react-shared/uikit/TipTapBlockEditor/PollExtension/PollDisplay";

export const PollExtension = Node.create({
  name: "poll",
  group: "block",
  atom: true,
  addAttributes: () => ({
    sgid: {
      default: null,
      parseHTML: element => element.getAttribute("sgid"),
      renderHTML: attributes =>
        attributes.sgid
          ? {
              sgid: attributes.sgid,
            }
          : {},
    },
  }),
  parseHTML: () => [{ tag: "polls-react" }],
  renderHTML: ({ HTMLAttributes }) => [
    "polls-react",
    mergeAttributes(HTMLAttributes),
  ],
  addNodeView() {
    return ReactNodeViewRenderer(PollDisplay);
  },
});
