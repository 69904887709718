import PropTypes from "prop-types";
import classNames from "classnames";
import { useTrialBanner } from "@/react/components/Layout/TrialBanner/useTrialBanner";
import { useDmsV3Enabled } from "@circle-react/hooks/useDmsV3Enabled";
import {
  Avatar,
  AvatarContainer,
  Row,
  RowContainer,
} from "@circle-react-uikit/SkeletonLoader/components";

export const ChatRoom = ({ hideMessageBox = false }) => {
  const { isTrialBannerExists } = useTrialBanner();
  const { isMessagingModalOpen } = useDmsV3Enabled();

  return (
    <div
      className={classNames("bg-primary flex flex-col justify-between py-5", {
        "lg:h-[calc(100vh-64px)]":
          !isMessagingModalOpen && !isTrialBannerExists, // Header height 64px
        "lg:h-[calc(100vh-112px)]":
          !isMessagingModalOpen && isTrialBannerExists, // Header height 64px + Trial banner height 48px
        "lg:h-[calc(100vh-224px)]": isMessagingModalOpen, // Header height 64px + Modal offset height 48px + Message box height 36px
      })}
    >
      <RowContainer lg full>
        {Array.from(Array(3).keys()).map(index => (
          <div key={index}>
            <div className="px-5 py-3">
              <AvatarContainer md full>
                <Avatar />
                <RowContainer full>
                  <Row md />
                  <Row md half />
                </RowContainer>
              </AvatarContainer>
            </div>
            <div className="px-5 py-3">
              <AvatarContainer md full>
                <Avatar />
                <RowContainer full>
                  <Row md />
                  <Row md almostFull />
                  <Row md half />
                </RowContainer>
              </AvatarContainer>
            </div>
          </div>
        ))}
      </RowContainer>
      {!hideMessageBox && (
        <div className="bg-primary z-50 w-full p-5">
          <div className="border-primary h-24 w-full rounded-lg border">
            <div className="flex h-full items-end space-x-3 p-4">
              <div className="bg-secondary h-6 w-6 rounded-lg" />
              <div className="bg-secondary h-6 w-6 rounded-lg" />
              <div className="bg-secondary h-6 w-6 rounded-lg" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

ChatRoom.propTypes = {
  hideMessageBox: PropTypes.bool,
};
