import create from "zustand";

interface FailedEmbedsState {
  failedLinkEmbeds: string[];
  addFailedLinkEmbed: (url: string) => void;
}

export const useFailedEmbedsStore = create<FailedEmbedsState>(set => ({
  failedLinkEmbeds: [],

  addFailedLinkEmbed: url => {
    set(state => ({
      failedLinkEmbeds: [...state.failedLinkEmbeds, url],
    }));
  },
}));
