import PropTypes from "prop-types";
import classnames from "classnames";
import { useBEMClasses } from "@circle-react/hooks/utils/useBEMClasses";

export const RowContainer = ({ children, className = "", ...props }) => {
  const blockElement = "skeleton-loader__row-container";
  const { modifiers } = useBEMClasses(blockElement, props);

  return (
    <div className={classnames(blockElement, modifiers, className)}>
      {children}
    </div>
  );
};

RowContainer.propTypes = {
  md: PropTypes.bool,
  lg: PropTypes.bool,
  xl: PropTypes.bool,
  full: PropTypes.bool,
  inline: PropTypes.bool,
  children: PropTypes.node,
};
