import type { MouseEvent, ReactNode } from "react";
import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { isFunction, noop } from "lodash";
import { blobPath } from "@circle-react/helpers/urlHelpers";
import { ImageEditorModalWithCrop } from "@circle-react-shared/ImageEditorModalWithCrop";
import { Actions } from "./Actions";
import type { ImagePreviewFunc } from "./ImagePreview";
import { ImagePreview } from "./ImagePreview";
import { Loader } from "./Loader";

export interface RendererProps {
  changeImageText?: string;
  customAddCoverBtn?: ReactNode;
  handleChangeCoverClick?: (event: any) => void;
  handleDelete?: (event: any) => void;
  imageUrl?: string;
  processing?: boolean;
  showAddCoverBtn?: boolean;
  value?: string;
}

export type RendererFunction = (props?: RendererProps) => ReactNode;

export interface ImageSelectorProps {
  alt?: string;
  aspectRatio?: number;
  changeImageText?: string;
  className?: string;
  customAddCoverBtn?: ReactNode;
  customAspectRatioHelpText?: string;
  hideEmbedUrl?: boolean;
  hideUnsplash?: boolean;
  isCropEnabled?: boolean;
  isOpen?: boolean;
  name: string;
  onChange?: (value: string | null) => void;
  onClose?: () => void;
  onDelete?: () => void;
  onImageAttachSuccess?: () => void;
  onProcessing?: (isProcessing: boolean) => void;
  renderer?: (props: RendererProps) => ReactNode;
  renderImagePreview?: ImagePreviewFunc;
  showAddCoverBtn?: boolean;
  title?: string;
  value: string;
}

export const ImageSelector = ({
  alt,
  aspectRatio,
  changeImageText,
  className,
  customAddCoverBtn,
  customAspectRatioHelpText,
  hideEmbedUrl = false,
  hideUnsplash = false,
  isCropEnabled = true,
  isOpen = false,
  name,
  onChange = noop,
  onClose = noop,
  onDelete = noop,
  onImageAttachSuccess = noop,
  onProcessing,
  renderer,
  renderImagePreview,
  showAddCoverBtn = false,
  title,
  value,
}: ImageSelectorProps) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [shouldShowImageSelector, setShouldShowImageSelector] =
    useState(isOpen);
  const [hasFile, setHasFile] = useState(false);
  const imageUrl = blobPath({ filename: name, signed_id: value });
  const prevIsProcessing = useRef(isProcessing);

  useEffect(() => {
    // small workaround to allow us to open/close it from outside the selector context
    if (isOpen) {
      setShouldShowImageSelector(true);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isFunction(onProcessing) && prevIsProcessing.current !== isProcessing) {
      onProcessing(isProcessing);
    }
    prevIsProcessing.current = isProcessing;
  }, [isProcessing, onProcessing]);

  const altText = alt ?? name;

  const handleDelete = (event: MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault();
    setIsProcessing(false);
    onChange(null);
    setHasFile(false);
    onDelete();
  };

  const handleChangeCoverClick = (event: MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault();
    setShouldShowImageSelector(true);
  };

  const attachImageSuccess: any = (_previewImage: any, signedId: any) => {
    onChange(signedId);
    setIsProcessing(false);
    onImageAttachSuccess && onImageAttachSuccess();
  };

  const uploadProgress = () => {
    setIsProcessing(true);
  };

  const ImagePreviewComponent = renderImagePreview ?? ImagePreview;

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions -- TODO: The event bubbling should be handled by the ImageEditorModalWithCrop component
    <div
      className={classNames("react-image-input form-input", className)}
      onClick={e => e.stopPropagation()}
    >
      <ImageEditorModalWithCrop
        showModal={shouldShowImageSelector}
        closeModal={() => {
          setShouldShowImageSelector(false);
          onClose();
        }}
        attachImage={attachImageSuccess}
        uploadProgress={uploadProgress}
        aspectRatio={aspectRatio}
        hideUnsplash={hideUnsplash}
        hideEmbedUrl={hideEmbedUrl}
        isCropEnabled={isCropEnabled}
        title={title}
        hasFile={hasFile}
        customAspectRatioHelpText={customAspectRatioHelpText}
        setHasFile={setHasFile}
      />
      {!renderer && (
        <>
          <Loader condition={isProcessing} />
          {!isProcessing && (
            <ImagePreviewComponent value={value} src={imageUrl} alt={altText} />
          )}
          <Actions
            value={value}
            isProcessing={isProcessing}
            handleDelete={handleDelete}
            handleChangeCoverClick={handleChangeCoverClick}
          />
        </>
      )}
      {renderer &&
        renderer({
          changeImageText,
          customAddCoverBtn,
          handleChangeCoverClick,
          handleDelete,
          imageUrl,
          processing: isProcessing,
          showAddCoverBtn,
          value,
        })}
    </div>
  );
};
