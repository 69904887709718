import { t } from "i18n-js";
import { ScoreCell } from "@circle-react/components/SettingsApp/ManageMembers/TabContent/Cells";
import type { MemberTag as MemberTagProps } from "@circle-react/types";
import { ScoreContextProvider } from "@circle-react-shared/ActivityScore";
import { BadgeV2 } from "@circle-react-shared/uikit/BadgeV2";
import { MemberTag } from "./MemberTag";
import { ShowMoreTag } from "./ShowMoreTag";

export interface MemberTagsProps {
  isAdmin?: boolean;
  limit?: number;
  memberTags: MemberTagProps[];
  showMoreTag?: boolean;
  memberId?: number;
  shouldShowActivityScore?: boolean;
  showMoreLabelVariant?: "words" | "numbers";
}

export const MemberTags = ({
  isAdmin = false,
  limit = 3,
  memberTags = [],
  showMoreTag = true,
  memberId,
  shouldShowActivityScore = false,
  showMoreLabelVariant,
}: MemberTagsProps) => {
  const visibleMemberTags = memberTags.filter(tag => tag.is_public);

  if (!visibleMemberTags.length && !isAdmin && !shouldShowActivityScore) {
    return null;
  }

  const memberTagsWithLabel = visibleMemberTags.slice(0, limit);
  const showMoreMemberTags = visibleMemberTags.slice(
    limit,
    visibleMemberTags.length,
  );

  return (
    <div className="flex flex-wrap gap-2">
      {shouldShowActivityScore && memberId && (
        <ScoreContextProvider ids={[memberId]}>
          <ScoreCell memberId={memberId} shouldHideIfNoScore />
        </ScoreContextProvider>
      )}

      {isAdmin && (
        <BadgeV2
          className="!bg-brand !text-brand-button !border-none"
          label={t("admin")}
          dataTestId="admin-badge"
        />
      )}

      {memberTagsWithLabel.map(memberTag => (
        <MemberTag memberTag={memberTag} key={memberTag.id} />
      ))}

      {showMoreTag && showMoreMemberTags.length > 0 && (
        <ShowMoreTag
          memberTags={showMoreMemberTags}
          labelVariant={showMoreLabelVariant}
        />
      )}
    </div>
  );
};
