import { t } from "i18n-js";
import type { MediaTranscodingStatus } from "@circle-react/types/MediaTranscoding";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";

interface TranscodingOverlayProps {
  status: Exclude<MediaTranscodingStatus, "succeeded">;
}

const i18nRoot = "media_player.transcoding";

export const TranscodingOverlay = ({ status }: TranscodingOverlayProps) => {
  const i18nKey = status === "failed" ? "failed" : "in_progress";

  return (
    <div className="absolute left-0 top-0 z-10 h-full w-full">
      <div className="h-full w-full bg-black opacity-80" />
      <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center">
        <div className="w-10/12 max-w-[360px] text-center">
          <div className="space-x-2">
            {status === "failed" && (
              <Icon
                type="20-alert"
                size={20}
                className="!h-4 !w-4 !text-white"
              />
            )}
            <Typography.LabelSm color="text-white" weight="semibold">
              {t([i18nRoot, i18nKey, "title"])}
            </Typography.LabelSm>
          </div>
          <Typography.LabelSm color="text-white">
            {t([i18nRoot, i18nKey, "description"])}
          </Typography.LabelSm>
        </div>
      </div>
    </div>
  );
};
