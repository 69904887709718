import { t } from "i18n-js";
import { Prompt } from "../../Prompt";
import { useKeyboardNavigation } from "../../hooks/useKeyboardNavigation";

export const PromptOptions = ({ onClick }) => {
  const localeBasePath = "tiptap.extensions.ai_copilot.prompt_options.editions";

  const OPTIONS = [
    {
      label: t(`${localeBasePath}.improve_writing`),
      value: "improve_writing",
    },
    {
      label: t(`${localeBasePath}.fix_text`),
      value: "fix_text",
    },
    {
      label: t(`${localeBasePath}.make_shorter`),
      value: "make_shorter",
    },
    {
      label: t(`${localeBasePath}.make_longer`),
      value: "make_longer",
    },
    {
      label: t(`${localeBasePath}.simplify_language`),
      value: "simplify_language",
    },
    {
      label: t(`${localeBasePath}.summarize`),
      value: "summarize",
    },
    {
      label: t(`${localeBasePath}.explain`),
      value: "explain",
    },
    {
      label: t(`${localeBasePath}.translate_spanish`),
      value: "translate",
    },
    {
      label: t(`${localeBasePath}.translate_french`),
      value: "translate",
    },
    {
      label: t(`${localeBasePath}.translate_dutch`),
      value: "translate",
    },
    {
      label: t(`${localeBasePath}.translate_italian`),
      value: "translate",
    },
    {
      label: t(`${localeBasePath}.translate_portuguese`),
      value: "translate",
    },
    {
      label: t(`${localeBasePath}.translate_english`),
      value: "translate",
    },
  ];

  const { activeIndex, setActiveIndex } = useKeyboardNavigation({
    optionsCount: OPTIONS.length,
    onEnter: index => {
      onClick(OPTIONS[index].value, OPTIONS[index].label);
    },
  });

  return (
    <Prompt.EditOptions
      activeIndex={activeIndex}
      setActiveIndex={setActiveIndex}
      onClick={({ item }) => onClick(item.value, item.label)}
      options={OPTIONS}
      shouldShowEnterIcon
    />
  );
};
