import PropTypes from "prop-types";
import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { Button } from "@circle-react-uikit/Button";
import { Form } from "@circle-react-uikit/Form";
import { FileTypeSelect } from "./FileTypeSelect";

const translationRoot = "media_player.transcript_menu";

export const TranscriptFormBody = ({
  isUpdating,
  supportsAi,
  aiFileDownloadUrl,
}) => {
  const { watch } = useFormContext();
  const isEnabled = watch("enabled");
  const validateSelectedFile = selectedFileValue => {
    if (selectedFileValue === "ai" && !supportsAi) {
      return t("media_player.transcript_menu.file_type.ai_not_supported_error");
    }
    return true;
  };

  return (
    <>
      <Form.Item
        name="enabled"
        translationRoot={translationRoot}
        hideBorder
        fullWidth
        inline
        className="!p-0"
        isDisabled={isUpdating}
      >
        <Form.ToggleSwitch variant="small" name="enabled" />
      </Form.Item>
      {isEnabled && (
        <Form.Item
          name="selected_file"
          translationRoot={translationRoot}
          hideBorder
          fullWidth
          inline
          hideLabel
          hideDescription
          isDisabled={isUpdating}
          rules={{
            validate: validateSelectedFile,
          }}
          className="flex flex-col"
        >
          <FileTypeSelect
            supportsAi={supportsAi}
            aiFileDownloadUrl={aiFileDownloadUrl}
          />
        </Form.Item>
      )}
      <div className="flex justify-end">
        <Button
          type="submit"
          variant="circle"
          disabled={isUpdating}
          className="mt-1"
        >
          {t("save")}
        </Button>
      </div>
    </>
  );
};

TranscriptFormBody.propTypes = {
  isUpdating: PropTypes.bool,
  supportsAi: PropTypes.bool,
  aiFileDownloadUrl: PropTypes.string,
};
