import {
  Avatar,
  AvatarContainer,
  Row,
  RowContainer,
} from "@circle-react-uikit/SkeletonLoader/components";
import { MembersAvatarRow } from "../MembersAvatarRow";

export interface MainHeaderProps {
  showMemberAvatarsRow: boolean;
}

export const MainHeader = ({ showMemberAvatarsRow }: MainHeaderProps) => (
  <>
    <div className="hidden h-16 items-center px-4 md:flex">
      <div
        style={{
          flex: "0 0 308px",
        }}
      >
        <RowContainer inline full>
          <Row xxl />
        </RowContainer>
      </div>
      <RowContainer inline full>
        <Row squareLarge />
        <Row lg half />
      </RowContainer>
      <div className="skeleton-loader--main-header-desktop__actions">
        <RowContainer inline full>
          <div className="mr-4 flex space-x-4">
            <Row square />
            <Row square />
            <Row square />
            {showMemberAvatarsRow && <MembersAvatarRow />}
          </div>
          <Row x2l long />
        </RowContainer>
      </div>
    </div>
    <div className="skeleton-loader skeleton-loader--main-header-mobile">
      <RowContainer>
        <Row square />
      </RowContainer>
      <div className="skeleton-loader--main-header-mobile__logo">
        <RowContainer inline full>
          <Row xxl />
        </RowContainer>
      </div>
      <AvatarContainer>
        <Avatar sm />
      </AvatarContainer>
    </div>
  </>
);
