import type { ReactNode } from "react";
import isFunction from "lodash/isFunction";
import type { LinkProps } from "react-router-dom";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import type { IconType } from "@circle-react-shared/Icon";
import { Icon } from "@circle-react-shared/Icon";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { Typography } from "@circle-react-uikit/Typography";
import { Item } from "./Item";
import { ItemLink } from "./ItemLink";

export interface RegularOption {
  badge?: string;
  badgeVariant?: string;
  color?: string;
  dataTestId?: string;
  description?: string;
  hide?: boolean;
  icon?: IconType;
  iconClassName?: string;
  iconSize?: number;
  label: ReactNode;
  onClick?: (() => void) | (() => Promise<void>);
  pathTo?: LinkProps["to"];
  value: string;
  isActive?: boolean;
  isDisabled?: boolean;
}

export interface SeparatorOption {
  value: "separator";
}

export type Option = RegularOption | SeparatorOption;

export const isSeparatorOption = (option: Option): option is SeparatorOption =>
  option.value === "separator";

export interface OptionProps {
  option: Option;
  onChange?: (value: string) => void;
  linkType?: "button" | "submit" | "reset";
  labelWeight?: string;
  isDark?: boolean;
  isActive?: boolean;
}

export const Option = ({
  option,
  onChange,
  linkType = "submit",
  labelWeight: labelWeightProp,
  isDark,
  isActive: isActiveProp,
}: OptionProps) => {
  const { isV3Enabled } = useIsV3();
  const labelWeight = labelWeightProp ?? (isV3Enabled ? "medium" : "normal");

  if (isSeparatorOption(option)) {
    return null;
  }

  if (option?.hide) return null;

  if (isDark) {
    option.color = "text-feature-light group-hover:text-dark";
  }

  return (
    <Item key={option.value}>
      {({ active }: { active: boolean }) => (
        <ItemLink
          active={isActiveProp !== undefined ? isActiveProp : active}
          to={option?.pathTo}
          onClick={() => {
            isFunction(onChange) && onChange(option.value);
            isFunction(option?.onClick) && option.onClick();
          }}
          linkType={linkType}
          isDark={isDark}
          isDisabled={option?.isDisabled}
        >
          <Dropdown.IconContainer>
            {isV3Enabled ? (
              <>
                {option.icon && (
                  <span className="h-5 w-5">
                    <Icon
                      className={option.iconClassName}
                      type={option.icon}
                      size={option.iconSize ?? 16}
                    />
                  </span>
                )}
                <Typography.LabelXsPlus
                  weight={labelWeight}
                  color={option.color}
                >
                  {option.label}
                </Typography.LabelXsPlus>
              </>
            ) : (
              <>
                {option.icon && (
                  <Icon
                    className={option.iconClassName}
                    type={option.icon}
                    size={option.iconSize ?? 16}
                  />
                )}
                <Typography.LabelSm weight={labelWeight} color={option.color}>
                  {option.label}
                </Typography.LabelSm>
              </>
            )}
          </Dropdown.IconContainer>
          {option.description && (
            <div className="py-0.5">{option.description}</div>
          )}
        </ItemLink>
      )}
    </Item>
  );
};
