import type { ComponentPropsWithoutRef, ReactNode } from "react";
import { Tab as HeadlessUITab } from "@headlessui/react";
import classNames from "classnames";

interface PanelsProps extends ComponentPropsWithoutRef<"div"> {
  children: ReactNode;
  className?: string;
}

export const Panels = ({ children, className = "", ...props }: PanelsProps) => (
  <HeadlessUITab.Panels
    as="div"
    data-testid="tab-panels"
    className={classNames("h-full w-full overflow-auto", className)}
    {...props}
  >
    {children}
  </HeadlessUITab.Panels>
);

Panels.displayName = "Tab.Panels";
