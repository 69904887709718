import { forwardRef } from "react";
import I18n from "i18n-js";

export const SearchBox = forwardRef<
  HTMLInputElement,
  {
    query: string;
    setQuery: (query: string) => void;
  }
>(({ query, setQuery }, ref) => (
  <div className="py-2">
    <input
      ref={ref}
      type="text"
      placeholder={I18n.t("tiptap.search_giphy")}
      onChange={event => setQuery(event.target.value)}
      value={query}
      className="bg-secondary placeholder:text-light w-full rounded border-none text-sm placeholder:opacity-100 focus:border-none focus:shadow-none focus:ring-0"
    />
  </div>
));

SearchBox.displayName = "SearchBox";
