import { useIsV3 } from "@circle-react/hooks/useIsV3";

export const Separator = () => {
  const { isV3Enabled } = useIsV3();

  if (!isV3Enabled) {
    <hr className="border-primary my-1.5 h-[1px] overflow-hidden border-t" />;
  }

  return (
    <div className="p-3">
      <hr className="border-primary h-[1px] overflow-hidden border-t" />
    </div>
  );
};
