import type { ReactNode } from "react";
import {
  DndContext as DndKitContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  restrictToHorizontalAxis,
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from "@dnd-kit/modifiers";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { useSortableListData } from "./SortableListDataProvider";

export interface DndContextProps {
  children: ReactNode;
  shouldUseKeyboardSensor: boolean;
}

export const DndContext = ({
  children,
  shouldUseKeyboardSensor,
}: DndContextProps) => {
  const {
    lockedVerticalAxis: hasLockedVerticalAxis,
    lockedHorizontalAxis: hasLockedHorizontalAxis,
    onDragStart,
    onDragEnd,
    onDragOver,
    distance,
  } = useSortableListData();

  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: distance,
    },
  });

  const keyboardSensor = useSensor(KeyboardSensor, {
    coordinateGetter: sortableKeyboardCoordinates,
  });

  const sensorArgs = [pointerSensor];
  if (shouldUseKeyboardSensor) {
    sensorArgs.push(keyboardSensor);
  }

  const sensors = useSensors(...sensorArgs);

  const modifiers = [
    restrictToWindowEdges,
    ...(hasLockedVerticalAxis ? [restrictToVerticalAxis] : []),
    ...(hasLockedHorizontalAxis ? [restrictToHorizontalAxis] : []),
  ];

  return (
    <DndKitContext
      sensors={sensors}
      modifiers={modifiers}
      collisionDetection={closestCenter}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragOver={onDragOver}
    >
      {children}
    </DndKitContext>
  );
};
