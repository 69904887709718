import classNames from "classnames";
import type { IconType } from "@circle-react-shared/Icon";
import { TextModifierIcon } from "@circle-react-shared/uikit/TipTap/Extensions/TextModifiers/TextModifierButton/TextModifierIcon";

interface AlignmentButtonProps {
  isActive: boolean;
  onClick: () => void;
  iconType: IconType;
}

export const AlignmentButton = ({
  isActive,
  onClick,
  iconType,
}: AlignmentButtonProps) => (
  <button
    type="button"
    onClick={onClick}
    className={classNames(
      "text-feature-dark bg-text-editor-modifier hover:bg-text-editor-modifier-hover rounded p-1",
      { "bg-text-editor-modifier-active": isActive },
    )}
  >
    <TextModifierIcon type={iconType} iconSize={16} />
  </button>
);
