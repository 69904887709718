import PropTypes from "prop-types";
import { RadioGroup } from "@headlessui/react";
import classNames from "classnames";
import { Typography } from "@circle-react-uikit/Typography";

export const SelectRadioGroup = ({ value, onChange, options }) => (
  <RadioGroup value={value} onChange={onChange}>
    <div className="flex items-center gap-6">
      {options.map(({ label, value }) => (
        <RadioGroup.Option
          key={value}
          value={value}
          className={({ checked }) =>
            classNames(
              "cursor-pointer select-none rounded-md px-3 py-2 leading-none transition-colors duration-200",
              {
                "bg-transparent": !checked,
                "bg-tertiary": checked,
              },
            )
          }
        >
          {({ checked }) => (
            <RadioGroup.Label as="span" className="whitespace-nowrap">
              <Typography.LabelSm weight={checked ? "medium" : "normal"}>
                {label}
              </Typography.LabelSm>
            </RadioGroup.Label>
          )}
        </RadioGroup.Option>
      ))}
    </div>
  </RadioGroup>
);

SelectRadioGroup.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};
