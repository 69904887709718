export const MAX_RECORDING_DURATION_MINUTES = 60;

export const RECORDER_STATUS = {
  WAITING_FOR_MIC_PERMISSION: "waiting_for_mic_permission",
  RECORDING: "recording",
  PROCESSING: "processing",
  UPLOADING: "uploading",
  UPLOAD_ERROR: "upload_error",
  SERVER_ERROR: "server_error",
};

export const WEBSOCKET_EVENT_SUCCESS = "VOICE_MESSAGE_PROCESSED";
export const WEBSOCKET_EVENT_ERROR = "VOICE_MESSAGE_ERROR";
