import { useCallback, useEffect, useRef, useState } from "react";
import { DirectUpload } from "@rails/activestorage";
import classNames from "classnames";
import { t } from "i18n-js";
import { useAttachmentGallery } from "@circle-react/components/Modals/AttachmentGalleryModal";
import {
  activeStorageBlobUrl,
  directUploadPath,
} from "@circle-react/helpers/urlHelpers";
import { AudioPlayer } from "@circle-react-shared/uikit/TipTapBlockEditor/FileExtension/AudioPlayer";
import { useTipTapEditorContext } from "../index";
import { scrollToBottom } from "../utilities";
import {
  contentTypeParser,
  supportsGalleryPreview,
} from "../utilities/contentTypeParser";
import { AttachmentDetails } from "./AttachmentDetails";
import { CancelButtonWithProgress } from "./CancelButtonWithProgress";
import { DownloadButton } from "./DownloadButton";

export interface AttachmentPreview {
  url: string;
  content_type: string;
  image_variants: {
    large: string;
    small: string;
    thumbnail: string;
    original: string;
  };
}

export interface Attachment extends AttachmentPreview {
  id: number;
  signed_id: string;
  file: File;
  toUpload: boolean;
  metadata: {
    voice_message: boolean;
    transcript_id: number;
    transcript_preview: string;
  };
}

export interface AttachmentItemProps {
  attachment: Attachment;
  index: number;
  shouldShowEnlarged: boolean;
  attachmentsForGalleryPreview: AttachmentPreview[];
}

export const AttachmentItem = ({
  attachmentsForGalleryPreview,
  attachment,
  index,
  shouldShowEnlarged,
}: AttachmentItemProps) => {
  const { editor, setLocalAttachments, shouldScrollIntoView } =
    useTipTapEditorContext();
  const { show: openAttachmentGallery } = useAttachmentGallery();

  const [progress, setProgress] = useState(0);
  const [isImgLoaded, setIsImgLoaded] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const queuedForCancellation = useRef(false);
  const imageVariants = attachment.image_variants || {};
  const removeCurrentAttachment = () => {
    setLocalAttachments((currentValue: any) =>
      currentValue.filter((_: any, i: any) => i !== index),
    );
  };

  const updateCurrentAttachment = useCallback(
    (newValue: any) => {
      setLocalAttachments((current: any) => {
        const newAttachments = [...current];
        newAttachments[index] = newValue;
        return newAttachments;
      });
    },
    [index, setLocalAttachments],
  );

  const remoteURLToFile = async ({ url, filename, content_type }: any) => {
    const response = await fetch(url);
    return new File([await response.blob()], filename, {
      type: content_type,
    });
  };

  const handleUpload = useCallback(async () => {
    setIsUploading(true);
    const file =
      attachment.file ||
      (await remoteURLToFile({
        url: attachment.url,
        filename: `${attachment.id}.${attachment.content_type.split("/")[1]}`,
        content_type: attachment.content_type,
      }));
    const upload = new DirectUpload(file, directUploadPath(), {
      directUploadWillStoreFileWithXHR: xhr =>
        xhr.upload.addEventListener("progress", e => {
          if (queuedForCancellation.current) {
            xhr.abort();
            setIsUploading(false);
          }
          setProgress((e.loaded / e.total) * 100);
        }),
    });
    upload.create((error, blob) => {
      if (error) {
        throw new Error(`Direct upload failed: ${String(error)}`);
      } else {
        const url = activeStorageBlobUrl({
          signed_id: blob.signed_id,
          filename: blob.filename,
        });
        updateCurrentAttachment({
          ...blob,
          url,
          uploaded: true,
        });
        setIsUploading(false);
      }
    });
  }, [attachment, updateCurrentAttachment]);

  useEffect(() => {
    if (attachment.toUpload && !isUploading) {
      void handleUpload();
    }
  }, [attachment, isUploading, handleUpload]);

  const isImage = contentTypeParser.isImage(attachment.content_type);
  const isAudio = contentTypeParser.isAudio(attachment.content_type);
  const isVoiceMessage = isAudio && Boolean(attachment.metadata?.voice_message);
  const attachmentSupportGalleryPreview = supportsGalleryPreview(
    attachment.content_type,
  );

  let imagePreviewURL = attachment.url;
  if (!editor.isEditable) {
    if (shouldShowEnlarged) {
      imagePreviewURL = imageVariants.small ?? attachment.url;
    } else {
      imagePreviewURL = imageVariants.thumbnail ?? attachment.url;
    }
  }

  imagePreviewURL = imagePreviewURL ?? attachment?.url;

  const imageEventListeners = {
    onLoad: () => {
      setIsImgLoaded(true);
      if (shouldScrollIntoView) {
        scrollToBottom();
      }
    },
    onError: () => {
      setIsImgLoaded(true);
    },
  };

  return (
    <button
      type="button"
      onClick={() =>
        !editor.isEditable &&
        attachmentSupportGalleryPreview &&
        openAttachmentGallery({
          attachments: attachmentsForGalleryPreview,
          startIndex: attachmentsForGalleryPreview.findIndex(
            a => a.url == attachment.url,
          ),
        })
      }
      className={classNames("relative flex max-w-[264px] cursor-pointer", {
        "h-[60px] min-w-[150px] px-4 py-2": !isImage && !isVoiceMessage,
        "h-[60px] w-[60px]": isImage && !shouldShowEnlarged && !isVoiceMessage,
        "h-[200px] w-auto min-w-[150px]":
          isImage && shouldShowEnlarged && !isVoiceMessage,
        "border-tertiary bg-tertiary group gap-2 rounded-md border":
          !isVoiceMessage,
      })}
    >
      {editor.isEditable && (
        <CancelButtonWithProgress
          isUploading={isUploading}
          progress={progress}
          queuedForCancellation={queuedForCancellation}
          removeCurrentAttachment={removeCurrentAttachment}
        />
      )}
      {!attachmentSupportGalleryPreview &&
        !editor.isEditable &&
        !isVoiceMessage && <DownloadButton url={attachment.url} />}
      {isImage && (
        <img
          loading="eager"
          alt={t("tiptap.attachment_previews.image")}
          className={classNames(
            "rounded-md object-cover",
            isImgLoaded ? "border-primary h-full w-full border" : "hidden",
          )}
          src={imagePreviewURL}
          {...imageEventListeners}
        />
      )}
      {isVoiceMessage && (
        <AudioPlayer
          src={attachment.url}
          type={attachment.content_type}
          controls={["play", "progress", "current-time"]}
          isVoiceMessage={isVoiceMessage}
          transcriptionId={attachment.metadata?.transcript_id}
          transcriptionPreview={attachment.metadata?.transcript_preview}
        />
      )}
      {!attachmentSupportGalleryPreview && !isVoiceMessage && (
        <AttachmentDetails attachment={attachment} />
      )}
    </button>
  );
};
