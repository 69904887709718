import { useMemo } from "react";
import { t } from "i18n-js";
import { flatten } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { MemberRow } from "@circle-react/components/PostsV2/PostViewListItem/MemberRow";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { useInfiniteQueryWrapper } from "@circle-react/hooks/reactQuery/useInfiniteQueryWrapper";
import { LoaderRow } from "@circle-react-shared/LoaderRow";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { Modal } from "@circle-react-uikit/ModalV2";

interface PollVoteListModalProps {
  pollOptionId: number;
  pollId: number;
  pollValue: string;
  percentage: number;
}

const PollVoteListModalComponent = ({
  pollOptionId,
  pollId,
  pollValue,
  percentage,
}: PollVoteListModalProps) => {
  const modal = useModal();
  const { data, isLoading, fetchNextPage, hasNextPage, mapPages } =
    useInfiniteQueryWrapper(
      internalApi.polls.voteList({
        pollId,
        pollOptionId,
        params: {
          per_page: 15,
        },
      }),
      { enabled: !!pollId && !!pollOptionId },
      ["poll-vote-list", pollId, pollOptionId],
    );

  const votes = useMemo(
    () => flatten(mapPages((page: any) => page.records)),
    [mapPages],
  );
  const totalVotesCount = data?.pages[0]?.count;

  return (
    <Modal isOpen={modal.visible} onClose={modal.remove}>
      <Modal.Overlay />
      <Modal.Content shouldCloseOnOutsideClick>
        <Modal.Header>
          <Modal.Title size="sm">{pollValue}</Modal.Title>
          <Modal.HeaderActions>
            <Modal.CloseButton onClick={modal.hide} />
          </Modal.HeaderActions>
        </Modal.Header>
        {!isLoading && (
          <Typography.LabelSm weight="regular">
            <div className="space-x-1 px-5 pb-4 md:px-8">
              <span>{percentage}%</span>
              <span>{t("separator_dot")}</span>
              <span>
                {t("tiptap.extensions.poll.votes", {
                  count: totalVotesCount,
                })}
              </span>
            </div>
          </Typography.LabelSm>
        )}
        <Modal.Body
          id={`post-likes-modal-${pollId}-${pollOptionId}`}
          className="overflow-auto pb-4 pt-0 sm:max-h-80"
        >
          {isLoading ? (
            <LoaderRow />
          ) : (
            <InfiniteScroll
              scrollThreshold={0.75}
              next={fetchNextPage}
              hasMore={hasNextPage || false}
              loader={<LoaderRow />}
              scrollableTarget={`post-likes-modal-${pollId}-${pollOptionId}`}
              dataLength={votes.length}
              className="flex flex-col space-y-4"
            >
              {votes.map(vote => (
                <MemberRow
                  key={vote.id}
                  member={vote.community_member}
                  hideModal={modal.hide}
                />
              ))}
            </InfiniteScroll>
          )}
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export const usePollVoteListModal = () =>
  useModal(
    ModalManager.create((props: PollVoteListModalProps) => (
      <PollVoteListModalComponent {...props} />
    )),
  );
