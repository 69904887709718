import { forwardRef } from "react";
import classNames from "classnames";
import { Controller, useFormContext } from "react-hook-form";
import type { TextareaAutosizeProps } from "react-textarea-autosize";
import TextAreaAutosize from "react-textarea-autosize";

export interface FormTextAreaAutosizeProps extends TextareaAutosizeProps {
  name: string;
}

export const FormTextAreaAutosize = forwardRef<
  HTMLTextAreaElement,
  FormTextAreaAutosizeProps
>(({ name, className, ...passThroughProps }, ref) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, name } }) => (
        <TextAreaAutosize
          {...passThroughProps}
          className={classNames(
            "placeholder:text-light placeholder:opacity-100",
            className,
          )}
          name={name}
          onChange={({ target: { value } }) => onChange(value)}
          value={value}
          ref={ref}
        />
      )}
    />
  );
});

FormTextAreaAutosize.displayName = "FormTextAreaAutosize";
