import { BubbleMenu } from "@tiptap/react";
import { t } from "i18n-js";
import { TextModifierButton } from "@circle-react-shared/uikit/TipTap/Extensions/TextModifiers/TextModifierButton";
import { shouldShowToolbar } from "@circle-react-shared/uikit/TipTap/Extensions/TextModifiers/utilities";
import { useAddTipTapLinkModal } from "@circle-react-shared/uikit/TipTapBlockEditor/AddTipTapLinkModal";

export const TextModifiersMenu = ({ editor }: any) => {
  const useAddTipTapLink = useAddTipTapLinkModal();
  const options = [
    {
      name: "bold",
      iconType: "20-character-bold",
      label: t("tiptap.bubble_menu.bold"),
      onClick: () => editor.chain().focus().toggleBold().run(),
    },
    {
      name: "italic",
      iconType: "20-character-italic",
      label: t("tiptap.bubble_menu.italic"),
      onClick: () => editor.chain().focus().toggleItalic().run(),
    },
    {
      name: "strike",
      iconType: "20-character-strike",
      label: t("tiptap.bubble_menu.strike"),
      onClick: () => editor.chain().focus().toggleStrike().run(),
    },
    {
      name: "underline",
      iconType: "20-character-underline",
      label: t("tiptap.bubble_menu.underline"),
      onClick: () => editor.chain().focus().toggleUnderline().run(),
    },
    {
      name: "link",
      iconType: "20-character-link",
      label: t("tiptap.bubble_menu.link"),
      onClick: () => useAddTipTapLink.show({ editor: editor }),
    },
  ];

  return (
    <div>
      <BubbleMenu
        editor={editor}
        tippyOptions={{
          duration: 100,
          arrow: false,
          placement: "top",
          theme: "text-modifiers",
          animation: "shift-away-subtle",
          zIndex: 1,
        }}
        shouldShow={shouldShowToolbar}
      >
        <div className="bg-primary border-primary divide-primary flex gap-1 divide-x rounded-lg border p-1 shadow-sm">
          <div>
            {options.map(option => (
              <TextModifierButton
                key={option.name}
                isActive={editor.isActive(option.name)}
                {...option}
              />
            ))}
          </div>
        </div>
      </BubbleMenu>
    </div>
  );
};
