import { useEffect, useState } from "react";
import { getEmojiMart } from "../getEmojiMart";

export const useFetchEmojis = (query: string) => {
  const [emojis, setEmojis] = useState<any[]>([]);

  useEffect(() => {
    const fetchEmojis = async () => {
      let searchQuery = query;
      if (query === ")" || query === "(") {
        searchQuery = `:${query}`;
      }

      const module = await getEmojiMart();
      const { SearchIndex } = module;

      const emojiList = await SearchIndex.search(searchQuery || "face");

      const usableEmojis = emojiList.filter(
        (emoji: any) => emoji?.skins?.[0]?.native,
      );

      setEmojis(usableEmojis);
    };
    void fetchEmojis();
  }, [query]);

  return { emojis };
};
