import type { ReactNode } from "react";
import { useRef, useState } from "react";
import { t } from "i18n-js";
import { uniqueId } from "lodash";
import { useClickAway } from "react-use";
import { Typography } from "@circle-react-uikit/Typography";
import { CloseButton } from "./CloseButton";
import { Cue } from "./Cue";
import { FullscreenButton } from "./FullscreenButton";
import { FullscreenView } from "./FullscreenView";
import { isCurrentCue } from "./helpers";
import { useShouldAutoScroll } from "./useShouldAutoScroll";

export interface Cue {
  text: string;
  startTime: number;
}

export interface TranscriptPopupProps {
  mediaTranscriptId: number;
  onSeek: (time: number) => void;
  onFullscreenSeek: (time: number) => void;
  onFullscreenToggle: () => void;
  renderFullscreenPlayer: () => ReactNode;
  cues: Cue[];
  currentTime: number;
}

export const TranscriptPopup = ({
  mediaTranscriptId,
  onSeek,
  onFullscreenSeek,
  onFullscreenToggle,
  renderFullscreenPlayer,
  cues = [],
  currentTime,
}: TranscriptPopupProps) => {
  const popupRef = useRef(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const togglePopup = () => setIsPopupOpen(!isPopupOpen);
  const closePopup = () => setIsPopupOpen(false);
  useClickAway(popupRef, closePopup);
  const { containerRef: cuesContainerRef, shouldAutoScroll } =
    useShouldAutoScroll();
  const toggleFullscreen = () => {
    onFullscreenToggle?.();
    setIsPopupOpen(false);
    setIsFullscreen(!isFullscreen);
  };

  if (!cues?.length) {
    return null;
  }

  const firstCue: Cue = cues[0] || { text: "", startTime: 0 };

  return (
    <div className="relative">
      <blockquote>
        <Typography.BodyXs as="div">{firstCue.text}</Typography.BodyXs>
        <div>
          <button
            type="button"
            className="text-link relative cursor-pointer text-xs hover:underline"
            onClick={togglePopup}
            ref={popupRef}
          >
            {isPopupOpen
              ? t("media_player.hide_transcript")
              : t("media_player.show_transcript")}
            {isPopupOpen && (
              <div className="bg-primary border-light absolute bottom-0 left-full z-20 ml-2 flex h-72 w-96 flex-col rounded-md border p-4">
                <div className="mb-2 flex shrink-0 items-start justify-between">
                  <Typography.BodyMd as="div" color="text-dark">
                    {t("media_player.transcript")}
                  </Typography.BodyMd>
                  <div className="flex items-center gap-1">
                    <FullscreenButton onClick={toggleFullscreen} />
                    <CloseButton onClick={togglePopup} />
                  </div>
                </div>
                <div className="grow overflow-auto" ref={cuesContainerRef}>
                  {cues.map(cue => (
                    <Cue
                      key={uniqueId(`transcript-${mediaTranscriptId}`)}
                      cue={cue}
                      isCurrentCue={isCurrentCue(cue, currentTime)}
                      shouldScroll={shouldAutoScroll}
                      onSeek={onSeek}
                    />
                  ))}
                </div>
              </div>
            )}
          </button>
        </div>
      </blockquote>
      <FullscreenView
        isOpen={isFullscreen}
        onClose={toggleFullscreen}
        cues={cues}
        onSeek={onFullscreenSeek}
        currentTime={currentTime}
      >
        {renderFullscreenPlayer()}
      </FullscreenView>
    </div>
  );
};
