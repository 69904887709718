import { useState } from "react";
import PropTypes from "prop-types";
import { t } from "i18n-js";
import { Icon } from "@circle-react-shared/Icon";
import { FullScreenModal } from "@circle-react-shared/ModalsV2/FullScreenModal";
import { Typography } from "@circle-react-uikit/Typography";
import { Cue } from "./Cue";
import { Search } from "./Search";
import { isCurrentCue } from "./helpers";
import { useShouldAutoScroll } from "./useShouldAutoScroll";

export const FullscreenView = ({
  isOpen,
  onClose,
  cues,
  onSeek,
  currentTime,
  children,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { containerRef: cuesContainerRef, shouldAutoScroll } =
    useShouldAutoScroll();
  const onSearchTermChange = e => setSearchTerm(e.target.value);
  const filteredCues = cues.filter(
    cue =>
      !searchTerm || cue.text.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <FullScreenModal
      shouldCloseOnOutsideClick
      shouldShowCloseButton
      isOpen={isOpen}
      contentPortalId="fullscreen-transcripts-portal"
      position="bottom"
      onClose={onClose}
    >
      <div className="relative flex h-full items-stretch">
        <div className="bg-dark relative grow">
          <div
            id="transcripts-fullscreenmodal-player"
            className="absolute inset-0"
          >
            {children}
          </div>
        </div>
        <div className="border-primary flex w-1/3 max-w-sm shrink-0 flex-col border-l">
          <div className="border-primary mb-2 flex h-16 shrink-0 items-center gap-2 border-b px-4">
            <Icon type="20-ai" size={20} />
            <Typography.LabelLg weight="semibold">
              {t("media_player.transcript")}
            </Typography.LabelLg>
          </div>
          <div className="px-4 py-2">
            <Search onChange={onSearchTermChange} />
          </div>
          <div className="relative grow">
            <div
              className="absolute inset-0 overflow-auto p-4"
              ref={cuesContainerRef}
            >
              {filteredCues.map((cue, index) => (
                <Cue
                  key={`transcript-fullscreen-${index}`}
                  cue={cue}
                  isCurrentCue={isCurrentCue(cue, currentTime)}
                  shouldScroll={shouldAutoScroll}
                  onSeek={onSeek}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </FullScreenModal>
  );
};

FullscreenView.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  cues: PropTypes.arrayOf(
    PropTypes.shape({
      startTime: PropTypes.number,
      text: PropTypes.string,
    }),
  ),
  onSeek: PropTypes.func.isRequired,
  currentTime: PropTypes.number,
};
