import { Node } from "@tiptap/core";
import {
  ReactNodeViewRenderer,
  ReactRenderer,
  mergeAttributes,
} from "@tiptap/react";
import Suggestion from "@tiptap/suggestion";
import { PluginKey } from "prosemirror-state";
import tippy from "tippy.js";
import { getPortalTarget } from "@circle-react-shared/uikit/TipTap/utilities/getPortalTarget";
import { checkIfContentMatches, insertNodeAtRange } from "../utilities";
import { MentionList } from "./MentionList";
import { MentionsDisplay } from "./MentionsDisplay";

export const Mentions = portalTargetId =>
  Node.create({
    name: "mention",
    group: "inline",
    inline: true,
    selectable: false,
    atom: true,
    addAttributes: () => ({
      sgid: {
        default: null,
        parseHTML: element => element.getAttribute("data-sgid"),
        renderHTML: attributes =>
          attributes.sgid
            ? {
                "data-sgid": attributes.sgid,
              }
            : {},
      },
    }),
    parseHTML: () => [{ tag: "mention-react" }],
    renderHTML: ({ HTMLAttributes }) => [
      "mention-react",
      mergeAttributes(HTMLAttributes),
    ],
    addNodeView() {
      return ReactNodeViewRenderer(MentionsDisplay);
    },
    addProseMirrorPlugins() {
      const appendTarget = getPortalTarget(portalTargetId);
      return [
        Suggestion({
          editor: this.editor,
          char: "@",
          allowSpaces: true,
          pluginKey: new PluginKey("mention"),
          command: ({ editor, range, props }) =>
            insertNodeAtRange({ editor, range, name: this.name, attrs: props }),
          allow: ({ state, range }) =>
            checkIfContentMatches({ state, range, name: this.name }),
          render: () => {
            let reactRenderer;
            let popup;

            return {
              onStart: props => {
                reactRenderer = new ReactRenderer(MentionList, {
                  props,
                  editor: props.editor,
                });

                popup = tippy("body", {
                  getReferenceClientRect: props.clientRect,
                  appendTo: () => appendTarget,
                  content: reactRenderer.element,
                  showOnCreate: true,
                  interactive: true,
                  trigger: "manual",
                  placement: "auto-end",
                  theme: "light-border",
                });
              },

              onUpdate(props) {
                reactRenderer.updateProps(props);

                popup[0].setProps({
                  getReferenceClientRect: props.clientRect,
                });
              },

              onKeyDown(props) {
                if (props.event.key === "Escape") {
                  popup[0].hide();

                  return true;
                }

                return reactRenderer.ref?.onKeyDown(props);
              },

              onExit() {
                popup[0].destroy();
                reactRenderer.destroy();
              },
            };
          },
        }),
      ];
    },
  });
