import {
  Avatar,
  AvatarContainer,
  Row,
  RowContainer,
} from "@circle-react-uikit/SkeletonLoader/components";

const renderAvatarContainer = (index: number) => (
  <div key={index} className="py-1.5">
    <AvatarContainer md full>
      <Avatar />
      <RowContainer full>
        <Row md />
        <Row md full />
      </RowContainer>
    </AvatarContainer>
  </div>
);

export const ChatThreadsListHeader = () => (
  <>
    <RowContainer lg full>
      <div className="bg-primary border-primary mb-2 rounded-2xl border p-5">
        <div className="mb-4">
          <RowContainer full>
            <Row md />
            <Row md half />
          </RowContainer>
        </div>
        {Array.from(Array(4).keys()).map(renderAvatarContainer)}
      </div>
    </RowContainer>
    <RowContainer lg full>
      <div className="bg-primary border-primary mb-2 rounded-2xl border p-5">
        <div className="mb-4">
          <RowContainer full>
            <Row md />
            <Row md half />
          </RowContainer>
        </div>
        {Array.from(Array(3).keys()).map(renderAvatarContainer)}
      </div>
    </RowContainer>
  </>
);
