import { NodeViewWrapper } from "@tiptap/react";
import { useShowCopilot } from "../hooks/useShowCopilot";
import { CleanupWrapper } from "./CleanupWrapper";
import { DraftPrompt } from "./DraftPrompt";
import { EditionPrompt } from "./EditionPrompt";
import { AiBlockContextProvider } from "./hooks/useAiBlockContext";

export const AiCopilotBlock = ({ editor, node, getPos, deleteNode }) => {
  const { range } = node.attrs;

  const { shouldShowCopilot } = useShowCopilot();

  if (!shouldShowCopilot) {
    return null;
  }

  return (
    <AiBlockContextProvider
      editor={editor}
      node={node}
      getPos={getPos}
      deleteNode={deleteNode}
    >
      <NodeViewWrapper>
        <CleanupWrapper>
          <div className="mx-auto my-4 flex items-center justify-center">
            <div className="from-ai-start to-ai-end relative z-10 w-full rounded-lg bg-gradient-to-r p-0.5 shadow-xl">
              {range ? <EditionPrompt /> : <DraftPrompt />}
            </div>
          </div>
        </CleanupWrapper>
      </NodeViewWrapper>
    </AiBlockContextProvider>
  );
};
