import { times } from "lodash";
import {
  Row,
  RowContainer,
} from "@circle-react-uikit/SkeletonLoader/components";

export interface NewEventsListViewProps {
  count?: number;
}

export const NewEventsListView = ({ count = 8 }: NewEventsListViewProps) => (
  <div className="mx-6 max-w-2xl space-y-9 p-4 pb-12 md:mx-auto">
    {times(count, index => (
      <div key={index} className="flex h-[90px] w-full items-center space-x-6">
        <div className="bg-tertiary h-full w-[75px] shrink-0 rounded-md" />
        <RowContainer full>
          <div className="flex justify-between space-x-8">
            <RowContainer full>
              <Row md full />
              <Row md almostFull />
            </RowContainer>
            <div className="mr-4">
              <Row lg short className="!h-6" />
            </div>
          </div>
        </RowContainer>
      </div>
    ))}
  </div>
);
