import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { PdfPreview } from "@circle-react/components/shared/AttachmentPreview/PdfPreview";
import { Icon } from "@circle-react/components/shared/Icon";
import { Modal } from "@circle-react/components/shared/uikit/ModalV2";

export interface FilePreviewModalProps {
  file: any;
}

export const FilePreviewModalComponent = ({ file }: FilePreviewModalProps) => {
  const modal = useModal();
  const isPDF = file.content_type === "application/pdf";
  if (!isPDF) return null;
  return (
    <Modal isOpen={modal.visible} onClose={modal.remove}>
      <Modal.Overlay>
        <Modal.Content size="full">
          <Modal.Header>
            <Modal.Title size="sm">{file.filename}</Modal.Title>
            <Modal.HeaderActions>
              <a
                href={file.url}
                download
                className="text-dark hover:text-darkest focus:text-dark flex items-center justify-center"
              >
                <Icon type="16-download" size={16} />
              </a>
              <Modal.CloseButton onClick={modal.remove} />
            </Modal.HeaderActions>
          </Modal.Header>
          <Modal.Body>
            <PdfPreview className="h-full w-full" src={file.url} />
          </Modal.Body>
        </Modal.Content>
      </Modal.Overlay>
    </Modal>
  );
};

const FilePreviewModal = ModalManager.create(
  ({ file }: FilePreviewModalProps) => (
    <FilePreviewModalComponent file={file} />
  ),
);

export const useFilePreviewModal = () => useModal(FilePreviewModal);
