import type { ReactNode } from "react";
import classNames from "classnames";
import { useIsV3 } from "@circle-react/hooks/useIsV3";

export interface IconContainerProps {
  children: ReactNode;
}

export const IconContainer = ({ children }: IconContainerProps) => {
  const { isV3Enabled } = useIsV3();

  return (
    <div
      className={classNames("flex items-center", {
        "text-default gap-2.5": !isV3Enabled,
        "text-dark gap-2": isV3Enabled,
      })}
    >
      {children}
    </div>
  );
};
