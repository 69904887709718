import { useEffect, useState } from "react";
import { secondsToTimeString } from "@circle-react/helpers/dateTimeHelpers/index";

export const Timer = ({ isRecording }) => {
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    if (isRecording) {
      const timer = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds + 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [isRecording]);

  return <div>{secondsToTimeString(seconds)}</div>;
};
