import type { ReactNode } from "react";

interface BodyProps {
  children: ReactNode;
}

export const Body = ({ children }: BodyProps) => (
  <div className="bg-primary border-light absolute right-0 top-1 w-80 cursor-default rounded-lg border p-5">
    {children}
  </div>
);
