import { useCallback, useEffect, useRef, useState } from "react";
import Hls from "hls.js";

export interface UseHlsOptions {
  id?: string;
  renderKey?: string;
  src?: string;
  enabled?: boolean;
  autoStartLoadEnabled?: boolean;
}

export interface QualityOptions {
  default: number;
  options: number[];
  forced: boolean;
  onChange: (newQuality: number) => void;
}

const isMediaElement = (value: any): value is HTMLMediaElement =>
  value instanceof HTMLMediaElement;

export const useHls = ({
  id,
  renderKey,
  src,
  enabled = true,
  autoStartLoadEnabled = false,
}: UseHlsOptions) => {
  const hlsRef = useRef<Hls | null>(null);
  const [quality, setQuality] = useState<QualityOptions | null>(null);
  const hasQualityRef = useRef(false);

  useEffect(() => {
    if (!enabled || !src || !id) return;

    const videoElement = document.getElementById(id);

    if (!isMediaElement(videoElement)) return;

    hlsRef.current = new Hls({ autoStartLoad: autoStartLoadEnabled });
    hlsRef.current.loadSource(src);
    hlsRef.current.attachMedia(videoElement);

    hlsRef.current.once(Hls.Events.MANIFEST_PARSED, () => {
      if (!hlsRef.current || hasQualityRef.current) {
        return;
      }
      const levels = hlsRef.current.levels;

      const qualityOptions: QualityOptions = {
        default: levels[levels.length - 1].height,
        options: levels.map(level => level.height),
        forced: true,
        onChange: newQuality => {
          levels.forEach((level, levelIndex) => {
            if (hlsRef.current && level.height === newQuality) {
              hlsRef.current.currentLevel = levelIndex;
            }
          });
        },
      };

      setQuality(qualityOptions);
      hasQualityRef.current = true;
    });

    return () => {
      hlsRef.current?.destroy();
      hlsRef.current = null;
    };
  }, [renderKey, id, src, enabled, autoStartLoadEnabled]);

  const startLoad = useCallback(() => hlsRef.current?.startLoad(), []);

  return { quality, startLoad };
};
