import { useEffect, useState } from "react";
import { t } from "i18n-js";
import { delay } from "lodash";
import { BlockInViewOnlyMode } from "@circle-react/components/ViewOnlyMasquerading/BlockInViewOnlyMode";
import { usePunditUserContext } from "@circle-react/contexts";
import { Dropdown } from "@circle-react-shared/uikit/Dropdown";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { useEditPollModal } from "@circle-react-shared/uikit/TipTapBlockEditor/PollExtension/EditPollModal";
import { PollFooter } from "@circle-react-shared/uikit/TipTapBlockEditor/PollExtension/PollDisplay/PollFooter";
import { PollOption } from "@circle-react-shared/uikit/TipTapBlockEditor/PollExtension/PollDisplay/PollOption";
import { useUndoMutation } from "@circle-react-shared/uikit/TipTapBlockEditor/PollExtension/PollDisplay/api";
import type { PollResponse } from "@circle-react-shared/uikit/TipTapBlockEditor/PollExtension/PollModal/api";
import { Typography } from "@circle-react-shared/uikit/Typography";

const UNDO_TIMEOUT = 15000;

export const PollWrapper = ({
  poll,
  isEditable,
  deleteNode,
}: {
  isEditable: boolean;
  poll: PollResponse;
  deleteNode: () => void;
}) => {
  const { currentCommunityMember } = usePunditUserContext();
  const editPollModal = useEditPollModal();
  const [canRespond, setCanRespond] = useState<boolean>(
    !poll.community_member_response_option_id && !!currentCommunityMember,
  );
  const [selectedOptionId, setSelectedOptionId] = useState<number | undefined>(
    poll.community_member_response_option_id,
  );
  const [undoCount, setUndoCount] = useState<number>(0);
  const shouldShowResults = Boolean(
    poll.results && "detailed_results" in poll.results,
  );

  const undoMutation = useUndoMutation();
  useEffect(() => {
    if (selectedOptionId) {
      delay(() => {
        setCanRespond(false);
      }, UNDO_TIMEOUT);
    }
  }, [selectedOptionId]);

  const canUndo = Boolean(selectedOptionId && canRespond && undoCount < 1);
  const undo = () => {
    setSelectedOptionId(undefined);
    setCanRespond(true);
    setUndoCount(undoCount + 1);
    undoMutation.mutate(poll.id);
  };

  return (
    <BlockInViewOnlyMode>
      <div
        className="border-primary space-y-4 rounded-lg border px-6 py-5"
        data-testid="poll-wrapper"
      >
        <div
          className="text-darkest flex items-start justify-between"
          data-testid="poll-header"
        >
          <Typography.TitleSm weight="semibold">
            {poll.title}
          </Typography.TitleSm>
          {isEditable && (
            <Dropdown
              direction="bottom-end"
              linkType="button"
              button={
                <div>
                  <Dropdown.MenuButton>
                    <IconButton
                      ariaLabel={t("options_dropdown")}
                      name="16-menu-dots-horizontal"
                    />
                  </Dropdown.MenuButton>
                </div>
              }
              options={[
                {
                  label: t("tiptap.extensions.poll.edit"),
                  onClick: () =>
                    editPollModal.show({
                      pollSgid: poll.sgid,
                    }),
                  value: "edit",
                },
                {
                  label: t("tiptap.extensions.poll.remove"),
                  value: "remove",
                  onClick: () => deleteNode(),
                },
              ]}
              dataTestId={`poll-${poll.id}-actions`}
            />
          )}
        </div>
        <div className="space-y-3" data-testid="poll-options">
          {poll.poll_options.map(option => (
            <PollOption
              key={option.id}
              poll={poll}
              option={option}
              canRespond={canRespond}
              selectedOptionId={selectedOptionId}
              setSelectedOptionId={setSelectedOptionId}
              shouldShowResults={shouldShowResults}
            />
          ))}
        </div>
        <PollFooter poll={poll} canUndo={canUndo} undo={undo} />
      </div>
    </BlockInViewOnlyMode>
  );
};
