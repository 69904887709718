import { Node } from "@tiptap/core";
import { ReactNodeViewRenderer, mergeAttributes } from "@tiptap/react";
import { Renderer } from "./Renderer";

export const CTAExtension = Node.create({
  name: "cta",
  group: "block",
  atom: true,
  addAttributes: () => ({
    url: {
      default: null,
    },
    label: {
      default: null,
    },
    color: {
      default: null,
    },
    full_width: {
      default: false,
    },
    alignment: {
      default: "center",
    },
  }),
  parseHTML: () => [{ tag: "cta-react" }],
  renderHTML: ({ HTMLAttributes }) => [
    "cta-react",
    mergeAttributes(HTMLAttributes),
  ],
  addNodeView: () => ReactNodeViewRenderer(Renderer),
});
