import { AiIcon } from "./AiIcon";
import { Container } from "./Container";
import { EditOptions } from "./EditOptions";
import { Input } from "./Input";
import { LoadingIcon } from "./LoadingIcon";
import { Text } from "./Text";

const Prompt = Container;
Prompt.AiIcon = AiIcon;
Prompt.Input = Input;
Prompt.LoadingIcon = LoadingIcon;
Prompt.Text = Text;
Prompt.EditOptions = EditOptions;

export { Prompt };
