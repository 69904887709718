import type { ReactNode } from "react";
import { Tab as HeadlessUITab } from "@headlessui/react";
import classNames from "classnames";
import { isFunction, noop } from "lodash";

interface GroupProps {
  children: ReactNode;
  className?: string;
  selectedIndex?: number;
  id?: string;
  onChange?: (index: number) => void;
}

export const Group = ({ children, className = "", ...props }: GroupProps) => (
  <HeadlessUITab.Group
    as="div"
    data-testid="tab-group"
    selectedIndex={props.selectedIndex}
    onChange={
      isFunction(props.onChange)
        ? index => {
            props.onChange?.(index);
          }
        : noop
    }
    className={classNames("text-dark h-full w-full overflow-auto", className)}
    {...props}
  >
    {children}
  </HeadlessUITab.Group>
);

Group.displayName = "Tab.Group";
