import PropTypes from "prop-types";
import classNames from "classnames";

export const Well = ({ children, backgroundNone = false, className = "" }) => (
  <div
    className={classNames(
      "rounded-md bg-none px-4 py-5 sm:p-6",
      {
        "bg-none": backgroundNone,
        "bg-tertiary": !backgroundNone,
      },
      className,
    )}
  >
    {children}
  </div>
);

Well.propTypes = {
  children: PropTypes.any.isRequired,
  backgroundNone: PropTypes.bool,
  className: PropTypes.string,
};
