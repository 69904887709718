import type { GiphyItem } from "@circle-react-shared/uikit/TipTapBlockEditor/GiphyPickerModal";
import { useTipTapEditorContext } from "../index";

export interface GifItemProps {
  gif: GiphyItem;
  close: () => void;
}

export const GifItem = ({ gif, close }: GifItemProps) => {
  const { localAttachments = [], setLocalAttachments } =
    useTipTapEditorContext();

  const onClick = () => {
    const isVideo = gif.type === "video";
    const content_type = isVideo ? "video/mp4" : "image/gif";
    setLocalAttachments([
      ...localAttachments,
      {
        url: gif.images.original.url,
        id: gif.id,
        content_type,
        toUpload: true,
        progress: 0,
      },
    ]);
    close();
  };
  return (
    <button type="submit" onClick={onClick} aria-label={gif.title}>
      <img
        loading="eager"
        alt=""
        width={180}
        src={gif.images.preview_gif.url}
      />
    </button>
  );
};
