import type { IconType } from "@circle-react-shared/Icon";
import { Icon } from "@circle-react-shared/Icon";

interface TextModifierIconProps {
  type: IconType;
  iconSize?: number;
}

export const TextModifierIcon = ({
  type,
  iconSize = 20,
}: TextModifierIconProps) => (
  <span className="flex items-center">
    <Icon type={type} size={iconSize} useWithFillCurrentColor />
  </span>
);
