import { useMemo } from "react";
import { useQuery } from "react-query";
import { WebVTTParser } from "webvtt-parser";
import { mediaTranscriptPaths } from "@circle-react/helpers/urlHelpers";

export const useParsedWebVTTFile = mediaTranscript => {
  const {
    id: mediaTranscriptId,
    enabled: isEnabled,
    is_ready: isReady,
  } = mediaTranscript || {};

  const { data, isError, refetch } = useQuery(
    ["mediaTranscript", "webvtt", mediaTranscriptId],
    () =>
      fetch(mediaTranscriptPaths.showVtt({ id: mediaTranscriptId })).then(res =>
        res.text(),
      ),
    { enabled: !!mediaTranscriptId && isReady && isEnabled },
  );
  const parsedWebvtt = useMemo(() => {
    if (!data || isError) {
      return {};
    }
    const parser = new WebVTTParser();
    return parser.parse(data);
  }, [data, isError]);

  return {
    parsedWebvtt,
    refetch,
  };
};
