import type { PropsWithChildren } from "react";
import classNames from "classnames";
import { useIsV3 } from "@circle-react/hooks/useIsV3";

interface HeaderProps {
  hasNoBorder?: boolean;
  className?: string;
}
export const Header = ({
  hasNoBorder,
  children,
  className,
}: PropsWithChildren<HeaderProps>) => {
  const { isV3Enabled } = useIsV3();
  const minHeightStylesV2 = hasNoBorder ? "min-h-[48px]" : "min-h-[64px]";
  const minHeightStylesV3 = hasNoBorder ? "min-h-[48px]" : "min-h-[72px]";

  const minHeightStyles = isV3Enabled ? minHeightStylesV3 : minHeightStylesV2;

  return (
    <div
      className={classNames(
        "text-darkest flex items-center px-6 font-medium",
        minHeightStyles,
        {
          "border-primary border-b py-4": !hasNoBorder,
        },
        className,
      )}
    >
      {children}
    </div>
  );
};
