import { forwardRef } from "react";
import type { ComponentPropsWithoutRef, LegacyRef } from "react";
import * as RadixSlider from "@radix-ui/react-slider";
import classNames from "classnames";
import { TippyV2 } from "@circle-react-shared/TippyV2";

const Thumb = forwardRef(
  (
    props: ComponentPropsWithoutRef<typeof RadixSlider.Thumb>,
    ref: LegacyRef<HTMLSpanElement> | undefined,
  ) => {
    const { "aria-valuenow": valueNow } = props;

    return (
      <TippyV2 content={valueNow}>
        <span ref={ref} {...props} />
      </TippyV2>
    );
  },
);

Thumb.displayName = "Thumb";

export function SliderThumb({
  className,
  ...rest
}: ComponentPropsWithoutRef<typeof RadixSlider.Thumb>) {
  return (
    <RadixSlider.Thumb
      className={classNames(
        "before:bg-brand bg-primary shadow-c-slider relative block h-4 w-4 rounded-xl before:absolute before:inset-1/2 before:h-2 before:w-2 before:-translate-x-1/2 before:-translate-y-1/2 before:rounded-xl",
        className,
      )}
      asChild
      {...rest}
    >
      <Thumb />
    </RadixSlider.Thumb>
  );
}
