import PropTypes from "prop-types";
import filesize from "filesize";
import { Typography } from "@circle-react-uikit/Typography";
import { FileTypeIcon } from "./FileTypeIcon";

export const AttachmentDetails = ({ attachment }) => (
  <div className="flex w-full items-center space-x-2">
    <div className="mb-1 flex items-center">
      <FileTypeIcon content_type={attachment.content_type} />
    </div>
    <div className="flex flex-col truncate">
      <div className="truncate">
        <Typography.LabelSm weight="semibold">
          {attachment.filename}
        </Typography.LabelSm>
      </div>
      <Typography.LabelXs weight="regular">
        {filesize(attachment.byte_size)}
      </Typography.LabelXs>
    </div>
  </div>
);

AttachmentDetails.propTypes = {
  attachment: PropTypes.shape({
    content_type: PropTypes.string,
    filename: PropTypes.string,
    byte_size: PropTypes.number,
  }),
};
