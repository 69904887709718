import { AlignmentButton } from "./AlignmentButton";

interface AlignmentPickerProps {
  node: any;
  updateAttributes: (attrs: any) => void;
}

export const AlignmentPicker = ({
  node,
  updateAttributes,
}: AlignmentPickerProps) => (
  <div className="invisible absolute right-0 top-0 flex w-full -translate-y-full items-center justify-end pb-1 group-hover:visible">
    <div className="bg-text-editor-modifier space-x-1 overflow-hidden rounded-md p-1 text-sm">
      <AlignmentButton
        onClick={() => {
          updateAttributes({
            alignment: "left",
            width_ratio: 0.5,
          });
        }}
        isActive={node.attrs.alignment == "left"}
        iconType="16-image-left"
      />
      <AlignmentButton
        onClick={() => {
          updateAttributes({
            alignment: "center",
            width_ratio: 1,
          });
        }}
        isActive={node.attrs.alignment == "center"}
        iconType="16-image-centre"
      />
    </div>
  </div>
);
