import { Extension } from "@tiptap/react";
import { defaultEnterBehaviour } from "@circle-react-shared/uikit/TipTap/Extensions/CustomKeymaps/common";

export const CommentKeymap = Extension.create({
  name: "commentKeymap",
  addKeyboardShortcuts() {
    const editor = this.editor;

    return {
      // Both Shift-Enter and Enter will replicate the default enter key behavior
      "Shift-Enter": () => defaultEnterBehaviour({ editor }),
      Enter: () => defaultEnterBehaviour({ editor }),
    };
  },
});
