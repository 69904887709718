import {
  AvatarContainer,
  Row,
} from "@circle-react-uikit/SkeletonLoader/components";

export const Bookmarks = () => (
  <div className="h-10 w-full">
    <AvatarContainer lg full>
      <Row lg extraLong />
      <Row lg extraLong />
      <Row lg extraLong />
      <Row lg extraLong />
    </AvatarContainer>
  </div>
);
