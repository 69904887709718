import type { ComponentPropsWithoutRef } from "react";
import { forwardRef } from "react";
import classNames from "classnames";
import { Typography } from "../Typography";
import { Notice } from "./Notice";

export const WarningNotice = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<typeof Notice>
>(function WarningNotice({ children, className, ...rest }, ref) {
  return (
    <Notice
      className={classNames(
        "bg-v2-warning/5 border-v2-warning border",
        className,
      )}
      ref={ref}
      {...rest}
    >
      <Typography.LabelSm color="text-stroke-foreground" as="span">
        {children}
      </Typography.LabelSm>
    </Notice>
  );
});
