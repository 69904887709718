import type { ComponentPropsWithoutRef } from "react";
import classNames from "classnames";

export const MenuButton = ({
  children,
  className,
  ...props
}: ComponentPropsWithoutRef<"div">) => (
  <div
    className={classNames(
      "group flex w-full cursor-pointer items-center justify-center gap-1.5 py-1",
      className,
    )}
    {...props}
  >
    {children}
  </div>
);
