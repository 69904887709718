import type { MouseEventHandler } from "react";
import { NodeViewWrapper } from "@tiptap/react";
import classNames from "classnames";
import fileSize from "filesize";
import { t } from "i18n-js";
import { truncate } from "lodash";
import { useQuery } from "react-query";
import { Icon } from "@circle-react/components/shared/Icon";
import { FileTypeIcon } from "@circle-react/components/shared/uikit/TipTap/AttachmentPreviews/FileTypeIcon";
import { useBlockEditorContext } from "@circle-react/components/shared/uikit/TipTapBlockEditor";
import { useFilePreviewModal } from "@circle-react/components/shared/uikit/TipTapBlockEditor/FileExtension/FilePreviewModal";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { contentTypeParser } from "@circle-react-shared/uikit/TipTap/utilities/contentTypeParser";
import { AudioPlayer } from "@circle-react-shared/uikit/TipTapBlockEditor/FileExtension/AudioPlayer";
import { VideoRenderer } from "@circle-react-shared/uikit/TipTapBlockEditor/FileExtension/VideoRenderer";
import { LinkPreview } from "./LinkPreview";

export interface RendererProps {
  node: any;
  editor: any;
  deleteNode: () => void;
}

export const Renderer = ({ node, editor, deleteNode }: RendererProps) => {
  const isEditable = editor.isEditable;
  const filePreviewModal = useFilePreviewModal();
  const {
    localInlineAttachments,
    inlineAttachments,
    addToLocalInlineAttachments,
    shouldPreviewFilesAsLinks,
  } = useBlockEditorContext() ?? {};
  const signedId = node.attrs?.signed_id;
  const attachment =
    localInlineAttachments?.find(
      attachment => attachment.signed_id === signedId,
    ) ||
    inlineAttachments?.find(attachment => attachment.signed_id === signedId);

  const { isLoading } = useQuery(
    internalApi.richTextFields.fetchAttachment({ signedId }),
    {
      enabled: !attachment && isEditable,
      retry: false,
      retryOnMount: false,
      onSuccess: addToLocalInlineAttachments,
    },
  );

  const handleDelete: MouseEventHandler<HTMLButtonElement> = event => {
    deleteNode();
    event.preventDefault();
    event.stopPropagation();
  };

  if (isLoading) {
    return (
      <NodeViewWrapper as="div" className="my-4">
        <Icon type="loader" size={16} />
      </NodeViewWrapper>
    );
  }

  if (!attachment) return null;
  if (shouldPreviewFilesAsLinks) {
    return (
      <LinkPreview
        attachment={attachment}
        isEditable={isEditable}
        onDelete={handleDelete}
      />
    );
  }

  const isNativelySupportedVideo = contentTypeParser.isNativelySupportedVideo(
    attachment.content_type,
  );

  const isVideoTranscodingEnabled =
    contentTypeParser.isVideo(attachment.content_type) &&
    Boolean(attachment.media_transcoding);

  // Renders the video player only for videos that are natively supported in
  // most modern browsers or videos that are or will be transcoded.
  const isVideo = isNativelySupportedVideo || isVideoTranscodingEnabled;
  const isAudio = contentTypeParser.isAudio(attachment.content_type);
  const isPDF = contentTypeParser.isPDF(attachment.content_type);
  const isRegularFile = !isVideo && !isAudio && !isPDF;

  const wrapperAnchorProps = isAudio
    ? {}
    : {
        href: attachment.url,
        download: true,
      };

  if (isVideo) {
    return (
      <VideoRenderer
        attachment={attachment}
        isTranscodingEnabled={isVideoTranscodingEnabled}
      />
    );
  }

  return (
    <NodeViewWrapper
      as="div"
      id="file-wrapper"
      className={classNames(
        "bg-secondary hover:bg-tertiary border-primary group relative w-full flex-col rounded border px-3 py-2",
        {
          "max-w-[350px]": isRegularFile,
          "max-w-[500px]": isAudio || isPDF,
        },
      )}
    >
      {isEditable && (
        <button
          type="button"
          className="bg-secondary border-primary text-dark absolute -right-2.5 -top-2.5 flex scale-50 cursor-pointer items-center justify-center rounded-full border opacity-0 transition-all duration-200 group-hover:scale-100 group-hover:opacity-100"
          onClick={handleDelete}
          aria-label={t("close")}
        >
          <Icon type="close" />
        </button>
      )}
      <a
        {...wrapperAnchorProps}
        className="inline-flex items-center space-x-3 truncate"
      >
        <FileTypeIcon content_type={attachment.content_type} />
        <div className="flex flex-col truncate">
          <Typography.LabelSm weight="semibold">
            {truncate(attachment.filename, { length: 35 })}
          </Typography.LabelSm>
          <Typography.LabelXs weight="regular">
            {fileSize(attachment.byte_size)}
          </Typography.LabelXs>
        </div>
      </a>
      {isPDF && (
        <div className="relative mt-2 flex max-h-[500px] items-center justify-center overflow-hidden">
          <button
            type="button"
            onClick={() => {
              void filePreviewModal.show({ file: attachment });
            }}
            className="absolute left-0 top-0 h-full w-full cursor-zoom-in"
            aria-label={t("view_attachment")}
          />
          {attachment.image_variants?.thumbnail && (
            <img
              loading="lazy"
              className="h-48 w-full object-cover object-top"
              src={attachment.image_variants.thumbnail}
              alt={t("tiptap.pdf_preview")}
            />
          )}
        </div>
      )}
      {isAudio && (
        <AudioPlayer src={attachment.url} type={attachment.content_type} />
      )}
    </NodeViewWrapper>
  );
};
