import type { Editor } from "@tiptap/react";

const isCursorSelection = (
  selection: any,
): selection is { $cursor: { pos: number; empty: boolean } } =>
  selection?.$cursor?.pos !== null;

export const showSlashCommandMenu = ({ editor }: { editor: Editor }) => {
  if (isCursorSelection(editor.state.selection)) {
    const cursorPosition = editor.state?.selection?.$cursor?.pos;
    // Skip if it's at the beginning of the document
    if (cursorPosition > 0) {
      const positionBeforeCursor = Math.max(0, cursorPosition - 1);
      const nodeAtPosition =
        cursorPosition && editor.state.doc.nodeAt(positionBeforeCursor);
      if (nodeAtPosition && nodeAtPosition?.isText) {
        const textBefore = nodeAtPosition.textBetween(
          positionBeforeCursor,
          cursorPosition,
          " ",
        );
        const hasPrecedingSpace = textBefore.endsWith(" ");
        if (!hasPrecedingSpace) {
          editor.chain().focus().insertContent(" ").run();
        }
      }
    }
  }
  return editor.chain().focus().insertContent("/").run();
};

export const getCurrentRange = ({ editor }: { editor: Editor }) => {
  const { from, to } = editor.state.selection;
  return { from, to };
};
