import { Counter } from "./Counter";
import { Group } from "./Group";
import { List } from "./List";
import { ListWrapper } from "./ListWrapper";
import { Panel } from "./Panel";
import { Panels } from "./Panels";
import { TabRoot } from "./Tab";

const Tab = Object.assign(TabRoot, {
  List,
  Group,
  Panel,
  Panels,
  Counter,
  ListWrapper,
});

export { Tab };
