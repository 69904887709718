import { Fragment, useRef } from "react";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import { Icon } from "@circle-react-shared/Icon";
import "./styles.scss";

const invisibleCharacter = "&#8203;";

/** @deprecated Please use the new Modal components at "@circle-react-uikit/ModalV2" */
export const FullScreenModal = ({
  show,
  onClose,
  className,
  title,
  passThroughProps,
  hideCloseButton,
  hideTitle,
  children,
}) => {
  const cancelButtonRef = useRef();
  const modalBodyRef = useRef();

  return (
    <Transition.Root
      show={show}
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Dialog
        as="div"
        static
        className={`fullscreen-modal ${className}`}
        initialFocus={hideCloseButton ? modalBodyRef : cancelButtonRef}
        open={show}
        onClose={onClose}
        {...passThroughProps}
      >
        <div className="fullscreen-modal__body-outer">
          <Dialog.Overlay className="fixed inset-0 bg-gray-900/50" />
          <span
            className="hidden"
            aria-hidden="true"
            style={{ content: invisibleCharacter }}
          />
          <div className="fullscreen-modal__body-wrap">
            <div className="fullscreen-modal__body" ref={modalBodyRef}>
              {!hideCloseButton && (
                <button
                  type="submit"
                  className="fullscreen-modal__close-icon"
                  onClick={onClose}
                  ref={cancelButtonRef}
                >
                  <Icon type="close" />
                </button>
              )}

              {!hideTitle && (
                <h2 className="fullscreen-modal__title">{title}</h2>
              )}
              {children}
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

FullScreenModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  passThroughProps: PropTypes.object,
  hideCloseButton: PropTypes.bool,
  hideTitle: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
