export interface ImagePreviewProps {
  src?: string;
  alt?: string;
  value?: string;
}

export const ImagePreview = (props: ImagePreviewProps = {}) => {
  const { src, alt, value } = props;
  if (!value || !src) return <>{}</>;
  return (
    <img loading="lazy" className="quickpost__thumbnail" src={src} alt={alt} />
  );
};

export type ImagePreviewFunc = typeof ImagePreview;
