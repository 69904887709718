export const getMarksByType = ({ editor, type }) => {
  try {
    const { content } = editor.getJSON();
    let marks = [];
    const paragraphs =
      content?.filter(blocks => blocks.type == "paragraph") || [];
    paragraphs.forEach(paragraph => {
      paragraph?.content?.forEach(block => {
        if (block.type !== "hardBreak" && block.marks) {
          block.marks.forEach(mark => {
            if (mark.type == type) {
              marks = [...marks, mark];
            }
          });
        }
      });
    });
    return marks;
  } catch (err) {
    console.error(`Error while fetching marks by type ${type}`, err);
    return [];
  }
};

const updateLinkMarkTarget = ({ marks }) => {
  let isMarkUpdated = false;
  const updatedMarks = marks.map(mark => {
    if (
      mark.type == "link" &&
      !(mark.attrs.target === "_blank" || mark.attrs.target === "_self")
    ) {
      isMarkUpdated = true;
      return {
        ...mark,
        attrs: {
          ...mark.attrs,
          target: "_blank",
        },
      };
    }
    return mark;
  });
  return { marks: updatedMarks, isMarkUpdated };
};

export const sanitizeLinkMarks = ({ editor }) => {
  try {
    let shouldUpdateEditorContent = false;
    const { content } = editor.getJSON();
    const newContent = content.map(item => {
      if (item.type == "paragraph") {
        const contents = item.content?.map(block => {
          if (block.type === "text" && block.marks) {
            const { marks, isMarkUpdated } = updateLinkMarkTarget({
              marks: block.marks,
            });
            shouldUpdateEditorContent =
              shouldUpdateEditorContent || isMarkUpdated;
            return {
              ...block,
              marks,
            };
          }
          return block;
        });
        return {
          ...item,
          content: contents,
        };
      }
      return item;
    });

    if (shouldUpdateEditorContent) editor.commands.setContent(newContent);
  } catch (err) {
    console.error(`Error while updating link marks`, err);
  }
};
