import { Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import { t } from "i18n-js";
import { random } from "lodash";
import { AiCopilotBlock } from "./AiCopilotBlock";

export const AiCopilotExtension = Node.create({
  name: "aicopilot",
  group: "block",
  selectable: false,
  atom: true,
  renderHTML({ HTMLAttributes }) {
    return ["aicopilot", HTMLAttributes, 0];
  },
  addAttributes: () => ({
    placeholder: {
      default: null,
    },
    id: {
      default: random(),
    },
    range: {
      default: null,
    },
    type: {
      default: "completion",
    },
  }),
  parseHTML() {
    return [{ tag: "aicopilot" }];
  },
  addNodeView() {
    return ReactNodeViewRenderer(AiCopilotBlock);
  },
  addCommands() {
    return {
      addAiCopilotNode:
        () =>
        ({ commands }) =>
          commands.insertContent({
            type: "aicopilot",
            attrs: {
              placeholder: t("tiptap.extensions.ai_copilot.input.placeholder"),
            },
          }),
    };
  },
});
