import type { ReactNode } from "react";
import { createContext, useContext, useState } from "react";
import { arrayMove } from "@dnd-kit/sortable";
import { isFunction, noop } from "lodash";

export interface SortableListDataContext {
  activeItem: any;
  distance: number;
  items: any[];
  lockedHorizontalAxis: boolean;
  lockedVerticalAxis: boolean;
  onDragEnd?: (event: any) => void;
  onDragOver?: (event: any) => void;
  onDragStart?: (event: any) => void;
  onSort: (items: any[]) => void;
  setActiveItem: (item: any) => void;
  useAnimation: boolean;
  useDragHandle: boolean;
}

const SortableListData = createContext<SortableListDataContext>({
  activeItem: {},
  distance: 0,
  items: [],
  lockedHorizontalAxis: false,
  lockedVerticalAxis: false,
  onSort: noop,
  setActiveItem: noop,
  useAnimation: false,
  useDragHandle: false,
});
SortableListData.displayName = "SortableListData";

export const useSortableListData = () => useContext(SortableListData);

export interface SortableListDataProviderProps {
  children: ReactNode;
  distance?: number;
  items: any[];
  lockedHorizontalAxis?: boolean;
  lockedVerticalAxis?: boolean;
  onDragEnd?: (event: any) => void;
  onDragOver?: (event: any) => void;
  onDragStart?: (event: any) => void;
  onSort: (items: any[]) => void;
  useAnimation?: boolean;
  useDragHandle?: boolean;
}

export const SortableListDataProvider = ({
  children,
  distance = 0,
  items,
  lockedHorizontalAxis = false,
  lockedVerticalAxis = false,
  onDragEnd,
  onDragOver,
  onDragStart,
  onSort,
  useAnimation = true,
  useDragHandle = false,
}: SortableListDataProviderProps) => {
  const [activeItem, setActiveItem] = useState<any>(null);

  const handleDragStart = (event: any) => {
    const item = items.find(({ id }: any) => id === event.active.id);
    setActiveItem(item);
  };

  const defaultDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = items.findIndex((item: any) => item.id === active.id);
      const newIndex = items.findIndex((item: any) => item.id === over.id);
      const newItems = arrayMove(items, oldIndex, newIndex);
      onSort(newItems);
    }
  };

  const handleDragEnd = (event: any) => {
    setActiveItem(null);
    return isFunction(onDragEnd) ? onDragEnd(event) : defaultDragEnd(event);
  };

  return (
    <SortableListData.Provider
      value={{
        activeItem,
        distance,
        items,
        lockedHorizontalAxis,
        lockedVerticalAxis,
        onDragEnd: handleDragEnd,
        onDragOver,
        onDragStart: isFunction(onDragStart) ? onDragStart : handleDragStart,
        onSort,
        setActiveItem,
        useAnimation,
        useDragHandle,
      }}
    >
      {children}
    </SortableListData.Provider>
  );
};
