import { Extension } from "@tiptap/react";
import { defaultEnterBehaviour } from "@circle-react-shared/uikit/TipTap/Extensions/CustomKeymaps/common";

export const ChatKeymap = Extension.create({
  name: "chatKeymap",
  addKeyboardShortcuts() {
    const editor = this.editor;

    return {
      // Remap the default enter key behavior to shift-enter for creating paragraphs
      "Shift-Enter": () => defaultEnterBehaviour({ editor }),
      // Disable enter key
      Enter: () => true,
    };
  },
});
