import { Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import { random } from "lodash";
import { EditedLabelBlock } from "./EditedLabelBlock";

export const EditedLabelExtension = Node.create({
  name: "editedlabel",
  group: "inline",
  inline: true,
  selectable: false,
  atom: true,

  addAttributes: () => ({
    id: {
      default: random(),
    },
  }),

  addNodeView() {
    return ReactNodeViewRenderer(EditedLabelBlock);
  },
  addCommands() {
    return {
      addEditedLabelNode:
        () =>
        ({ commands, editor }) => {
          if (editor.isEmpty) {
            return commands.insertContent({
              type: "paragraph",
              content: [{ type: "text", text: "" }, { type: "editedlabel" }],
            });
          }
          return commands.insertContent({
            type: "editedlabel",
          });
        },
    };
  },
});

EditedLabelExtension.displayName = "EditedLabelExtension";
