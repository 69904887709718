import { createContext, useContext, useEffect } from "react";
import type { ReactNode } from "react";
import isFunction from "lodash/isFunction";

export interface DropdownContextProps {
  isOpen: boolean;
  toggle: () => void;
  onToggle?: (open: boolean) => void;
}

const DropdownContext = createContext<DropdownContextProps | null>(null);
DropdownContext.displayName = "DropdownContext";

export const useDropdownContext = (): DropdownContextProps => {
  const context = useContext(DropdownContext);

  if (!context) {
    throw new Error("useDropdownContext must be used within a Dropdown");
  }

  return context;
};

export interface DropdownProviderProps extends DropdownContextProps {
  children: ReactNode;
}

export const DropdownProvider = ({
  isOpen,
  toggle,
  onToggle,
  children,
}: DropdownProviderProps) => {
  useEffect(() => {
    isFunction(onToggle) && onToggle(isOpen);
  }, [isOpen, onToggle]);

  return (
    <DropdownContext.Provider value={{ isOpen, toggle, onToggle }}>
      {children}
    </DropdownContext.Provider>
  );
};
