import classNames from "classnames";

export const Container = ({ children, className }) => (
  <div
    className={classNames(
      "bg-primary flex items-start gap-3 rounded-md px-4 py-2.5 shadow-md",
      className,
    )}
  >
    {children}
  </div>
);
