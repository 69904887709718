import { useSortable } from "@dnd-kit/sortable";
import { useSortableListData } from "../Provider/SortableListDataProvider";
import type { ListItemProps } from "./ListItem";
import { ListItemMemo } from "./ListItemMemo";

export interface ItemProps extends ListItemProps {
  disabled?: boolean;
  dataTestId?: string;
  item: any;
  index?: number;
}

export const Item = ({
  item,
  disabled = false,
  dataTestId = "",
  ...props
}: ItemProps) => {
  const { id } = item;
  const { useAnimation: shouldUseAnimation } = useSortableListData();

  const {
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
    setNodeRef,
  } = useSortable({
    id,
    ...(!shouldUseAnimation && {
      transition: { duration: 0, easing: "linear" },
    }),
    disabled,
  });

  return (
    <ListItemMemo
      ref={setNodeRef}
      item={item}
      attributes={attributes}
      listeners={listeners}
      transform={transform}
      transition={transition}
      isDragging={isDragging}
      dataTestId={dataTestId}
      {...props}
    />
  );
};
