import { useEffect, useState } from "react";
import { isFunction } from "lodash";

export const useKeyboardNavigation = ({ optionsCount = 0, onEnter }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const handleKeyDown = event => {
      if (["ArrowDown", "ArrowUp", "Enter"].includes(event.key)) {
        if (event.key === "Enter" && event.shiftKey) {
          return;
        }

        event.preventDefault();
        switch (event.key) {
          case "ArrowDown":
            setActiveIndex((activeIndex + 1) % optionsCount);
            break;
          case "ArrowUp":
            setActiveIndex((activeIndex - 1 + optionsCount) % optionsCount);
            break;
          case "Enter":
            isFunction(onEnter) && onEnter(activeIndex);
            break;
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [activeIndex, onEnter, optionsCount]);

  return { activeIndex, setActiveIndex };
};
