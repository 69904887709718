import {
  Avatar,
  AvatarContainer,
} from "@circle-react-uikit/SkeletonLoader/components";

export const MembersAvatarRowV3 = () => (
  <AvatarContainer>
    <div className="!border-tertiary z-[2] !rounded-full !border-r-2">
      <Avatar sm isFullRounded />
    </div>
    <div className="!border-tertiary z-[1] !ml-[-5px] !rounded-full !border-r-2">
      <Avatar sm isFullRounded />
    </div>
    <div className="!ml-[-5px]">
      <Avatar sm isFullRounded />
    </div>
  </AvatarContainer>
);
