import type { Editor } from "@tiptap/core";

export const defaultEnterBehaviour = ({ editor }: { editor: Editor }) =>
  editor.commands.first(({ commands }) => [
    () => commands.splitListItem("listItem"),
    () => commands.newlineInCode(),
    () => commands.createParagraphNear(),
    () => commands.liftEmptyBlock(),
    () => commands.splitBlock(),
  ]);
