import classNames from "classnames";
import { AttachmentPreviews } from "./AttachmentPreviews";
import { LinkPreviews } from "./LinkPreviews";
import { useTipTapEditorContext } from "./index";
import { getMarksByType } from "./utilities/getMarks";

export const PreviewBlocks = () => {
  const {
    editor,
    attachments = [],
    localAttachments = [],
    messageAttachments,
  } = useTipTapEditorContext();

  const attachmentsList = editor.isEditable ? localAttachments : attachments;
  const linkMarks = getMarksByType({ editor, type: "link" });

  const hasLinks = linkMarks.length > 0;
  const hasAttachments = attachmentsList?.length > 0;
  const showDivider = hasLinks && hasAttachments;
  const hasMessageAttachments = messageAttachments?.length > 0;

  if (!hasAttachments && !hasLinks && !hasMessageAttachments) {
    return null;
  }

  return (
    <div
      className={classNames(
        "flex items-center gap-x-2 overflow-x-auto overflow-y-hidden pb-2",
        editor.isEditable ? "pt-4" : "pt-2",
      )}
    >
      <AttachmentPreviews
        attachmentsList={attachmentsList}
        linkMarks={linkMarks}
      />
      {showDivider && (
        <div className="border-primary mx-0.5 h-16 w-px rounded-lg border-l-2" />
      )}
      <LinkPreviews
        linkMarks={linkMarks}
        messageAttachments={messageAttachments}
      />
    </div>
  );
};
