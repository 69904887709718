import PropTypes from "prop-types";
import classnames from "classnames";
import { useBEMClasses } from "@circle-react/hooks/utils/useBEMClasses";

export const AvatarContainer = ({ children, ...props }) => {
  const blockElement = "skeleton-loader__avatar-container";
  const { modifiers } = useBEMClasses(blockElement, props);

  return <div className={classnames(blockElement, modifiers)}>{children}</div>;
};

AvatarContainer.propTypes = {
  md: PropTypes.bool,
  lg: PropTypes.bool,
  full: PropTypes.bool,
  children: PropTypes.node,
};
