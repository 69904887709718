import {
  Avatar,
  AvatarContainer,
} from "@circle-react-uikit/SkeletonLoader/components";

export const MembersAvatarRow = () => (
  <AvatarContainer>
    <div className="!border-tertiary z-[2] !rounded-md !border-r-2">
      <Avatar sm />
    </div>
    <div className="!border-tertiary z-[1] !ml-[-5px] !rounded-md !border-r-2">
      <Avatar sm />
    </div>
    <div className="!ml-[-5px]">
      <Avatar sm />
    </div>
  </AvatarContainer>
);
