import classnames from "classnames";
import { truncate } from "lodash";
import { useThemeContext } from "@/react/providers";
import { EmojiRenderer } from "@circle-react-shared/EmojiRenderer";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { Typography } from "@circle-react-uikit/Typography";

export const EntityItem = ({ entity, handleClick, isSelected }: any) => {
  const {
    name,
    emoji,
    custom_emoji_url: customEmojiUrl,
    custom_emoji_dark_url: customEmojiDarkUrl,
  } = entity || {};
  const { currentAppearance } = useThemeContext();
  return (
    <TippyV2 interactive={false} content={name} disabled={name?.length < 40}>
      <button
        type="button"
        className={classnames(
          "hover:bg-tertiary flex w-full cursor-pointer items-center truncate px-6 py-2.5 text-left",
          { "bg-tertiary": isSelected },
        )}
        onClick={handleClick}
      >
        {entity.type === "space" && (
          <EmojiRenderer
            className="!text-dark mr-2 flex !h-5 !w-auto items-center"
            emoji={emoji}
            customEmojiUrl={customEmojiUrl}
            customEmojiDarkUrl={customEmojiDarkUrl}
            appearance={currentAppearance}
          />
        )}
        <Typography.LabelSm weight="medium">
          {truncate(name, {
            length: 40,
          })}
        </Typography.LabelSm>
      </button>
    </TippyV2>
  );
};
