import PropTypes from "prop-types";
import { times, uniqueId } from "lodash";
import {
  Row,
  RowContainer,
} from "@circle-react-uikit/SkeletonLoader/components";

export const CommunitySidebar = ({ count = 3 }) => (
  <div className="skeleton-loader skeleton-loader--community-sidebar">
    <div className="skeleton-loader--community-sidebar__header">
      <Row xl long />
    </div>
    {times(count, () => (
      <div
        key={uniqueId()}
        className="skeleton-loader--community-sidebar__item"
      >
        <RowContainer xl>
          <Row half lg />
          <Row almostFull />
          <Row almostFull />
          <Row almostFull />
        </RowContainer>
      </div>
    ))}
  </div>
);

CommunitySidebar.propTypes = {
  count: PropTypes.number,
};
