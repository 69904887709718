import { Controller, useFormContext } from "react-hook-form";
import { ImageSelector } from "./ImageSelector";
import type { ImageSelectorProps } from "./ImageSelector";

export interface ImageInputProps
  extends Omit<ImageSelectorProps, "onChange" | "value"> {}

export const ImageInput = ({ name, ...props }: ImageInputProps) => {
  const { control } = useFormContext();
  return (
    <Controller<Record<string, string>>
      control={control}
      name={name}
      render={({ field: { onChange, value, name } }) => (
        <ImageSelector
          {...props}
          name={name}
          onChange={onChange}
          value={value}
        />
      )}
    />
  );
};
