import {
  Avatar,
  AvatarContainer,
  Row,
  RowContainer,
} from "@circle-react-uikit/SkeletonLoader/components";

export const ChatThread = () => (
  <div className="bg-primary flex flex-col justify-between py-5">
    <RowContainer lg full>
      <div className="px-5 py-2">
        <AvatarContainer md full>
          <Avatar />
          <RowContainer full>
            <Row sm />
            <Row sm almostFull />
          </RowContainer>
        </AvatarContainer>
      </div>
      <div className="px-5 py-2">
        <AvatarContainer md full>
          <Avatar />
          <RowContainer full>
            <Row sm />
            <Row sm full />
            <Row sm almostFull />
          </RowContainer>
        </AvatarContainer>
      </div>
      <div className="px-5 py-2">
        <AvatarContainer md full>
          <Avatar />
          <RowContainer full>
            <Row sm />
            <Row sm almostFull />
          </RowContainer>
        </AvatarContainer>
      </div>
    </RowContainer>
    <div className="bg-primary z-50 w-full p-5">
      <div className="border-primary h-24 w-full rounded-lg border">
        <div className="flex h-full items-end space-x-3 p-4">
          {Array(3)
            .fill()
            .map((_, index) => (
              <div
                key={index}
                className="bg-secondary h-6 w-6 rounded-lg"
              ></div>
            ))}
        </div>
      </div>
    </div>
  </div>
);
