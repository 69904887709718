import { useState } from "react";
import { useBoolean } from "@circle-react/hooks/utils/useBoolean";

export const useEditorLocalState = ({ initialValue = {} }: any): any => {
  const [isFocused, , setIsFocused] = useBoolean(false);
  const [localSgidToObjectMap, setLocalSgidToObjectMap] =
    useState(initialValue);
  const addToLocalSgidToObjectMap = ({ sgid, object }: any) => {
    setLocalSgidToObjectMap((prev: any) => ({
      ...prev,
      [sgid]: object,
    }));
  };

  const [messageAttachments, setMessageAttachments] = useState<any>([]);

  const addMessageAttachments = (attachment: any) => {
    setMessageAttachments((prevAttachments: any) => {
      const ifAttachmentExists = prevAttachments.some(
        (item: any) => item.id === attachment.id,
      );
      if (ifAttachmentExists) return prevAttachments;
      return [...prevAttachments, attachment];
    });
  };

  const removeMessageAttachments = (attachment: any) => {
    setMessageAttachments((prevAttachments: any) => {
      if (!attachment) return prevAttachments;
      return prevAttachments.filter((item: any) => item.id !== attachment.id);
    });
  };

  return {
    isFocused,
    setIsFocused,
    localSgidToObjectMap,
    setLocalSgidToObjectMap,
    addToLocalSgidToObjectMap,
    messageAttachments,
    setMessageAttachments,
    addMessageAttachments,
    removeMessageAttachments,
  };
};
