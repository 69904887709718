import { isNodeActive } from "@tiptap/core";
import type { ListItemOptions } from "@tiptap/extension-list-item";
import ListItem from "@tiptap/extension-list-item";
import { joinListItemBackward } from "./commands";
import {
  findListItemPos,
  hasListItemBefore,
  isAtStartOfNode,
  listItemHasSubList,
} from "./helpers";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    listItem: {
      /**
       * Lift the list item into a wrapping list.
       */
      joinListItemBackward: (typeOrName: any) => ReturnType;
    };
  }
}

// TODO: Update (or potentially remove) after upgrading Tiptap to 2.1.0.
//
// This code was taken from the latest version of Tiptap (2.1.0.rc9) with a few
// modifications that were necessary to support our custom behavior for
// removing list items at the end of the list using Backspace.
//
// Related PR: https://github.com/ueberdosis/tiptap/pull/3819
// Source: https://github.com/ueberdosis/tiptap/blob/97019c6c9989eb711b458ab06a9e2d42c75adb5f/packages/extension-list-item/src/list-item.ts#L98-L136

interface CustomizedListItemOptions extends ListItemOptions {
  type?: "chat" | "comment" | "workflow";
}

const isCustomizedListItemOptions = (
  options: any,
): options is CustomizedListItemOptions =>
  options && typeof options.type === "string";

export const CustomizedListItem = ListItem.extend<CustomizedListItemOptions>({
  addCommands(): any {
    return { joinListItemBackward };
  },
  // eslint-disable-next-line sonarjs/cognitive-complexity -- This whole logic will be removed once we upgrade to 2.1.0
  addKeyboardShortcuts() {
    const handlers = this.parent?.();
    if (
      isCustomizedListItemOptions(this.options) &&
      this.options.type === "chat"
    ) {
      // Delete the Enter handler for chat because it creates a new list item
      // when we press Enter to submit a chat message.
      delete handlers?.["Enter"];
    }
    return {
      ...handlers,
      Backspace: () => {
        if (this.editor.commands.undoInputRule()) {
          return true;
        }

        const nodeAtCursor = this.editor.state.selection.$from.node();
        const isNodeEmpty = nodeAtCursor?.content?.size === 0;

        if (isNodeActive(this.editor.state, this.name) && isNodeEmpty) {
          this.editor.commands.lift(this.name);

          return true;
        }

        if (!isAtStartOfNode(this.editor.state)) {
          return false;
        }

        const listItemPos = findListItemPos(this.name, this.editor.state);

        if (!listItemPos) {
          const previousNode = this.editor.state.doc.resolve(
            this.editor.state.selection.$from.pos - 2,
          ).nodeBefore;

          if (previousNode?.type.name === this.name && isNodeEmpty) {
            // We're intentionally throwing an error here. This causes the
            // cursor to jump to the previous list item instead of adding a
            // new one.
            throw new Error("ListItem error (intentional)");
          } else {
            return false;
          }
        }

        const $prev = this.editor.state.doc.resolve(listItemPos.$pos.pos - 2);
        const prevNode = $prev.node(listItemPos.depth);

        const isPreviousListItemWithSubList = listItemHasSubList(
          this.name,
          this.editor.state,
          prevNode,
        );

        if (
          hasListItemBefore(this.name, this.editor.state) &&
          !isPreviousListItemWithSubList &&
          !isNodeEmpty
        ) {
          return this.editor.commands.joinListItemBackward(this.name);
        }

        return this.editor.chain().liftListItem(this.name).run();
      },
    };
  },
});
