import { Fragment, useRef } from "react";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import { Icon } from "@circle-react-shared/Icon";

const invisibleCharacter = "&#8203;";

/** @deprecated Please use the new Modal components at "@circle-react-uikit/ModalV2" */
export const LargeModal = ({
  show,
  title,
  onClose,
  hideCloseButton,
  className,
  passThroughProps,
  children,
}) => {
  const cancelButtonRef = useRef();

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        static
        className={`fullpage-modal fixed inset-0 z-10 overflow-y-auto ${className}`}
        initialFocus={cancelButtonRef}
        open={show}
        onClose={onClose}
        {...passThroughProps}
      >
        <div className="fullpage-modal__body-outer h-full w-full">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-900/50" />
          </Transition.Child>
          <span
            className="hidden"
            aria-hidden="true"
            style={{ content: invisibleCharacter }}
          />
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="z-1 fullpage-modal__body-wrap relative m-0 h-full w-full">
              <div
                className="bg-primary fullpage-modal__body h-full rounded-none md:rounded-lg"
                data-testid="modal"
              >
                <h2 className="fullpage-modal__title sticky">{title}</h2>
                {!hideCloseButton && (
                  <button
                    type="submit"
                    className="fullpage-modal__close-icon z-10"
                    onClick={onClose}
                    ref={cancelButtonRef}
                  >
                    <Icon type="close" useWithFillCurrentColor />
                  </button>
                )}
                {children}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

LargeModal.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  hideCloseButton: PropTypes.bool,
  className: PropTypes.string,
  passThroughProps: PropTypes.object,
  children: PropTypes.node,
};
