import { internalApi } from "@circle-react/helpers/urlHelpers";

const headers = {
  accept: "application/json",
  "content-type": "application/json",
};

export const signedUploadUrlRequest = async (file, setUploadError) => {
  try {
    const response = await fetch(internalApi.voiceMessages.signedUploadUrl(), {
      method: "POST",
      headers,
      body: JSON.stringify({
        filename: file.name,
        contentType: file.type,
      }),
    });

    const data = await response.json();
    return {
      method: data.method,
      url: data.url,
      fields: data.fields,
      headers: {
        "Content-Type": file.type,
      },
    };
  } catch (error) {
    setUploadError();
    console.error("Error uploading audio file:", error);
    return null;
  }
};

export const processFileRequest = async (
  objectId,
  recordingId,
  setServerError,
) => {
  try {
    await fetch(internalApi.voiceMessages.processFileUrl(), {
      method: "POST",
      headers,
      body: JSON.stringify({
        object_id: objectId,
        recording_id: recordingId,
      }),
    });
  } catch (error) {
    setServerError();
    console.error("Error processing audio file:", error);
  }
};
