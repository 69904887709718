import classnames from "classnames";

export interface GridItem {
  id: number;
}
interface GridViewProps {
  count: number;
  columnsCount: number;
  withContainer: boolean;
}

export const GridView = ({ count = 1, columnsCount = 3 }: GridViewProps) => {
  const gridItems: GridItem[] = Array.from(
    { length: count },
    (_, i): GridItem => ({ id: i + 1 }),
  );
  const columns: GridItem[][] = Array.from({ length: columnsCount }, () => []);
  gridItems.forEach((gridItem, index) =>
    columns[index % columnsCount].push(gridItem),
  );

  return (
    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">
      {columns.map(columnGridItems => (
        <div
          key={columnGridItems.map(gridItem => gridItem.id).join()}
          className="flex flex-col gap-6"
        >
          {columnGridItems.map(gridItem => (
            <div
              key={gridItem.id}
              className={classnames("bg-secondary aspect-square", {
                "mt-4 rounded-md": columnsCount < 3,
              })}
            />
          ))}
        </div>
      ))}
    </div>
  );
};
