import { RECORDER_STATUS } from "../constants";

export const generateRandomString = () =>
  Math.random().toString(36).slice(2, 9);

export const getStatusProperties = status => {
  const isRecording = status === RECORDER_STATUS.RECORDING;
  const isBusy =
    status === RECORDER_STATUS.UPLOADING ||
    status === RECORDER_STATUS.PROCESSING;
  const isError =
    status === RECORDER_STATUS.UPLOAD_ERROR ||
    status === RECORDER_STATUS.SERVER_ERROR;

  return { isRecording, isBusy, isError };
};

export const isPermissionGranted = async setStatus => {
  try {
    const permission = await navigator.permissions.query({
      name: "microphone",
    });

    if (permission.state !== "granted") {
      throw new Error("Microphone permission not granted");
    }
  } catch (error) {
    setStatus(RECORDER_STATUS.WAITING_FOR_MIC_PERMISSION);
  }
};
