import type { JSONContent } from "@tiptap/core";

export const buildTiptapDocument = ({
  bodyContent,
  inlineAttachments,
}: any) => ({
  body: {
    type: "doc",
    content: bodyContent,
  },
  inline_attachments: inlineAttachments,
});

export const textBlock = ({ text }: any) => ({
  type: "text",
  text,
});

export const paragraphBlock = ({
  content = [],
}: { content?: JSONContent[] } = {}): JSONContent => ({
  type: "paragraph",
  content,
});

export const fileBlock = ({ file }: any) => ({
  type: "file",
  attrs: {
    signed_id: file.signed_id,
  },
});

export const textParagraphBlock = ({ text }: any) =>
  paragraphBlock({ content: [textBlock({ text })] });

export const mentionBlock = ({ sgid }: any) => ({
  type: "mention",
  attrs: {
    sgid,
  },
});
