import { t } from "i18n-js";
import * as yup from "yup";

export const MAX_POLL_OPTIONS_COUNT = 5;

const uniqueOptionValues = (options: any) => {
  const uniqueValues = new Set(
    options.map((option: any) => option.value.trim()),
  );
  return uniqueValues.size === options.length;
};

export const getPollValidationSchema = () =>
  yup.object().shape({
    title: yup
      .string()
      .required(t("tiptap.extensions.poll.validation_errors.title_required")),
    poll_options: yup
      .array()
      .of(
        yup.object().shape({
          value: yup
            .string()
            .required(
              t(
                "tiptap.extensions.poll.validation_errors.option_value_required",
              ),
            ),
        }),
      )
      .min(2, t("tiptap.extensions.poll.validation_errors.options_minimum"))
      .max(
        MAX_POLL_OPTIONS_COUNT,
        t("tiptap.extensions.poll.validation_errors.options_maximum", {
          maximum: MAX_POLL_OPTIONS_COUNT,
        }),
      )
      .test(
        "unique-option-values",
        t("tiptap.extensions.poll.validation_errors.options_unique"),
        uniqueOptionValues,
      )
      .required(t("tiptap.extensions.poll.validation_errors.options_minimum")),
  });

export const NEW_POLL_OPTION = {
  value: "",
};
