import type { RefObject } from "react";
import { t } from "i18n-js";
import { useHotkeys } from "react-hotkeys-hook";
import { useQueryClient } from "react-query";
import { useModal } from "@circle-react/components/Layout/ModalManagerProvider";
import { LoaderRow } from "@circle-react-shared/LoaderRow";
import { Form } from "@circle-react-shared/uikit/Form";
import { FormLabel } from "@circle-react-shared/uikit/Form/FormLabel";
import { Modal } from "@circle-react-shared/uikit/ModalV2";
import { usePoll } from "@circle-react-shared/uikit/TipTapBlockEditor/PollExtension/PollDisplay/api";
import { Options } from "@circle-react-shared/uikit/TipTapBlockEditor/PollExtension/PollModal/Options";
import { PollDuration } from "@circle-react-shared/uikit/TipTapBlockEditor/PollExtension/PollModal/PollDuration";
import type { PollResponse } from "@circle-react-shared/uikit/TipTapBlockEditor/PollExtension/PollModal/api";
import {
  useCreatePoll,
  useEditPoll,
} from "@circle-react-shared/uikit/TipTapBlockEditor/PollExtension/PollModal/api";
import { useToast } from "@circle-react-shared/uikit/ToastV2";
import { Footer } from "./Footer";
import { NEW_POLL_OPTION, getPollValidationSchema } from "./constants";

export const PollForm = ({
  initialFocusRef,
  onComplete,
  isEdit,
  pollSgid,
  closeModal,
}: {
  initialFocusRef: RefObject<HTMLInputElement>;
  onComplete: (poll: PollResponse) => void;
  isEdit?: boolean;
  pollSgid?: string;
  closeModal: () => void;
}) => {
  const modalControls = useModal();
  const { success } = useToast();

  const { data: pollForEdit, isLoading: isLoadingPollForEdit } = usePoll({
    sgid: pollSgid!,
    options: {
      enabled: !!pollSgid && isEdit,
    },
  });

  const queryClient = useQueryClient();

  const { mutate: handleCreate, isLoading: isCreating } = useCreatePoll({
    onSuccess: poll => {
      onComplete(poll);
      modalControls.remove();
    },
  });

  const { mutate: handleEdit, isLoading: isEditing } = useEditPoll({
    onSuccess: poll => {
      success(t("tiptap.extensions.poll.edit_success"));
      queryClient.setQueryData(["poll", poll.sgid], poll);
      onComplete(poll);
      modalControls.remove();
    },
  });

  useHotkeys("mod+enter", event => {
    event.preventDefault();
    event.stopPropagation();
  });

  const handleSubmit = (values: any) => {
    if (isEdit && pollForEdit) {
      handleEdit({ ...values, id: pollForEdit.id });
    } else {
      handleCreate(values);
    }
  };

  if (isLoadingPollForEdit) {
    return <LoaderRow />;
  }

  const canEditOptions = !isEdit || !pollForEdit?.results?.total_responses;

  const defaultValues: any = isEdit
    ? {
        title: pollForEdit?.title,
        poll_options: pollForEdit?.poll_options,
        allow_members_to_view_results:
          pollForEdit?.allow_members_to_view_results,
        enable_poll_duration: !!pollForEdit?.closes_at,
        closes_at: pollForEdit?.closes_at,
      }
    : {
        title: "",
        poll_options: [NEW_POLL_OPTION],
        allow_members_to_view_results: true,
        enable_poll_duration: false,
        closes_at: null,
      };

  return (
    <Form
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      validationSchema={getPollValidationSchema()}
      className="h-full"
      formClassName="h-full flex flex-col mb-0"
    >
      <Modal.Header>
        <Modal.Title size="sm">
          {t(
            isEdit
              ? "tiptap.extensions.poll.edit"
              : "tiptap.extensions.poll.title",
          )}
        </Modal.Title>
        <Modal.HeaderActions className="absolute right-0 top-0 mr-6 mt-4">
          <Modal.CloseButton onClick={closeModal} />
        </Modal.HeaderActions>
      </Modal.Header>
      <div className="flex-1 overflow-auto px-5 py-4 md:px-0">
        <div className="md:px-8">
          <Form.Item
            name="title"
            label={t("tiptap.extensions.poll.ask_a_question")}
            placeholder={t("tiptap.extensions.poll.question_placeholder")}
            hideDescription
            hideBorder
            isFirstChild
          >
            <Form.Input ref={initialFocusRef} />
          </Form.Item>
        </div>
        <Form.Item
          label={t("tiptap.extensions.poll.options")}
          hideBorder
          hideDescription
          tooltipText={
            !canEditOptions
              ? t("tiptap.extensions.poll.cannot_edit_options")
              : ""
          }
          labelWrapperClassName="md:px-8"
        >
          <Options disabled={!canEditOptions} />
        </Form.Item>
        <div className="md:px-8">
          <div className="border-primary mt-2 border-b" />
          <FormLabel
            fieldId=""
            hideBorder
            label={t("tiptap.extensions.poll.settings.title")}
          />
          <Form.Checkbox
            id="allow_members_to_view_results"
            name="allow_members_to_view_results"
            label={t(
              "tiptap.extensions.poll.settings.allow_members_to_view_results",
            )}
            description=""
            className="!mt-0"
          />
          <Form.Checkbox
            id="enable_poll_duration"
            name="enable_poll_duration"
            label={t("tiptap.extensions.poll.settings.poll_duration")}
            rules={{}}
            description=""
            className="!mt-2"
          />
          <PollDuration />
        </div>
      </div>
      <Footer isEdit isLoading={isEditing || isCreating} />
    </Form>
  );
};
