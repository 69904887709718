import { useEffect, useRef } from "react";
import classNames from "classnames";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-shared/uikit/Typography";

export const CommandItem = ({
  activeMenuIndex,
  setActiveMenuIndex,
  flattenedCommands,
  item,
  editor,
  range,
}) => {
  const activeCommandName = flattenedCommands[activeMenuIndex]?.name;
  const isActive = activeCommandName === item.name;
  const ref = useRef();

  useEffect(() => {
    if (isActive) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [isActive]);

  const onMouseOverOrFocus = () => {
    setActiveMenuIndex(
      flattenedCommands.findIndex(command => command.name === item.name),
    );
  };

  return (
    <button
      type="button"
      ref={ref}
      key={item.name}
      onClick={() => {
        if (document.exitPointerLock) {
          document.exitPointerLock();
        }
        item.command({ editor, range });
      }}
      onMouseOver={onMouseOverOrFocus}
      onFocus={onMouseOverOrFocus}
      className={classNames(
        "text-dark flex items-center space-x-2 px-5 py-2 first:pt-2",
        {
          "bg-tertiary": isActive,
        },
      )}
    >
      <Icon type={item.icon} size={16} />
      <div>
        <Typography.LabelSm>{item.title}</Typography.LabelSm>
      </div>
    </button>
  );
};
