import {
  Row,
  RowContainer,
} from "@circle-react-uikit/SkeletonLoader/components";

export const EmailPreview = () => (
  <div className="flex w-full flex-col items-center">
    <div className="bg-tertiary mb-8 h-[175px] w-full rounded-lg" />
    <div className="flex w-full items-center">
      <RowContainer full className="gap-2">
        <Row xxl full />
        <Row xxl half />
        <Row xxl almostFull />
      </RowContainer>
    </div>
  </div>
);
