import { t } from "i18n-js";
import type { ButtonProps } from "@circle-react-uikit/Button";
import { Button } from "@circle-react-uikit/Button";

export interface ActionsProps {
  value?: string;
  isProcessing?: boolean;
  handleDelete?: ButtonProps["onClick"];
  handleChangeCoverClick?: ButtonProps["onClick"];
}

export const Actions = ({
  value,
  isProcessing = false,
  handleDelete,
  handleChangeCoverClick,
}: ActionsProps) => {
  const hasValue = Boolean(value);

  return (
    <div className="flex gap-2">
      {hasValue && (
        <Button variant="danger" full onClick={handleDelete}>
          {t("remove")}
        </Button>
      )}
      <Button
        full
        variant="secondary"
        onClick={handleChangeCoverClick}
        disabled={!!isProcessing}
      >
        {hasValue ? t("replace") : t("upload")}
      </Button>
    </div>
  );
};
