import { NodeViewWrapper } from "@tiptap/react";
import classNames from "classnames";
import { useCurrentCommunityMember } from "@circle-react/contexts";
import { useProfileLinkProps } from "@circle-react/hooks/profile/useProfileLinkProps";
import { useBlockEditorContext } from "@circle-react-shared/uikit/TipTapBlockEditor";
import { useTipTapEditorContext } from "@circle-react-uikit/TipTap/index";

const MENTION_TYPES = {
  COMMUNITY_MEMBER: "CommunityMember",
  GROUP_MENTION: ["GroupMention", "group_mention"],
};

export interface MentionsDisplayProps {
  node: any;
}

export const MentionsDisplay = ({ node }: MentionsDisplayProps) => {
  const currentCommunityMember = useCurrentCommunityMember();
  const tiptapEditorContext = useTipTapEditorContext();
  const tiptapBlockEditorContext = useBlockEditorContext();
  const {
    sgidToObjectMap = {},
    localSgidToObjectMap = {},
    type,
    disableMentionClicks = false,
  } = tiptapEditorContext || tiptapBlockEditorContext;

  const mention =
    localSgidToObjectMap[node.attrs.sgid] || sgidToObjectMap[node.attrs.sgid];

  const profileLinkProps = useProfileLinkProps({
    public_uid: mention?.public_uid,
  });

  if (!mention) {
    return null;
  }

  const isChat = type === "chat";
  const isCommunityMember = mention.type === MENTION_TYPES.COMMUNITY_MEMBER;
  const isGroupMention = MENTION_TYPES.GROUP_MENTION.some(
    item => item === mention.type,
  );
  // TODO: Future group mentions may not always mention the current community member,
  // review this logic when that happens.
  const isCurrentCommunityMemberMentioned =
    isGroupMention ||
    (isCommunityMember && mention.id === currentCommunityMember?.id);

  const chatClasses = classNames(
    "mx-0.5 rounded-sm p-0.5 font-normal",
    isGroupMention ? "text-[#42464D]" : "text-circle cursor-pointer",
    isCurrentCommunityMemberMentioned ? "bg-amber-100" : "bg-indigo-100",
  );

  const blockClasses = "text-link cursor-pointer font-medium";

  return (
    <NodeViewWrapper as="span">
      <span
        contentEditable={false}
        className={isChat ? chatClasses : blockClasses}
        {...(disableMentionClicks || isGroupMention ? {} : profileLinkProps)}
      >
        {isChat ? "@" : ""}
        {mention.name}
      </span>
    </NodeViewWrapper>
  );
};
