import PropTypes from "prop-types";
import {
  Audio,
  File,
  Pdf,
  Video,
} from "@circle-react/components/shared/TrixEditor/BlockSelector/SelectorIcons";

export const FileTypeIcon = ({ content_type }) => {
  if (content_type == "application/pdf") {
    return <Pdf />;
  }
  if (content_type?.startsWith("audio")) {
    return <Audio />;
  }
  if (content_type?.startsWith("video")) {
    return <Video />;
  }
  return <File />;
};

FileTypeIcon.propTypes = {
  content_type: PropTypes.string,
};
