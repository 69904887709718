import classNames from "classnames";
import { useScrollIntoView } from "@circle-react/hooks/utils/useScrollIntoView";
import { Typography } from "@circle-react-uikit/Typography";
import { parseCueTime } from "./helpers";

export interface CueProps {
  cue: {
    startTime: number;
    text: string;
  };
  isCurrentCue?: boolean;
  shouldScroll?: boolean;
  onSeek: (time: number) => void;
}

export const Cue = ({
  cue,
  isCurrentCue = false,
  shouldScroll = true,
  onSeek,
}: CueProps) => {
  const elementRef = useScrollIntoView<HTMLButtonElement>(
    isCurrentCue && shouldScroll,
  );

  return (
    <button
      type="button"
      className={classNames(
        "flex cursor-pointer items-start gap-3 p-1 text-left hover:underline",
        { "bg-secondary": isCurrentCue },
      )}
      onClick={event => {
        event.stopPropagation();
        onSeek(cue.startTime);
      }}
      ref={elementRef}
    >
      <div className="shrink-0">
        <Typography.BodySm
          as="div"
          color={isCurrentCue ? "text-dark" : "text-light"}
          weight={isCurrentCue ? "semibold" : "normal"}
        >
          {parseCueTime(cue.startTime)}
        </Typography.BodySm>
      </div>
      <Typography.LabelSm
        as="div"
        color="text-dark"
        weight={isCurrentCue ? "semibold" : "normal"}
      >
        {cue.text}
      </Typography.LabelSm>
    </button>
  );
};
