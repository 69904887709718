import { useRef, useState } from "react";
import PropTypes from "prop-types";
import { t } from "i18n-js";
import { planSettingsPath } from "@circle-react/helpers/urlHelpers";
import { useWebSocket } from "@circle-react/hooks/useWebSocket";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";

export const TranscriptNotification = ({
  url,
  onNewMediaTranscript,
  onMediaTranscriptUpdated,
  mediaTranscript,
  isLoading,
}) => {
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);
  const notificationDismissedRef = useRef(false);
  const showNotification = () => setIsNotificationVisible(true);
  const dismissNotification = () => {
    notificationDismissedRef.current = true;
    setIsNotificationVisible(false);
  };
  useWebSocket({
    channel: "MediaTranscriptChannel",
    url,
    onMessageReceive: data => {
      if (data.event === "newMediaTranscript") {
        showNotification();
        onNewMediaTranscript?.(data.json_message?.id);
      } else if (data.event === "mediaTranscriptUpdated") {
        if (!notificationDismissedRef.current) {
          showNotification();
        }
        onMediaTranscriptUpdated?.(data.json_message?.id);
      }
    },
  });

  const isAiSupported = mediaTranscript?.supports_ai;

  if (!isNotificationVisible || isLoading) {
    return null;
  }

  return (
    <div className="bg-primary absolute left-5 top-5 z-10 flex w-2/3 max-w-sm items-start gap-3 rounded-md p-4 shadow-xl">
      <div className="shrink-0">
        <Icon type="20-ai" />
      </div>
      <div className="flex grow flex-col gap-4">
        <Typography.LabelSm as="div">
          {t("media_player.transcript_notification_message")}
        </Typography.LabelSm>
        {!isAiSupported && (
          <Typography.LabelSm as="div">
            <span>{`${t(
              "media_player.transcript_notification_upgrade_message",
            )} `}</span>
            <a
              className="cursor-pointer"
              href={planSettingsPath()}
              target="_blank"
              rel="noreferrer"
            >
              {t("media_player.transcript_notification_upgrade_link")}
            </a>
          </Typography.LabelSm>
        )}
      </div>
      <div className="shrink-0">
        <Icon
          type="20-close"
          onClick={dismissNotification}
          className="cursor-pointer"
        />
      </div>
    </div>
  );
};

TranscriptNotification.propTypes = {
  url: PropTypes.string,
  onNewMediaTranscript: PropTypes.func,
  onMediaTranscriptUpdated: PropTypes.func,
  mediaTranscript: PropTypes.object,
  isLoading: PropTypes.bool,
};
