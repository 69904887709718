import { useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { endOfDay, isSameDay, startOfDay } from "date-fns";
import { noop } from "lodash";
import DatePicker from "react-datepicker";
import { Controller, useFormContext } from "react-hook-form";
import {
  getNextQuarter,
  isMidnight,
} from "@circle-react/helpers/dateTimeHelpers";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "../Typography";
import "./datepicker.scss";

const getMinAndMaxTime = (selectedDate, minDate, maxDate) => {
  if (isSameDay(selectedDate, minDate) && isSameDay(selectedDate, maxDate)) {
    return { minTime: minDate, maxTime: maxDate };
  }

  if (isSameDay(selectedDate, minDate)) {
    return { minTime: minDate, maxTime: endOfDay(minDate) };
  }

  if (isSameDay(selectedDate, maxDate)) {
    return { minTime: startOfDay(maxDate), maxTime: maxDate };
  }

  return { minTime: null, maxTime: null };
};

/**
 *
 * @param {Object} props
 * @param {string} props.name
 * @param {Function} [props.onChange]
 * @param {Date} [props.minDate]
 * @param {Date} [props.maxDate]
 * @param {boolean} [props.disabled]
 * @param {string} [props.dataTestId]
 * @param {string} [props.datePlaceholder]
 * @param {string} [props.timePlaceholder]
 * @param {string} [props.portalId]
 * @param {string} [props.popperClassName]
 * @param {Object} [props.rest] - Rest of the props to pass to react-datepicker
 * @returns
 */
export const DateTimePicker = ({
  name,
  onChange = noop,
  minDate,
  maxDate,
  disabled = false,
  dataTestId,
  datePlaceholder = "",
  timePlaceholder = "",
  labelDate = "",
  labelTime = "",
  className = "",
  classNameDate = "",
  classNameTime = "",
  ...rest
}) => {
  const { control } = useFormContext();

  const refDate = useRef();
  const refTime = useRef();

  const handleDateChange =
    (setFormValue = noop) =>
    value => {
      if (!(value instanceof Date)) return;
      let scheduledDateTime = value;

      if (
        isSameDay(scheduledDateTime, minDate) &&
        isMidnight(scheduledDateTime)
      ) {
        scheduledDateTime = getNextQuarter(minDate);
      }

      setFormValue(scheduledDateTime.toUTCString());

      onChange(scheduledDateTime);
    };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => {
        const selectedDate = value && new Date(value);

        const { minTime, maxTime } = getMinAndMaxTime(
          selectedDate,
          minDate,
          maxDate,
        );

        return (
          <div
            className={classNames("flex", className)}
            data-testid={dataTestId}
          >
            <div className={classNameDate}>
              {labelDate && (
                <label htmlFor={name} className="mb-2">
                  <Typography.LabelSm weight="semibold">
                    {labelDate}
                  </Typography.LabelSm>
                </label>
              )}
              <div className="relative mr-1" data-testid="event_date">
                <DatePicker
                  ref={refDate}
                  selected={selectedDate}
                  dateFormat="MMM d, yyyy"
                  onChange={handleDateChange(onChange)}
                  minDate={minDate}
                  maxDate={maxDate}
                  name={name}
                  disabled={disabled}
                  placeholderText={datePlaceholder}
                  className="relative"
                  {...rest}
                />
                <Icon
                  type="cheveron-down"
                  className="absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer hover:text-gray-800"
                  onClick={() => {
                    refDate.current?.input?.focus();
                  }}
                />
              </div>
            </div>
            <div className={classNameTime}>
              {labelTime && (
                <label htmlFor={name} className="mb-2">
                  <Typography.LabelSm weight="semibold">
                    {labelTime}
                  </Typography.LabelSm>
                </label>
              )}
              <div className="relative ml-1" data-testid="event_hour">
                <DatePicker
                  ref={refTime}
                  selected={selectedDate}
                  onChange={handleDateChange(onChange)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  dateFormat="h:mm aa"
                  disabled={disabled}
                  placeholderText={timePlaceholder}
                  minTime={minTime}
                  maxTime={maxTime}
                  className="relative"
                  {...rest}
                />
                <Icon
                  type="cheveron-down"
                  className="absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer hover:text-gray-800"
                  onClick={() => {
                    refTime.current?.input?.focus();
                  }}
                />
              </div>
            </div>
          </div>
        );
      }}
    />
  );
};

DateTimePicker.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  disabled: PropTypes.bool,
  dataTestId: PropTypes.string,
  datePlaceholder: PropTypes.string,
  timePlaceholder: PropTypes.string,
  labelDate: PropTypes.string,
  labelTime: PropTypes.string,
  className: PropTypes.string,
  classNameDate: PropTypes.string,
  classNameTime: PropTypes.string,
};
