// Checks if the given media content type is natively supported in most modern
// browsers and devices. This is not an exhaustive list and covers only the
// most popular media types. Some browsers may support more content types.
export const isMediaContentTypeNativelySupported = (contentType: string) =>
  [
    "video/mp4",
    "video/mpeg",
    "video/ogg",
    "audio/mpeg",
    "audio/ogg",
    "audio/mp4",
    "audio/wav",
    "audio/x-wav",
  ].includes(contentType);
