import classNames from "classnames";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { Icon } from "@circle-react-shared/Icon";
import { UserImage } from "@circle-react-shared/uikit/UserImage";

export const MentionItem = ({ member, isSelected, handleClick }: any) => (
  <button
    type="button"
    data-testid="mention-item"
    className={classNames(
      "hover:bg-tertiary flex cursor-pointer items-center px-3 py-1.5",
      { "bg-tertiary": isSelected },
    )}
    onClick={handleClick}
  >
    <div className="mr-3">
      {member.type === "group_mention" ? (
        <Icon type="20-members" size={20} className="!text-darkest ml-1 mr-2" />
      ) : (
        <UserImage size="8" name={member.name} src={member.avatar_url} />
      )}
    </div>

    <Typography.LabelSm weight="medium">{member.name}</Typography.LabelSm>
  </button>
);
