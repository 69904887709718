import { useImperativeHandle } from "react";
import { defer } from "lodash";
import { useInternalEditorState } from "@circle-react-shared/uikit/TipTap/internalEditorState";

export const useKeyboardHandler = ({
  selectedIndex,
  setSelectedIndex,
  items,
  ref,
  selectItem,
}: any) => {
  const { setPopupsVisible } = useInternalEditorState();
  useImperativeHandle(ref, () => {
    const upHandler = () =>
      setSelectedIndex(
        (Number(selectedIndex) + Number(items.length) - 1) % items.length,
      );

    const downHandler = () =>
      setSelectedIndex((Number(selectedIndex) + 1) % items.length);

    const enterHandler = () => selectItem(selectedIndex);

    return {
      onKeyDown: ({ event }: any) => {
        switch (event.key) {
          case "ArrowUp":
            upHandler();
            return true;
          case "ArrowDown":
            downHandler();
            return true;
          case "Enter":
            if (!items.length) return false;
            enterHandler();
            defer(() => setPopupsVisible(false));
            return true;
          default:
            setPopupsVisible(false);
            return false;
        }
      },
    };
  }, [items, selectedIndex, selectItem, setPopupsVisible, setSelectedIndex]);
};
