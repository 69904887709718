import type { ReactNode } from "react";
import classNames from "classnames";

export interface StatusBadgeProps {
  children: ReactNode;
  variant?: "success" | "warning" | "danger" | "info" | "default";
  textTransform?: "uppercase" | "capitalize" | "lowercase" | "normal-case";
}

const fontColors = {
  success: "text-v2-success",
  warning: "text-v2-warning-900",
  danger: "text-v2-danger",
  default: "text-dark",
  info: "text-v2-info",
};

const bgColors = {
  success: "bg-v2-success-100",
  warning: "bg-v2-warning-100",
  danger: "bg-v2-danger-100",
  default: "bg-secondary",
  info: "bg-blue-100",
};

export const StatusBadge = ({
  children,
  variant = "default",
  textTransform,
}: StatusBadgeProps) => {
  const fontStyles = fontColors[variant] || fontColors.default;
  const bgStyles = bgColors[variant] || bgColors.default;

  return (
    <span
      className={classNames(
        "rounded-md px-2 py-1 text-xs font-semibold",
        fontStyles,
        bgStyles,
        textTransform,
      )}
    >
      {children}
    </span>
  );
};
