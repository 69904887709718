import { useEffect } from "react";
import noop from "lodash/noop";
import { useCurrentPostContext } from "@circle-react/contexts";
import { useCurrentLessonContext } from "@circle-react/contexts/currentLessonContext";

export const useFormSubmitDisabler = ({ isLoading }) => {
  const { setIsSubmitEnabled: setPostIsSubmitEnabled = noop } =
    useCurrentPostContext();
  const { setIsSubmitEnabled: setLessonIsSubmitEnabled = noop } =
    useCurrentLessonContext();

  useEffect(() => {
    setPostIsSubmitEnabled(!isLoading);
    setLessonIsSubmitEnabled(!isLoading);
  }, [isLoading, setPostIsSubmitEnabled, setLessonIsSubmitEnabled]);
};
