import type { Node } from "@tiptap/core";
import { Extension } from "@tiptap/core";
import { Plugin, PluginKey } from "prosemirror-state";

const nodeEqualsType = ({ types, node }: { types: any; node: Node | null }) =>
  node &&
  ((Array.isArray(types) && types.includes(node.type)) || node.type === types);

export interface TrailingNodeOptions {
  node: string;
  notAfter: string[];
}

// Based on the experimental TrailingNode extension:
// - https://github.com/ueberdosis/tiptap/blob/f387ad3dd4c2b30eaea33fb0ba0b42e0cd39263b/demos/src/Experiments/TrailingNode/Vue/trailing-node.ts
export const TrailingNode = Extension.create<TrailingNodeOptions>({
  name: "trailingNode",

  addOptions() {
    return {
      node: "paragraph",
      notAfter: ["paragraph"],
    };
  },

  addProseMirrorPlugins() {
    const plugin = new PluginKey(this.name);
    const disabledNodes = Object.entries(this.editor.schema.nodes)
      .map(([, node]) => node)
      .filter((node: any) => this.options.notAfter.includes(node.name));

    return [
      new Plugin({
        key: plugin,
        appendTransaction: (_tr: any, _oldState: any, newState: any) => {
          const { doc, tr, schema } = newState;
          const shouldInsertNodeAtEnd = plugin.getState(newState);
          const endPosition = doc.content.size;
          const type = schema.nodes[this.options.node];

          if (!shouldInsertNodeAtEnd) {
            return;
          }

          return tr.insert(endPosition, type.create());
        },
        state: {
          init: (_config: any, state: any) => {
            const lastNode = state.tr.doc.lastChild;

            return (
              lastNode &&
              !nodeEqualsType({ node: lastNode, types: disabledNodes })
            );
          },
          apply: (tr: any, value: any) => {
            if (!tr.docChanged) {
              return value;
            }

            const lastNode = tr.doc.lastChild;

            return !nodeEqualsType({ node: lastNode, types: disabledNodes });
          },
        },
      }),
    ];
  },
});
