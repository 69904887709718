import PropTypes from "prop-types";
import { isFunction, noop } from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import { TipTapBlockEditor } from "../../TipTapBlockEditor";

export const FormTipTapBlockEditor = ({
  name,
  onChangeCallback = noop,
  ...passThroughProps
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <TipTapBlockEditor
          value={value}
          onChange={value => {
            isFunction(onChangeCallback) && onChangeCallback(value);
            onChange(value);
          }}
          {...passThroughProps}
        />
      )}
    />
  );
};

FormTipTapBlockEditor.propTypes = {
  className: PropTypes.string,
  editorClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onLocalSgidToObjectMapChange: PropTypes.func,
  onLocalAttachmentsChange: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  sgidToObjectMap: PropTypes.object,
  value: PropTypes.any,
  showListsInTextModifierMenu: PropTypes.bool,
};
