import { useFormContext } from "react-hook-form";
import type { FormInputProps } from "@circle-react-uikit/Form/FormInput";
import { FormInput } from "@circle-react-uikit/Form/FormInput";
import { prependHttps } from "./prependHttps";

export const LinkInput = ({ name, onBlur, ...rest }: FormInputProps) => {
  const { setValue } = useFormContext();

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const newValue = prependHttps(e.target.value);
    setValue(name, newValue, { shouldValidate: true });
    onBlur?.(e);
  };

  return <FormInput {...rest} type="url" name={name} onBlur={handleOnBlur} />;
};
