import { useCallback, useRef, useState } from "react";
import type { ElementType, MouseEvent, ReactNode } from "react";
import { Menu } from "@headlessui/react";
import classNames from "classnames";
import { usePopper } from "react-popper";
import { ActiveLabelButton } from "./ActiveLabelButton";
import { BadgeContainer } from "./BadgeContainer";
import { DropdownProvider, useDropdownContext } from "./Context";
import { Header } from "./Header";
import { IconContainer } from "./IconContainer";
import { IconWithBadgeList } from "./IconWithBadgeList";
import { Item } from "./Item";
import { ItemGroup } from "./ItemGroup";
import { ItemLink } from "./ItemLink";
import { ItemWithLink } from "./ItemWithLink";
import { MenuButton } from "./MenuButton";
import { MenuItems } from "./MenuItems";
import type { Options } from "./MenuItems";
import { Separator } from "./Separator";
import { DROPDOWN_DIRECTION, DROPDOWN_SIZE } from "./constants";
import type {
  DropdownAnimation,
  DropdownDirection,
  DropdownSize,
} from "./constants";
import { popperSameWidthModifier } from "./utils";

export { useDropdownContext, DROPDOWN_SIZE, DROPDOWN_DIRECTION };

type PopperOptions = Parameters<typeof usePopper>[2];
type DropdownOptions = Options;
type DropDownOption = DropdownOptions[number];

export type {
  DropdownOptions,
  DropDownOption,
  PopperOptions,
  DropdownAnimation,
  DropdownDirection,
  DropdownSize,
};

export interface DropdownProps {
  as?: ElementType;
  buttonWrapperComponent?: ElementType;
  activeOptionValue?: string;
  animations?: DropdownAnimation;
  appendTo?: Element;
  button?: ReactNode;
  buttonClassName?: string;
  buttonLabel?: string;
  buttonType?: "button" | "submit" | "reset";
  buttonWrapperClassName?: string;
  children?: ReactNode;
  className?: string;
  dataTestId?: string;
  buttonDataTestId?: string;
  direction?: DropdownDirection;
  isDark?: boolean;
  labelWeight?: string;
  linkType?: "button" | "submit" | "reset";
  menuWrapperClassName?: string;
  menuItemsClassName?: string;
  onChange?: (value: string) => void;
  onToggle?: (open: boolean) => void;
  options?: DropdownOptions;
  popperOptions?: PopperOptions;
  shouldAddDivider?: boolean;
  size?: DropdownSize;
  transitionWrapperClassName?: string;
  menuButtonEl?: ElementType;
}

const DropdownComponent = ({
  as = "div",
  buttonWrapperComponent: ButtonWrapperComponent = "div",
  activeOptionValue,
  animations,
  appendTo,
  button,
  buttonClassName,
  buttonLabel,
  buttonType,
  buttonWrapperClassName,
  children,
  className = "inline-block text-left",
  dataTestId,
  buttonDataTestId = "dropdown-button",
  direction = "bottom-end",
  isDark = false,
  labelWeight,
  linkType,
  menuWrapperClassName,
  menuItemsClassName,
  onChange,
  onToggle,
  options,
  popperOptions,
  shouldAddDivider = false,
  size = DROPDOWN_SIZE.MEDIUM,
  transitionWrapperClassName,
  menuButtonEl = "button",
}: DropdownProps) => {
  const popperElRef = useRef(null);
  const dropdownButtonRef = useRef<HTMLButtonElement | null>(null);
  const [popperTarget, setPopperTarget] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );

  const shouldRenderPortal = Boolean(appendTo);
  const hasCustomButton = Boolean(button);
  const hasOptions = Boolean(options);

  let localPopperOptions: PopperOptions = {
    placement: direction,
    strategy: "fixed",
  };

  if (shouldRenderPortal && size === DROPDOWN_SIZE.FULL) {
    localPopperOptions.modifiers = [popperSameWidthModifier];
  }

  localPopperOptions = {
    ...localPopperOptions,
    ...popperOptions,
  };

  const { styles, attributes } = usePopper(
    popperTarget,
    popperElement,
    localPopperOptions,
  );

  const toggleMenuOpen = useCallback(() => {
    /*
      Headless UI does not provide a way to open/close a Menu programmatically,
      so we need to do simulate a click on the dropdown button to toggle it.
    */
    dropdownButtonRef.current?.click?.();
  }, [dropdownButtonRef]);

  const onClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
  };

  return (
    <Menu as={as} className={className} data-testid="dropdown">
      {({ open }) => (
        <DropdownProvider
          isOpen={open}
          toggle={toggleMenuOpen}
          onToggle={onToggle}
        >
          <ButtonWrapperComponent
            ref={setPopperTarget}
            className={buttonWrapperClassName}
            data-testid={dataTestId || "dropdown-button-wrapper"}
          >
            <Menu.Button
              as={menuButtonEl}
              ref={dropdownButtonRef}
              className={classNames(
                "focus-visible:outline-secondary rounded-md focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
                buttonClassName,
              )}
              data-testid={buttonDataTestId}
              type={buttonType}
              aria-label={buttonLabel}
              onClick={onClick}
            >
              {hasCustomButton && button}
              {!hasCustomButton && (
                <ActiveLabelButton
                  activeOptionValue={activeOptionValue}
                  options={options}
                />
              )}
            </Menu.Button>
            <MenuItems
              open={open}
              size={size}
              onChange={onChange}
              options={options}
              hasOptions={hasOptions}
              menuWrapperClassName={menuWrapperClassName}
              transitionWrapperClassName={transitionWrapperClassName}
              shouldAddDivider={shouldAddDivider}
              popperElRef={popperElRef}
              popperAttributes={attributes.popper}
              popperStyles={styles.popper}
              appendTo={appendTo}
              setPopperElement={setPopperElement}
              linkType={linkType}
              labelWeight={labelWeight}
              animations={animations}
              isDark={isDark}
              menuItemsClassName={menuItemsClassName}
            >
              {children}
            </MenuItems>
          </ButtonWrapperComponent>
        </DropdownProvider>
      )}
    </Menu>
  );
};

export const Dropdown = Object.assign(DropdownComponent, {
  Header,
  Item,
  ItemWithLink,
  ItemGroup,
  ItemLink,
  MenuButton,
  ActiveLabelButton,
  BadgeContainer,
  IconContainer,
  IconWithBadgeList,
  Separator,
});
