import { forwardRef } from "react";
import classNames from "classnames";
import TextAreaAutosize from "react-textarea-autosize";

export const Input = forwardRef(({ className, ...passThroughProps }, ref) => (
  <TextAreaAutosize
    ref={ref}
    autoComplete="off"
    minRows={1}
    maxRows={3}
    type="text"
    className={classNames(
      "text-dark placeholder:text-default w-full resize-none border-0 bg-transparent p-0 leading-5 outline-none focus:ring-0",
      className,
    )}
    {...passThroughProps}
  />
));

Input.displayName = "Input";
