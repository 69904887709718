import "fullscreen-polyfill";
import { QueryClientProvider } from "@circle-react/providers/QueryClient";
import { ToastProvider } from "@circle-react-uikit/ToastV2";
import type { TranscriptsPlayerProps } from "./TranscriptsPlayerComponent";
import { TranscriptsPlayerComponent } from "./TranscriptsPlayerComponent";

export const TranscriptsPlayer = (props: TranscriptsPlayerProps) => (
  <QueryClientProvider>
    <ToastProvider>
      <TranscriptsPlayerComponent {...props} />
    </ToastProvider>
  </QueryClientProvider>
);
