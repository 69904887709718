import { DefaultModal } from "./DefaultModal";
import { FullScreenModal } from "./FullScreenModal";
import { LargeModal } from "./LargeModal";

/** @deprecated Please use the new Modal components at "@circle-react-uikit/ModalV2" */
export const Modal = ({ variant, ...rest }: any) => {
  let Component;
  switch (variant) {
    case "fullscreen":
      Component = FullScreenModal;
      break;
    case "large":
      Component = LargeModal;
      break;
    case "default":
      Component = DefaultModal;
      break;
    default:
      Component = DefaultModal;
  }

  return <Component {...rest} />;
};
