import PropTypes from "prop-types";
import { addSeconds, differenceInSeconds } from "date-fns";
import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { getDateFromString } from "@circle-react/helpers/dateTimeHelpers";
import { DateTimePicker } from "@circle-react-uikit/DateTimePicker";

export const DateRangePicker = ({
  startsAtConfig,
  endsAtConfig,
  durationConfig,
  disabled = false,
}) => {
  const { register, setValue, getValues } = useFormContext();

  const startDate = getDateFromString(getValues(startsAtConfig.name));

  const onStartDateChange = value => {
    const duration = getValues(durationConfig.name);
    const endDate = addSeconds(value, duration);

    setValue(endsAtConfig.name, endDate);
  };

  const onEndDateChange = () => {
    const startDate = getDateFromString(getValues(startsAtConfig.name));
    const endDate = getDateFromString(getValues(endsAtConfig.name));

    const duration = differenceInSeconds(endDate, startDate);
    if (duration < 0) return;

    setValue(durationConfig.name, duration, {
      shouldDirty: true,
    });
  };

  return (
    <div className="flex flex-col items-start gap-2 md:flex-row md:items-center">
      <DateTimePicker
        name={startsAtConfig.name}
        onChange={onStartDateChange}
        minDate={startsAtConfig.minDate}
        dataTestId="event_starts_at"
        disabled={disabled}
      />
      <div className="mx-2">{t("to")}</div>
      <DateTimePicker
        name={endsAtConfig.name}
        onChange={onEndDateChange}
        minDate={startDate}
        dataTestId="event_ends_at"
        disabled={disabled}
      />
      <div className="hidden">
        <input type="hidden" {...register(durationConfig.name)} />
      </div>
    </div>
  );
};

DateRangePicker.propTypes = {
  startsAtConfig: PropTypes.shape({
    name: PropTypes.string,
    minDate: PropTypes.object,
  }),
  endsAtConfig: PropTypes.shape({ name: PropTypes.string }),
  durationConfig: PropTypes.shape({ name: PropTypes.string }),
};
