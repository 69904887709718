import { useRef } from "react";
import type { Editor, Range } from "@tiptap/react";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { PollForm } from "@circle-react-shared/uikit/TipTapBlockEditor/PollExtension/PollModal/PollForm";
import type { PollResponse } from "@circle-react-shared/uikit/TipTapBlockEditor/PollExtension/PollModal/api";
import { Modal } from "@circle-react-uikit/ModalV2";

interface ModalProps {
  editor: Editor;
  range: Range;
  addToLocalSgidToObjectMap: (params: { sgid: string; object: any }) => void;
}

const PollModalComponent = ({
  editor,
  range,
  addToLocalSgidToObjectMap,
}: ModalProps) => {
  const modal = useModal();
  const initialFocusRef = useRef<HTMLInputElement>(null);
  const insertPoll = (poll: PollResponse) => {
    editor
      .chain()
      .focus()
      .insertContentAt(range, [
        {
          type: "poll",
          attrs: {
            sgid: poll.sgid,
          },
        },
        {
          type: "hardBreak",
        },
      ])
      .run();
    addToLocalSgidToObjectMap({ sgid: poll.sgid, object: poll });
  };
  return (
    <Modal
      isOpen={modal.visible}
      onClose={modal.remove}
      initialFocus={initialFocusRef}
      className="h-full max-h-full overflow-visible"
    >
      <Modal.Overlay />
      <Modal.Content size="lg" shouldCloseOnOutsideClick>
        <PollForm
          closeModal={modal.remove}
          onComplete={insertPoll}
          initialFocusRef={initialFocusRef}
        />
      </Modal.Content>
    </Modal>
  );
};

export const usePollModal = () =>
  useModal(
    ModalManager.create((props: ModalProps) => (
      <PollModalComponent {...props} />
    )),
  );
