import type { ReactNode } from "react";
import { Fragment, useRef, useState } from "react";
import {
  Popover as HeadlessUIPopover,
  Portal,
  Transition,
} from "@headlessui/react";
import { usePopper } from "react-popper";
import { useClickAway } from "react-use";
import type { IconType } from "@circle-react-shared/Icon";
import { Body } from "./Body";
import { Button } from "./Button";

const DEFAULT_POPPER_OPTIONS = {
  strategy: "fixed" as const,
  placement: "bottom-end" as const,
};

const POPOVER_TRANSITIONS = {
  enter: "transition ease-out duration-100",
  enterFrom: "opacity-0",
  enterTo: "opacity-100",
  leave: "transition ease-in duration-100",
  leaveFrom: "opacity-100",
  leaveTo: "opacity-0",
};

interface PopoverProps {
  isOpen: boolean;
  onToggle: () => void;
  children?: ReactNode;
  iconType: IconType;
  popperOptions?: object;
}

export const Popover = ({
  iconType,
  isOpen,
  onToggle,
  children,
}: PopoverProps) => {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const [targetElement, setTargetElement] = useState<HTMLButtonElement | null>(
    null,
  );
  const popoverRef = useRef<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(
    targetElement,
    popperElement,
    DEFAULT_POPPER_OPTIONS,
  );

  const isInsideModal = (element: EventTarget) => {
    if (element instanceof Element) {
      return element.closest(".modal__box") !== null;
    }
    return false;
  };

  const isClickInsideButton = (event: Event) =>
    event instanceof MouseEvent &&
    event.target instanceof Node &&
    targetElement?.contains(event.target);

  useClickAway(popoverRef, event => {
    if (!(event.target instanceof Node)) {
      return;
    }
    if (!isClickInsideButton(event) && !isInsideModal(event.target)) {
      onToggle();
    }
  });

  return (
    <HeadlessUIPopover as="div" className="relative">
      <Button iconType={iconType} onClick={onToggle} ref={setTargetElement} />
      <Transition {...POPOVER_TRANSITIONS} show={isOpen}>
        {isOpen && (
          <Portal as={Fragment}>
            <HeadlessUIPopover.Panel
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
              className="z-20"
            >
              <div ref={popoverRef}>
                <Body>{children}</Body>
              </div>
            </HeadlessUIPopover.Panel>
          </Portal>
        )}
      </Transition>
    </HeadlessUIPopover>
  );
};
