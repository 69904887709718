import { useNotificationWebSocket } from "@/react/hooks/notifications/useNotificationWebSocket";
import { usePunditUserContext } from "@circle-react/contexts";
import { useTipTapEditorContext } from "../..";
import { WEBSOCKET_EVENT_ERROR, WEBSOCKET_EVENT_SUCCESS } from "../constants";

export const useWebsocket = ({ onClose, recordingId, setServerError }) => {
  const { currentCommunityMember } = usePunditUserContext();
  const { id: currentCommunityMemberId } = currentCommunityMember;
  const { localAttachments, setLocalAttachments } = useTipTapEditorContext();

  useNotificationWebSocket(currentCommunityMemberId, eventData => {
    if (
      (eventData.event === WEBSOCKET_EVENT_SUCCESS ||
        eventData.event === WEBSOCKET_EVENT_ERROR) &&
      eventData.json_message.recording_id === recordingId
    ) {
      if (eventData.event === WEBSOCKET_EVENT_SUCCESS) {
        setLocalAttachments([
          ...localAttachments,
          {
            url: eventData.json_message.url,
            signed_id: eventData.json_message.signed_id,
            content_type: eventData.json_message.content_type,
            metadata: { voice_message: true },
          },
        ]);

        onClose();
      } else if (eventData.event === WEBSOCKET_EVENT_ERROR) {
        setServerError();
      }
    }
  });
};
