import { NodeViewWrapper } from "@tiptap/react";
import { TranscriptsPlayer } from "@circle-react-uikit/TranscriptsPlayer";

export const VideoRenderer = ({ attachment, isTranscodingEnabled = false }) => (
  <NodeViewWrapper as="div" id="file-wrapper">
    <TranscriptsPlayer
      src={attachment.url}
      type={attachment.content_type}
      mediaTranscoding={attachment.media_transcoding}
      mediaTranscriptId={attachment.media_transcript_id}
      isDownloadable={attachment.is_downloadable}
      isTranscodingEnabled={isTranscodingEnabled}
      poster={attachment?.image_variants?.thumbnail}
      variants={attachment.video_variants}
      signedId={attachment.signed_id}
    />
  </NodeViewWrapper>
);
