import classNames from "classnames";
import { t } from "i18n-js";
import type {
  FieldValues,
  UseFieldArrayInsert,
  UseFieldArrayRemove,
} from "react-hook-form";
import { useFormState } from "react-hook-form";
import { useHotkeys } from "react-hotkeys-hook";
import { Icon } from "@circle-react-shared/Icon";
import { IconButton as DragHandleIconButton } from "@circle-react-shared/SortableItem/IconButton";
import { Form } from "@circle-react-shared/uikit/Form";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { useListItemDataProvider } from "@circle-react-shared/uikit/SortableList";
import { MAX_POLL_OPTIONS_COUNT, NEW_POLL_OPTION } from "./constants";

interface OptionItemProps {
  index: number;
  insert: UseFieldArrayInsert<FieldValues, "poll_options">;
  remove: UseFieldArrayRemove;
  fields: Record<"id", string>[];
  disabled?: boolean;
}

export const OptionItem = ({
  index,
  insert,
  fields,
  remove,
  disabled,
}: OptionItemProps) => {
  const { listeners } = useListItemDataProvider();
  const ref = useHotkeys<HTMLInputElement>(
    "enter",
    event => {
      event.preventDefault();
      insert(index + 1, NEW_POLL_OPTION);
    },
    {
      enabled: fields.length < MAX_POLL_OPTIONS_COUNT,
      enableOnTags: ["INPUT"],
    },
  );

  const { errors }: any = useFormState({
    name: "options",
  });

  const hasError = errors?.options?.[index]?.value?.message;

  return (
    <div
      className={classNames("group flex items-center justify-between md:pl-2", {
        "form-group--has-error": hasError,
      })}
    >
      <DragHandleIconButton
        name="16-drag-handle"
        className="invisible mr-1 hidden cursor-move group-hover:visible md:block"
        {...listeners}
      >
        <Icon type="16-drag-handle" size={16} />
      </DragHandleIconButton>
      <Form.Input
        ref={ref}
        id={`poll_options-${index}`}
        name={`poll_options.${index}.value`}
        placeholder={t("tiptap.extensions.poll.option_placeholder", {
          number: index + 1,
        })}
        disabled={disabled}
      />
      {!disabled && (
        <IconButton
          className={classNames(
            "border-primary invisible ml-2 rounded-full border",
            {
              "group-hover:visible": fields.length > 1,
            },
          )}
          name="20-close"
          onClick={() => {
            fields.length > 1 && remove(index);
          }}
          iconSize={20}
          buttonSize={32}
          ariaLabel={t("close")}
        />
      )}
    </div>
  );
};
