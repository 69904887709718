import classNames from "classnames";
import { defer } from "lodash";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { useInternalEditorState } from "@circle-react-shared/uikit/TipTap/internalEditorState";

export interface EmojiItemProps {
  emoji: any;
  index: number;
  isSelected: boolean;
  setSelectedIndex: (index: number) => void;
  insertEmoji: (emoji: any) => void;
}

export const EmojiItem = ({
  emoji,
  index,
  isSelected,
  setSelectedIndex,
  insertEmoji,
}: EmojiItemProps) => {
  const { setPopupsVisible } = useInternalEditorState();

  const onMouseOverOrFocus = () => {
    setSelectedIndex(index);
  };

  return (
    <button
      type="button"
      tabIndex={0}
      id={`emoji-${emoji.id}`}
      key={emoji.id}
      onClick={() => {
        insertEmoji(emoji);
        defer(() => setPopupsVisible(false));
      }}
      onMouseOver={onMouseOverOrFocus}
      onFocus={onMouseOverOrFocus}
      className={classNames(
        "text-light flex w-full cursor-pointer items-center space-x-2 px-5 py-2 first:pt-2",
        {
          "bg-tertiary": isSelected,
        },
      )}
    >
      <span className="text-base">{emoji.skins[0].native}</span>
      <div className="truncate">
        <Typography.LabelSm>:{emoji.id}:</Typography.LabelSm>
      </div>
    </button>
  );
};
