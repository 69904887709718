import { getNodeType } from "@tiptap/core";

// TODO: Remove after upgrading Tiptap to 2.1.0.
//
// This code was taken from the latest version of Tiptap (2.1.0.rc9) with a few
// modifications to conform to our style conventions and avoid type errors due
// to multiple versions of ProseMirror packages.
//
// Source: https://github.com/ueberdosis/tiptap/blob/75f0418f03fb040dc4c12c104dfe457342b4e4b4/packages/extension-list-item/src/helpers/index.ts#L9
export const findListItemPos = (typeOrName: any, state: any) => {
  const { $from } = state.selection;
  const nodeType = getNodeType(typeOrName, state.schema);

  let currentNode: any = null;
  let currentDepth = $from.depth;
  let currentPos = $from.pos;
  let targetDepth: number | null = null;

  while (currentDepth > 0 && targetDepth === null) {
    currentNode = $from.node(currentDepth);

    if (currentNode.type === nodeType) {
      targetDepth = currentDepth;
    } else {
      currentDepth -= 1;
      currentPos -= 1;
    }
  }

  if (targetDepth === null) {
    return null;
  }

  return { $pos: state.doc.resolve(currentPos), depth: targetDepth };
};

// TODO: Remove after upgrading Tiptap to 2.1.0.
//
// This code was taken from the latest version of Tiptap (2.1.0.rc9) with a few
// modifications to conform to our style conventions.
//
// Source: https://github.com/ueberdosis/tiptap/blob/75f0418f03fb040dc4c12c104dfe457342b4e4b4/packages/extension-list-item/src/helpers/hasListItemBefore.ts#L3
export const hasListItemBefore = (typeOrName: string, state: any): boolean => {
  const { $anchor } = state.selection;
  const $targetPos = state.doc.resolve($anchor.pos - 2);

  return $targetPos.index() === 0 ||
    $targetPos.nodeBefore?.type.name !== typeOrName
    ? false
    : true;
};

// TODO: Remove after upgrading Tiptap to 2.1.0.
//
// This code was taken from the latest version of Tiptap (2.1.0.rc9) with a few
// modifications to conform to our style conventions.
//
// Source: https://github.com/ueberdosis/tiptap/blob/75f0418f03fb040dc4c12c104dfe457342b4e4b4/packages/core/src/helpers/isAtStartOfNode.ts#L3
export const isAtStartOfNode = (state: any) => {
  const { $from, $to } = state.selection;
  return !($from.parentOffset > 0 || $from.pos !== $to.pos);
};

// TODO: Remove after upgrading Tiptap to 2.1.0.
//
// This code was taken from the latest version of Tiptap (2.1.0.rc9).
//
// Source: https://github.com/ueberdosis/tiptap/blob/75f0418f03fb040dc4c12c104dfe457342b4e4b4/packages/extension-list-item/src/helpers/listItemhasSublist.ts#L5
export const listItemHasSubList = (
  typeOrName: string,
  state: any,
  node?: any,
) => {
  if (!node) {
    return false;
  }

  const nodeType = getNodeType(typeOrName, state.schema);

  let hasSubList = false;

  node.descendants((child: any) => {
    if (child.type === nodeType) {
      hasSubList = true;
    }
  });

  return hasSubList;
};
