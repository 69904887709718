import type { ReactNode } from "react";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { useIsV3 } from "@circle-react/hooks/useIsV3";

export interface HeaderProps {
  children: ReactNode;
}

export const Header = ({ children }: HeaderProps) => {
  const { isV3Enabled } = useIsV3();

  if (!isV3Enabled) {
    return (
      <div className="block px-5 py-0.5">
        <Typography.LabelXxs color="text-light">{children}</Typography.LabelXxs>
      </div>
    );
  }

  return (
    <div className="flex items-center px-3 py-2">
      <Typography.LabelXsPlus color="text-lightest" weight="medium">
        {children}
      </Typography.LabelXsPlus>
    </div>
  );
};
