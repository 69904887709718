import PropTypes from "prop-types";
import classNames from "classnames";
import { SortableContext } from "../Provider";

export const List = ({ gap = 2, className = "", children, ...rest }) => (
  <SortableContext {...rest}>
    {/** @todo Fix this. class should not be dynamic https://tailwindcss.com/docs/content-configuration#dynamic-class-names */}
    <ul className={classNames(`grid gap-${gap}`, className)}>{children}</ul>
  </SortableContext>
);

List.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  gap: PropTypes.number,
};
