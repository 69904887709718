import { Extension } from "@tiptap/core";

export const DisableModEnterShortcut = Extension.create({
  name: "disableModEnter",

  addKeyboardShortcuts() {
    return {
      "Mod-Enter": () => true,
    };
  },
});
