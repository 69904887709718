import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

export const hasMessageAccess = (id: any, accessArray: any) => {
  for (const obj of accessArray) {
    if (Object.prototype.hasOwnProperty.call(obj, id) && obj[id] === true) {
      return true;
    }
  }
  return false;
};

export const checkMessageApiAccess = async ({
  chatRoomUuid,
  messageId,
}: any) => {
  try {
    const message = await reactQueryGet(
      internalApi.chatRoomMessages.show({
        uuid: chatRoomUuid,
        id: messageId,
      }),
    );
    if (message) {
      return true;
    }
    return undefined;
  } catch (err) {
    console.error("No chat message access", err);
    return false;
  }
};
