import { padStart } from "lodash";

export const padNumber = num => padStart(num, 2, "0");

export const parseCueTime = strTime => {
  const numTime = Number(strTime);
  const hours = Math.floor(numTime / 3600);
  const minutes = Math.floor((numTime - hours * 3600) / 60);
  const seconds = Math.floor(numTime - hours * 3600 - minutes * 60);
  if (hours) {
    return `${padNumber(hours)}:${padNumber(minutes)}:${padNumber(seconds)}`;
  }
  return `${padNumber(minutes)}:${padNumber(seconds)}`;
};

export const isCurrentCue = (cue, currentTime) => {
  if (!cue) return false;
  const { startTime, endTime } = cue;
  return currentTime >= startTime && currentTime < endTime;
};
