import type { ComponentPropsWithRef, ReactNode } from "react";
import { forwardRef } from "react";
import { CSS } from "@dnd-kit/utilities";
import classNames from "classnames";
import { ListItemDataProvider } from "../Provider/ListItemDataProvider";
import { useSortableListData } from "../Provider/SortableListDataProvider";

export interface ListItemProps extends ComponentPropsWithRef<"li"> {
  attributes?: any;
  children?: ReactNode;
  className?: string;
  dataTestId?: string;
  isDragging?: boolean;
  isDragOverlay?: boolean;
  item: any;
  listeners?: any;
  placeholder?: any;
  transform?: any;
  transition?: string;
}

export const ListItem = forwardRef<HTMLLIElement, ListItemProps>(
  (
    {
      item,
      transform,
      transition,
      listeners,
      attributes,
      isDragging = false,
      isDragOverlay = false,
      placeholder: Placeholder,
      className,
      children,
      dataTestId = "",
      ...props
    },
    ref,
  ) => {
    const { useDragHandle: shouldUseDragHandle } = useSortableListData();

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };
    const isPlaceholder = isDragging && !isDragOverlay;
    return (
      <li
        ref={ref}
        style={style}
        className={classNames(
          "w-full min-w-0 list-none",
          {
            "cursor-auto": shouldUseDragHandle,
            "cursor-move": !shouldUseDragHandle,
          },
          className,
        )}
        {...attributes}
        {...props}
        {...(!shouldUseDragHandle && listeners)}
        data-testid={dataTestId}
      >
        <ListItemDataProvider item={item} listeners={listeners}>
          {!isPlaceholder && children ? (
            children
          ) : Placeholder ? (
            <Placeholder />
          ) : (
            <div className="h-10" />
          )}
        </ListItemDataProvider>
      </li>
    );
  },
);

ListItem.displayName = "ListItem";
