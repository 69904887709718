import PropTypes from "prop-types";
import { t } from "i18n-js";
import { Icon } from "@circle-react/components/shared/Icon";
import { Transcription as TranscriptionSkeleton } from "@circle-react-uikit/SkeletonLoader/skeletons/Transcription";
import { Typography } from "@circle-react-uikit/Typography";
import { CloseButton } from "../TranscriptPopup/CloseButton";
import { Cue } from "../TranscriptPopup/Cue";
import { FullscreenButton } from "../TranscriptPopup/FullscreenButton";
import { FullscreenView } from "../TranscriptPopup/FullscreenView";
import { isCurrentCue } from "../TranscriptPopup/helpers";
import { useShouldAutoScroll } from "../TranscriptPopup/useShouldAutoScroll";
import { useMediaTranscript } from "../useMediaTranscript";

export const Popup = ({
  toggleFullscreen,
  togglePopup,
  onSeek,
  mediaTranscriptId,
  currentTime,
  onFullscreenSeek,
  renderFullscreenPlayer,
  isFullscreen,
}) => {
  const isFullscreenEnabled = typeof renderFullscreenPlayer === "function";
  const { containerRef: cuesContainerRef, shouldAutoScroll } =
    useShouldAutoScroll();
  const {
    parsedWebvtt: { cues },
  } = useMediaTranscript(mediaTranscriptId);
  const areCuesAvailable = Boolean(cues?.length);

  return (
    <>
      <div className="bg-primary ml-2 flex h-72 w-96 flex-col rounded-md p-4 shadow-sm">
        <div className="mb-2 flex shrink-0 items-start justify-between">
          <div className="flex">
            <Icon type="16-star-ai" viewBox="0 0 20 16" className="mr-1" />
            <Typography.LabelMd as="div" color="text-dark" weight="bold">
              {t("media_player.transcript")}
            </Typography.LabelMd>
          </div>
          <div className="flex items-center gap-3">
            {isFullscreenEnabled && (
              <FullscreenButton onClick={toggleFullscreen} />
            )}
            <CloseButton onClick={togglePopup} />
          </div>
        </div>
        <div className="grow overflow-auto" ref={cuesContainerRef}>
          {!areCuesAvailable && <TranscriptionSkeleton />}
          {areCuesAvailable &&
            cues.map((cue, index) => (
              <Cue
                key={`transcript-${mediaTranscriptId}-${index}`}
                cue={cue}
                isCurrentCue={isCurrentCue(cue, currentTime)}
                shouldScroll={shouldAutoScroll}
                onSeek={onSeek}
              />
            ))}
        </div>
      </div>
      {isFullscreenEnabled && areCuesAvailable && (
        <FullscreenView
          isOpen={isFullscreen}
          onClose={toggleFullscreen}
          cues={cues}
          onSeek={onFullscreenSeek}
          currentTime={currentTime}
        >
          {renderFullscreenPlayer()}
        </FullscreenView>
      )}
    </>
  );
};

Popup.propTypes = {
  mediaTranscriptId: PropTypes.number,
  onSeek: PropTypes.func.isRequired,
  toggleFullscreen: PropTypes.func,
  togglePopup: PropTypes.func,
  renderFullscreenPlayer: PropTypes.func,
  onFullscreenSeek: PropTypes.func,
  currentTime: PropTypes.number,
  transcriptPreview: PropTypes.string,
  isFullscreen: PropTypes.bool,
};
