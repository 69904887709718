import { times, uniqueId } from "lodash";
import {
  Avatar,
  AvatarContainer,
  Row,
  RowContainer,
} from "@circle-react-uikit/SkeletonLoader/components";

export interface ListViewProps {
  count?: number;
}

export const ListView = ({ count = 1 }: ListViewProps) => (
  <div className="skeleton-loader skeleton-loader--list-view !border-primary !bg-primary !divide-y !rounded-2xl border">
    {times(count, () => (
      <div
        key={uniqueId()}
        className="skeleton-loader--list-view__item !m-0 !border-0 !bg-transparent"
      >
        <AvatarContainer md full>
          <Avatar />
          <RowContainer full>
            <div className="flex justify-between">
              <RowContainer full>
                <Row md half />
                <Row sm />
              </RowContainer>
              <div className="mr-4 flex items-center space-x-4">
                <Row square lg />
                <Row square lg />
              </div>
            </div>
          </RowContainer>
        </AvatarContainer>
      </div>
    ))}
  </div>
);
