import { t } from "i18n-js";
import { useMutation, useQuery } from "react-query";
import { mediaTranscriptApi } from "@circle-react/api";
import { useToast } from "@circle-react-uikit/ToastV2";
import { useParsedWebVTTFile } from "./useParsedWebVTTFile";

export const useMediaTranscript = mediaTranscriptId => {
  const { success, error } = useToast();
  const {
    data: mediaTranscript,
    isLoading,
    refetch,
  } = useQuery(
    ["mediaTranscript", mediaTranscriptId],
    () => mediaTranscriptApi.show(mediaTranscriptId),
    { enabled: !!mediaTranscriptId },
  );
  const { parsedWebvtt, refetch: refetchWebvtt } =
    useParsedWebVTTFile(mediaTranscript) || {};
  const {
    mutateAsync: updateAsync,
    mutate: update,
    isLoading: isUpdating,
  } = useMutation(
    formData => mediaTranscriptApi.update(mediaTranscriptId, formData),
    {
      onSuccess: () => {
        success(t("media_player.transcript_menu.success"));
        refetch();
        refetchWebvtt();
      },
      onError: responseError => {
        error(t("media_player.transcript_menu.error"));
        console.error(responseError);
      },
    },
  );
  const canEdit = mediaTranscript?.can_edit;

  return {
    mediaTranscript,
    canEdit,
    isLoading,
    isUpdating,
    parsedWebvtt,
    update,
    updateAsync,
    refetch,
  };
};
