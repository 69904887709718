import { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { flatten, uniqBy } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import { useInfiniteQuery } from "react-query";
import Masonry from "react-responsive-masonry";
import { Icon } from "@circle-react/components/shared/Icon";
import * as giphyFetch from "@circle-react/helpers/giphyFetchHelpers";
import { GifItem } from "./GifItem";
import { SearchBox } from "./SearchBox";

export const GiphyPicker = ({ close }) => {
  const LIMIT = 10;
  const [query, setQuery] = useState(null);

  const fetchGifs = ({ pageParam = 1 }) =>
    query
      ? giphyFetch.search(query, { offset: pageParam, limit: LIMIT })
      : giphyFetch.trending({ offset: pageParam, limit: LIMIT });

  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery(
    ["chat-members-search", query],
    fetchGifs,
    {
      getNextPageParam: lastPage => {
        const loadedItems =
          lastPage.pagination.offset + lastPage.pagination.count;
        const totalItems = lastPage.pagination.total_count;
        return loadedItems < totalItems
          ? lastPage.pagination.offset + LIMIT
          : null;
      },
    },
  );

  const gifs = useMemo(
    () => uniqBy(flatten(data?.pages?.map(page => page.data)), "id"),
    [data],
  );
  return (
    <div
      className="bg-primary border-primary flex w-[360px] flex-col space-y-1 rounded border shadow"
      data-testid="giphy-picker"
    >
      <SearchBox query={query} setQuery={setQuery} />
      <div className="h-[360px]">
        {!gifs.length && (
          <div className="flex h-full items-center justify-center">
            {isLoading ? (
              <Icon type="loader" />
            ) : (
              <span>{I18n.t("tiptap.no_results_found")}</span>
            )}
          </div>
        )}
        {Boolean(gifs.length) && (
          <InfiniteScroll
            dataLength={gifs.length}
            next={fetchNextPage}
            hasMore={hasNextPage}
            scrollThreshold={0.6}
            height={360}
            loader={
              <div className="col-span-2 flex justify-center py-3">
                <Icon type="loader" />
              </div>
            }
            className="mb-2 p-2"
          >
            <Masonry columnsCount={2} gutter={8}>
              {gifs.map(gif => (
                <GifItem key={gif.id} gif={gif} close={close} />
              ))}
            </Masonry>
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
};

GiphyPicker.propTypes = {
  close: PropTypes.func.isRequired,
};
