import type { UseMutationOptions, UseQueryOptions } from "react-query";
import { useMutation, useQuery } from "react-query";
import {
  reactQueryDelete,
  reactQueryGet,
} from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import type { PollResponse } from "@circle-react-shared/uikit/TipTapBlockEditor/PollExtension/PollModal/api";

export const usePoll = ({
  sgid,
  options = {},
}: {
  sgid: string;
  options: UseQueryOptions<PollResponse>;
}) =>
  useQuery({
    queryKey: ["poll", sgid],
    queryFn: () => reactQueryGet(internalApi.polls.showBySgid(sgid)),
    ...options,
  });

export const useUndoMutation = ({
  options = {},
}: {
  options?: UseMutationOptions<any, unknown, any>;
} = {}) =>
  useMutation(
    (pollId: number) => reactQueryDelete(internalApi.polls.undo(pollId)),
    options,
  );
