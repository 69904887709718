export const Radio = ({ checked }: { checked: boolean }) => {
  if (checked) {
    return (
      <div className="border-secondary bg-dark-primary-button my-0.5 flex h-5 w-5 items-center justify-center rounded-full">
        <div className="bg-primary h-2 w-2 rounded-full" />
      </div>
    );
  }
  return (
    <div className="border-primary bg-primary my-0.5 h-5 w-5 rounded-full border" />
  );
};
