import { useMemo } from "react";
import classnames from "classnames";
import { t } from "i18n-js";
import { Icon } from "@circle-react/components/shared/Icon";
import { generateRandomString } from "../helpers";
import { useRecorder, useWebsocket } from "../hooks";
import { Spinner, Timer } from "./";

export const Recorder = ({ onClose }) => {
  const recordingId = useMemo(() => generateRandomString(), []);

  const { isRecording, status, isBusy, isError, onSubmit, setServerError } =
    useRecorder({ recordingId });
  useWebsocket({ onClose, recordingId, setServerError });

  const statusLabel = t(`tiptap.voice_message.${status}`);

  return (
    <div className="bg-primary shadow-voice-message absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center rounded-lg">
      <div
        className={classnames(
          "flex items-center rounded-full bg-gray-100 p-1 text-gray-700 dark:bg-gray-900 dark:text-gray-300",
          {
            "text-red-600 dark:text-red-600": isRecording,
          },
        )}
      >
        <button
          type="submit"
          onClick={onClose}
          className="flex items-center justify-center rounded-full bg-white p-2 dark:bg-gray-600"
        >
          <Icon
            type="20-close"
            viewBox="0 0 20 20"
            className="text-icon dark:text-white"
          />
        </button>
        <div className="ml-4 mr-2 flex">
          <div className="min-w-[160px]">{statusLabel}</div>
          <div className="flex">
            <div className="mr-2 min-w-[45px]">
              <Timer isRecording={isRecording} />
            </div>
            <Icon
              type="20-microphone"
              className={classnames(
                "mt-[2px] text-gray-700 dark:text-gray-300",
                {
                  "text-red-600 dark:text-red-600": isRecording,
                },
              )}
            />
          </div>
        </div>
        <button
          type="submit"
          className="bg-brand flex items-center justify-center rounded-full p-2"
          onClick={onSubmit}
          disabled={isBusy}
        >
          {isBusy ? (
            <Spinner />
          ) : (
            <Icon
              type={isError ? "20-retry" : "20-checkmark"}
              viewBox="0 0 20 20"
              className="text-white"
            />
          )}
        </button>
      </div>
    </div>
  );
};
