import { Component } from "react";
import PropTypes from "prop-types";
import { delay } from "lodash";
import { notifyBugsnag } from "@/react/helpers/bugsnagHelpers";
import { NotFoundError } from "@circle-react/config/CustomErrors";
import { PageNotFound } from "./ErrorPages";

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMessage: "", reloadCount: 0 };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service

    //window.DISABLE_JS_ERROR_BOUNDARIES value is a string
    if (window.DISABLE_JS_ERROR_BOUNDARIES == "true") {
      throw error;
    }
    const { reloadCount } = this.state;

    this.setState({
      hasError: true,
      error,
      componentStack: errorInfo?.componentStack,
      errorMessage: error?.message,
      errorStack: error.stack,
      reloadCount: reloadCount,
    });
    console.error("ErrorBoundary Error", error, errorInfo);
    // As 404 are expected errors we don't want to notify bugsnag about them.
    if (!(error instanceof NotFoundError)) {
      notifyBugsnag(error);
    }

    const { shouldAutoReload } = this.props;
    shouldAutoReload && reloadCount < 3 && delay(this.handleReload, 3000);
  }

  handleReload = () => {
    // Reset error state and reload the component
    this.setState(prevState => ({
      hasError: false,
      errorMessage: "",
      reloadCount: prevState.reloadCount + 1,
    }));
  };

  render() {
    const { error, hasError, errorMessage } = this.state;
    const { renderFunc, handleNotFoundError, children } = this.props;
    const commonProps = {
      ...this.state,
      error: JSON.stringify(error),
    };
    if (hasError) {
      // We don't want all error boundaries to handle 404 error.
      if (handleNotFoundError && error instanceof NotFoundError) {
        return <PageNotFound errorMessage={errorMessage} {...commonProps} />;
      }
      if (renderFunc) {
        return renderFunc(commonProps);
      }
      return null;
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  renderFunc: PropTypes.func,
  handleNotFoundError: PropTypes.bool,
  children: PropTypes.node,
};

export const withErrorBoundary = (
  WrappedComponent,
  renderFunc,
  options = { handleNotFoundError: false },
) => {
  const component = props => (
    <ErrorBoundary
      renderFunc={renderFunc}
      handleNotFoundError={options.handleNotFoundError}
    >
      <WrappedComponent {...props} />
    </ErrorBoundary>
  );

  component.displayName = `withErrorBoundary(${
    WrappedComponent.displayName || WrappedComponent.name || "Component"
  })`;

  return component;
};
