import { times } from "lodash";
import {
  Avatar,
  AvatarContainer,
  Row,
  RowContainer,
} from "@circle-react-uikit/SkeletonLoader/components";
import { MembersAvatarRow } from "../MembersAvatarRow";

export interface ListViewBlockProps {
  count?: number;
}

export const ListViewBlock = ({ count = 5 }: ListViewBlockProps) => (
  <div className="border-primary bg-primary w-full rounded-2xl border p-2">
    {times(count, index => (
      <div key={index} className="flex p-4">
        <AvatarContainer md full>
          <Avatar />
          <RowContainer full>
            <div className="flex justify-between">
              <RowContainer full>
                <Row md half />
                <Row sm />
              </RowContainer>
              <div className="mr-4 flex items-center space-x-8">
                <MembersAvatarRow />
                <Row lg className="!h-6" />
              </div>
            </div>
          </RowContainer>
        </AvatarContainer>
      </div>
    ))}
  </div>
);
