import type { ElementType, ReactNode } from "react";
import { Menu } from "@headlessui/react";

export interface ItemProps {
  children: ReactNode;
  onClick?: () => void;
  as?: ElementType;
}

export const Item = ({ onClick, children, as }: ItemProps) => (
  <Menu.Item as={as} onClick={onClick}>
    {children}
  </Menu.Item>
);
