import PropTypes from "prop-types";
import { Icon } from "@circle-react/components/shared/Icon";

export const DownloadButton = ({ url }) => (
  <button
    type="submit"
    className="from-primary pointer-events-none absolute right-0 -my-2 hidden h-full items-center rounded-md bg-gradient-to-l px-1 pl-36 group-hover:flex"
  >
    <a
      className="bg-primary pointer-events-auto p-2"
      href={url}
      download
      target="_blank"
      rel="noreferrer"
    >
      <Icon type="download" />
    </a>
  </button>
);

DownloadButton.propTypes = {
  url: PropTypes.string.isRequired,
};
