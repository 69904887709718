import PropTypes from "prop-types";
import { times, uniqueId } from "lodash";
import {
  Avatar,
  AvatarContainer,
  Row,
  RowContainer,
} from "@circle-react-uikit/SkeletonLoader/components";

const renderAvatarContainer = () => (
  <div key={uniqueId()} className="skeleton-loader--right-sidebar__item">
    <AvatarContainer>
      <Avatar />
      <RowContainer>
        <Row />
        <Row xs half />
      </RowContainer>
    </AvatarContainer>
  </div>
);

export const RightSidebar = ({ count = 1 }) => (
  <div className="sidebar__members">
    <div className="sidebar__block sidebar__right block">
      <div className="skeleton-loader skeleton-loader--right-sidebar">
        {times(3, renderAvatarContainer)}
      </div>
    </div>

    <div className="sidebar__block sidebar__right block">
      <div className="skeleton-loader skeleton-loader--right-sidebar">
        {times(count, renderAvatarContainer)}
      </div>
    </div>
  </div>
);

RightSidebar.propTypes = {
  count: PropTypes.number,
};
