import PropTypes from "prop-types";
import { times } from "lodash";
import { MemberCard } from "@circle-react-uikit/SkeletonLoader/skeletons/MemberCard";

export const SpaceMemberListPage = ({ count = 12 }) => (
  <div className="space__members-listing">
    {times(count, i => (
      <MemberCard key={i} />
    ))}
  </div>
);

SpaceMemberListPage.propTypes = {
  count: PropTypes.number,
};
