import { useLocation } from "react-router-dom";
import { useLessons } from "@/react/components/Spaces/CourseSpace/Lessons/Edit/hooks/useLessons";
import { useCurrentSpaceContext } from "@circle-react/contexts";

export const useOpenChatRoomUuid = () => {
  const { pathname } = useLocation();
  const isMessagingPage = pathname.includes("/messages");
  const { data: currentSpace } = useCurrentSpaceContext();
  const { currentLesson = {} } = useLessons({ forEdition: false });

  const chatRoomUuid = isMessagingPage
    ? window.location.pathname?.split("messages/")[1]
    : currentSpace?.chat_room_uuid || currentLesson?.chat_room_uuid;

  return { chatRoomUuid };
};
