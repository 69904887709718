import PropTypes from "prop-types";
import { TranscriptPopupPreview } from "@circle-react-shared/uikit/TranscriptsPlayer/TranscriptPopupPreview";

export const AudioTranscriptions = ({
  transcriptionId,
  transcriptionPreview,
  audioPlayer,
}) => {
  const onSeek = time => {
    audioPlayer.currentTime = time;
    audioPlayer.play();
  };

  return (
    <div className="mt-2 flex">
      <div className="mr-2 h-auto w-1 rounded bg-gray-300"></div>
      <TranscriptPopupPreview
        mediaTranscriptId={transcriptionId}
        onSeek={onSeek}
        transcriptPreview={transcriptionPreview}
      />
    </div>
  );
};

AudioTranscriptions.propTypes = {
  transcriptionId: PropTypes.number.isRequired,
  transcriptionPreview: PropTypes.string.isRequired,
  audioPlayer: PropTypes.instanceOf(HTMLAudioElement).isRequired,
};
