import type { ElementType } from "react";
import { Switch as SwitchComponent } from "@headlessui/react";
import classnames from "classnames";
import noop from "lodash/noop";
import { Icon } from "@circle-react-shared/Icon";

export interface SwitchProps {
  as?: ElementType<any>;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
}

export const Switch = ({
  as = "button",
  checked = false,
  onChange = noop,
  disabled = false,
}: SwitchProps) => (
  <SwitchComponent
    as={as}
    checked={checked}
    onChange={onChange}
    disabled={disabled}
    className={classnames(
      "disabled:bg-disabled relative mb-0 flex h-4 w-6 items-center rounded-full transition-colors disabled:cursor-not-allowed",
      {
        "bg-toggle-checked": checked,
        "bg-toggle-unchecked": !checked,
      },
    )}
  >
    <span
      className={classnames(
        "text-darkest inline-block h-2.5 w-2.5 rounded-full transition-transform",
        {
          "bg-toggle-circle-checked translate-x-2.5": checked,
          "bg-toggle-circle-unchecked translate-x-1": !checked,
        },
      )}
    >
      <Icon
        type="10-circle-check"
        size={10}
        style={{ width: "auto", display: "block" }}
        className={classnames("text-selector-active transition-opacity", {
          "opacity-30": checked && disabled,
          "opacity-100": checked && !disabled,
          "opacity-0": !checked,
        })}
        useWithStrokeCurrentColor
      />
    </span>
  </SwitchComponent>
);
