import {
  Avatar,
  AvatarContainer,
  Row,
  RowContainer,
} from "@circle-react-uikit/SkeletonLoader/components";

export const ChatRoomSidebar = () => (
  <div className="mt-6">
    <RowContainer lg full>
      {Array.from(Array(3).keys()).map(index => (
        <div key={index}>
          <div className="px-6 py-3">
            <AvatarContainer md full>
              <Avatar />
              <RowContainer full>
                <Row md half />
                <Row md full />
              </RowContainer>
            </AvatarContainer>
          </div>
        </div>
      ))}
    </RowContainer>
  </div>
);
