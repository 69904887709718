import type { MouseEvent } from "react";
import { usePunditUserContext } from "@circle-react/contexts";
import { Button } from "@circle-react-uikit/Button";
import "./styles.scss";

export interface CoverImageOverlayProps {
  changeButtonText?: string;
  removeButtonText?: string;
  onChange?: (event: MouseEvent<HTMLButtonElement>) => void;
  onDelete?: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const CoverImageOverlay = ({
  onChange,
  onDelete,
  changeButtonText,
  removeButtonText,
}: CoverImageOverlayProps) => {
  const { isViewOnlyMasquerading } = usePunditUserContext();
  return (
    <div
      className="cover-image-overlay hover:opacity-100"
      data-testid="cover-image-overlay"
    >
      <Button
        variant="secondary"
        onClick={onChange}
        disabled={isViewOnlyMasquerading}
      >
        {changeButtonText}
      </Button>
      <Button
        variant="secondary"
        onClick={onDelete}
        type="button"
        disabled={isViewOnlyMasquerading}
      >
        {removeButtonText}
      </Button>
    </div>
  );
};
