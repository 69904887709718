import Underline from "@tiptap/extension-underline";

export const CustomizedUnderline = Underline.extend({
  // This is used to make sure that when users paste content from other sources, the underline tag is preserved
  // only if the pasted HTML contains the underline tag. This change is made so that we don't preserve underline
  // if the source uses inline styles to underline links (<a style="text-decoration: underline">). For eg: Google Docs.
  parseHTML() {
    return [
      {
        tag: "u",
      },
    ];
  },
});
