import { useMemo } from "react";
import classNames from "classnames";
import isFunction from "lodash/isFunction";
import { Icon } from "@circle-react-shared/Icon";
import type { IconType } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-uikit/Button";
import type { ButtonVariant } from "@circle-react-uikit/Button";
import { Dropdown } from "@circle-react-uikit/Dropdown";

export interface Option {
  label: string;
  value: string;
  icon: IconType;
  onClick?: () => void;
}

export interface ActionSelectorButtonProps {
  variant?: ButtonVariant;
  options: Option[];
  selectedAction?: Option;
  setSelectedAction: (action?: Option) => void;
  onChange?: (action?: Option) => void;
  disabled: boolean;
  type: "button" | "submit" | "reset";
}

export const ActionSelectorButton = ({
  variant,
  options,
  selectedAction,
  setSelectedAction,
  onChange,
  disabled,
  type = "button",
}: ActionSelectorButtonProps) => {
  const selectedOption = selectedAction || options[0];

  const optionsWithoutSelected = useMemo(
    () => options.filter(opt => opt.value !== selectedOption.value),
    [options, selectedOption],
  );

  return (
    <div className="flex items-center">
      <Button
        type={type}
        variant={variant}
        className="truncate rounded-r-sm"
        disabled={disabled}
        data-testid="perform-action-button"
        tabIndex={0}
        onClick={() => {
          if (isFunction(selectedOption.onClick)) {
            selectedOption.onClick();
          }
        }}
      >
        {selectedOption.label}
      </Button>
      <Dropdown
        direction="top-end"
        buttonType="button"
        linkType="button"
        buttonWrapperClassName={classNames({
          "pointer-events-none": disabled,
        })}
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 8],
              },
            },
          ],
        }}
        button={
          <div className="ml-0.5">
            <Button
              type="button"
              variant={variant}
              className="rounded-l-sm !px-2.5"
              disabled={disabled}
              data-testid="select-action-button"
            >
              <Icon type="16-chevron-down" size={16} className="mb-[1px]" />
            </Button>
          </div>
        }
        options={optionsWithoutSelected}
        onChange={value => {
          const action = options.find(option => option.value === value);
          setSelectedAction(action);
          if (isFunction(onChange)) onChange(action);
        }}
      />
    </div>
  );
};
