import type { RendererProps } from "@circle-react-uikit/ImageInput/ImageSelector";
import { CoverImageInputButton } from "./CoverImageInputButton";
import { CoverImageInputPanel } from "./CoverImageInputPanel";

export const DefaultCoverRenderer = (props: RendererProps) => {
  const { value, processing: isProcessing } = props;

  if (!value && !isProcessing) {
    return <CoverImageInputButton {...props} />;
  }
  return <CoverImageInputPanel {...props} />;
};
