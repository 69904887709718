import { Node } from "@tiptap/core";
import {
  ReactNodeViewRenderer,
  ReactRenderer,
  mergeAttributes,
} from "@tiptap/react";
import { Plugin, PluginKey } from "prosemirror-state";
import { insertNodeAtRange } from "../utilities";
import { ChatMessageDisplay } from "./ChatMessageDisplay";
import { ChatMessagePaste } from "./ChatMessagePaste";

export const ChatMessageLinkExtension = () =>
  Node.create({
    name: "message",
    group: "inline",
    inline: true,
    selectable: false,
    atom: true,
    priority: 1200,
    addAttributes: () => ({
      sgid: {
        default: null,
        parseHTML: element => element.getAttribute("data-sgid"),
        renderHTML: attributes =>
          attributes.sgid
            ? {
                "data-sgid": attributes.sgid,
              }
            : {},
      },
    }),
    parseHTML: () => [{ tag: "chat-message-react" }],
    renderHTML: ({ HTMLAttributes }) => [
      "chat-message-react",
      mergeAttributes(HTMLAttributes),
    ],
    addNodeView() {
      return ReactNodeViewRenderer(ChatMessageDisplay);
    },
    addProseMirrorPlugins() {
      const editor = this.editor;
      const name = this.name;
      return [
        new Plugin({
          key: new PluginKey("chatMessageLinkPaste"),
          props: {
            handlePaste: (view: any, event: any) => {
              const pastedData = event.clipboardData.getData("text/plain");

              const match = pastedData.includes("#message_");

              if (!match) return false;

              const href = pastedData;
              const range = view.state.selection.ranges[0].$from.pos;

              editor
                .chain()
                .focus()
                .insertContentAt(range, {
                  type: "text",
                  text: href,
                  marks: [
                    {
                      type: "link",
                      attrs: { href, target: "_blank" },
                    },
                  ],
                })
                .run();

              const reactRenderer = new ReactRenderer(ChatMessagePaste, {
                props: {
                  href,
                  command: (sgid: any) => {
                    insertNodeAtRange({
                      editor,
                      range: { from: range, to: range + href.length },
                      name,
                      attrs: { sgid },
                    });

                    reactRenderer.destroy();
                  },
                },
                editor,
              });

              return true;
            },
          },
        }),
      ];
    },
  });
