import { t } from "i18n-js";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";

export interface CloseButtonProps {
  onClick: () => void;
}

const label = t("close");

export const CloseButton = ({ onClick }: CloseButtonProps) => (
  <TippyV2 content={label} interactive={false}>
    <IconButton
      name="close"
      variant="secondary"
      onClick={onClick}
      ariaLabel={label}
    />
  </TippyV2>
);
