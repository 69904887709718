import { Transition } from "@headlessui/react";
import { t } from "i18n-js";
import { Icon } from "@circle-react-shared/Icon";
import type { RendererProps } from "@circle-react-uikit/ImageInput/ImageSelector";

export interface CoverImageInputButtonProps extends RendererProps {}

export const CoverImageInputButton = ({
  handleChangeCoverClick,
  showAddCoverBtn,
  customAddCoverBtn,
}: CoverImageInputButtonProps) => (
  <Transition
    show={!!showAddCoverBtn}
    enter="transition-opacity duration-300"
    enterFrom="opacity-0"
    enterTo="opacity-75"
    leave="transition-opacity duration-300"
    leaveFrom="opacity-75"
    leaveTo="opacity-0"
  >
    {/* eslint-disable-next-line jsx-a11y/prefer-tag-over-role -- This component is implemented different in many places, we need to refactor it with a render function  */}
    <div
      role="button"
      onClick={handleChangeCoverClick}
      onKeyDown={handleChangeCoverClick}
      tabIndex={0}
    >
      {customAddCoverBtn ? (
        customAddCoverBtn
      ) : (
        <button type="button" className="form-section__add-cover">
          <Icon type="toolbar-image" className="no-fill" />
          {t("add_cover")}
        </button>
      )}
    </div>
  </Transition>
);
