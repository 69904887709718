import { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import InnerHTML from "dangerously-set-html-content";
import { useHistory } from "react-router-dom";
import { TrixContentAttachmentPreviewHelperService } from "@/src/TrixContentAttachmentPreviewHelperService";
import {
  linkifyTrixContent,
  makeAllLinksOpenInNewWindow,
  setNoViewDownload,
  setupAttachmentDownload,
  transformDataBackgroundImage,
} from "@circle-react/helpers/actionTextHelpers";
import { useIsIframeEmbed } from "@circle-react/hooks/useIsIframeEmbed";
import { AttachmentPreview } from "@circle-react-shared/AttachmentPreview";
import "./styles.scss";

export const DisplayTrixContent = ({
  content,
  disableFullScreenPreview = false,
  contentParentPath,
  transformLinks = makeAllLinksOpenInNewWindow,
}) => {
  const history = useHistory();
  const ref = useRef(null);
  const [showAttachmentPreview, setShowAttachmentPreview] = useState(false);
  const attachmentPreviewPropRef = useRef(null);
  const isIframeEmbed = useIsIframeEmbed();

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    const trixContentElement = ref.current.querySelector(".trix-content");
    setNoViewDownload(trixContentElement);
    transformLinks({
      containerElement: trixContentElement,
      shouldIncludeInternalLinks: isIframeEmbed,
    });
    transformDataBackgroundImage(trixContentElement);
    setupAttachmentDownload(trixContentElement);
    const openPreviewModal = ({ attachments, currentIndex }) => {
      if (disableFullScreenPreview) {
        history.push(contentParentPath);
      } else {
        attachmentPreviewPropRef.current = { attachments, currentIndex };
        setShowAttachmentPreview(true);
      }
    };
    const trixContentAttachmentPreviewHelperService =
      new TrixContentAttachmentPreviewHelperService(ref.current);
    trixContentAttachmentPreviewHelperService.addAttachmentClickEventHandler(
      openPreviewModal,
    );
  }, [
    ref,
    isIframeEmbed,
    contentParentPath,
    disableFullScreenPreview,
    history,
  ]);

  useEffect(() => {
    if (content) {
      window.loadOembed();
    }
  }, [content]);

  const handleClosePreview = () => {
    attachmentPreviewPropRef.current = null;
    setShowAttachmentPreview(false);
  };
  const formattedContent = useMemo(
    () => linkifyTrixContent(content),
    [content],
  );

  return (
    <div
      className={classnames("react-trix-content text-dark", {
        "fullscreen-preview-enabled": !disableFullScreenPreview,
      })}
      ref={ref}
    >
      {showAttachmentPreview && (
        <AttachmentPreview
          {...attachmentPreviewPropRef.current}
          onClose={handleClosePreview}
          show
        />
      )}
      <InnerHTML html={formattedContent} />
    </div>
  );
};

DisplayTrixContent.propTypes = {
  content: PropTypes.string.isRequired,
  disableFullScreenPreview: PropTypes.bool,
  contentParentPath: PropTypes.string,
  transformLinks: PropTypes.func,
};
