import { useQuery } from "react-query";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

export const useMentions = ({ query, scope = "space", spaceId }: any) => {
  const fetchEntityItems = () =>
    reactQueryGet(
      internalApi.searchV2.advanced.index({
        params: {
          query,
          per_page: 20,
          page: 1,
          type: "mentions",
          mention_scope: scope,
          ...(spaceId && { space_id: spaceId }),
        },
      }),
    );

  const { data: items = [], isLoading } = useQuery(
    ["entities", query],
    fetchEntityItems,
    {
      select: ({ records }: any) =>
        records.map((record: any) => ({ ...record, label: record.name })),
      enabled: !!query,
    },
  );

  return { items, isLoading };
};
