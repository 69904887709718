import PropTypes from "prop-types";
import classNames from "classnames";

export const Content = ({ className, children }) => (
  <span className={classNames("truncate", className)}>{children}</span>
);

Content.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
