import {
  Row,
  RowContainer,
} from "@circle-react-uikit/SkeletonLoader/components";

export const SpaceHeader = () => (
  <div className="skeleton-loader skeleton-loader--space-header">
    <div className="skeleton-loader--space-header__container">
      <div className="skeleton-loader--space-header__title">
        <RowContainer inline full>
          <Row xxl short />
          <Row xxl almostFull />
        </RowContainer>
      </div>
      <div className="skeleton-loader--space-header__actions">
        <RowContainer inline>
          <Row xl short />
          <Row xl short />
          <Row xl short />
          <Row xxl long />
        </RowContainer>
      </div>
    </div>
    <div className="skeleton-loader--space-header__container">
      <RowContainer inline>
        <Row xxl long />
        <Row xxl long />
      </RowContainer>
      <div className="skeleton-loader--space-header__actions">
        <RowContainer inline>
          <Row xxl long />
        </RowContainer>
      </div>
    </div>
  </div>
);
