import type { Editor } from "@tiptap/react";
import classNames from "classnames";
import { useSmOrMdScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { getCurrentRange } from "@circle-react-shared/uikit/TipTapBlockEditor/Toolbar/helpers";
import { AlignmentButton } from "./AlignmentButton";
import { useCTASettingsModal } from "./CTASettingsModal";

interface CTAOptionsProps {
  editor: Editor;
  node: any;
  updateAttributes: any;
  deleteNode: () => void;
}

export const CTAOptions = ({
  editor,
  node,
  updateAttributes,
  deleteNode,
}: CTAOptionsProps) => {
  const ctaSettingsModal = useCTASettingsModal();
  const isMobile = useSmOrMdScreenMediaQuery();

  return (
    <div
      className={classNames(
        "absolute right-0 top-0 flex w-full -translate-y-full items-center justify-end pb-1 group-hover:visible",
        {
          invisible: !isMobile,
        },
      )}
    >
      <div className="bg-text-editor-modifier flex overflow-hidden rounded-lg p-1 text-sm">
        <div className="flex gap-x-1">
          <AlignmentButton
            onClick={() => {
              updateAttributes({
                alignment: "left",
                full_width: false,
              });
            }}
            isActive={node.attrs.alignment == "left"}
            iconType="16-image-left"
          />
          <AlignmentButton
            onClick={() => {
              updateAttributes({
                alignment: "center",
                full_width: false,
              });
            }}
            isActive={
              node.attrs.alignment == "center" && !node.attrs.full_width
            }
            iconType="16-image-centre"
          />
          <AlignmentButton
            onClick={() => {
              updateAttributes({
                alignment: "center",
                full_width: true,
              });
            }}
            isActive={node.attrs.alignment == "center" && node.attrs.full_width}
            iconType="16-image-full-width"
          />
          <AlignmentButton
            onClick={() => {
              updateAttributes({
                alignment: "right",
                full_width: false,
              });
            }}
            isActive={node.attrs.alignment == "right"}
            iconType="16-image-right"
          />
        </div>
        <span
          className="m-1 inline-block w-px self-stretch bg-gray-500"
          aria-hidden
        />
        <div className="flex gap-x-1">
          <AlignmentButton
            iconType="16-edit"
            onClick={() =>
              ctaSettingsModal.show({
                editor,
                range: getCurrentRange({ editor }),
              })
            }
          />
          <AlignmentButton onClick={deleteNode} iconType="16-trash" />
        </div>
      </div>
    </div>
  );
};
