import { forwardRef } from "react";
import classNames from "classnames";
import { Controller, useFormContext } from "react-hook-form";
import { TrixEditor } from "@circle-react-shared/TrixEditor";
import type {
  TrixEditorProps,
  TrixEditorRefObject,
} from "@circle-react-shared/TrixEditor";

/** @deprecated Please use TipTap editor instead for new components */
export const RichTextInput = forwardRef<TrixEditorRefObject, TrixEditorProps>(
  (props, ref) => {
    const { name, className = "", textboxClassName = "" } = props;
    const { control } = useFormContext();

    return (
      <div className={classNames("react-rich-text-input", className)}>
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => (
            <TrixEditor
              {...props}
              className={textboxClassName}
              onChange={(event: any) => onChange(event.target.innerHTML)}
              value={value}
              ref={ref}
            />
          )}
        />
      </div>
    );
  },
);
RichTextInput.displayName = "RichTextInputControlledInput";
