import {
  Children,
  forwardRef,
  isValidElement,
  useEffect,
  useMemo,
  useState,
} from "react";
import classNames from "classnames";
import isFunction from "lodash/isFunction";
import isString from "lodash/isString";
import { Tab } from "./Tab";
import "./styles.scss";

const getComparableTab = (tab: any) =>
  isString(tab) ? tab : tab?.type?.displayName;

/** @deprecated Please use the new Tab at "@circle-react-uikit/TabV2" */
export const Tabs = forwardRef<HTMLDivElement, any>(
  (
    {
      controlled = false,
      children,
      value,
      defaultValue,
      onChange,
      activeTab: activeTabProp,
      onClick,
      wrapperClassName,
      options,
      variant = "default",
      mobile = false,
      tabClassName = "",
      className = "",
    },
    ref,
    // eslint-disable-next-line sonarjs/cognitive-complexity -- Disabled to set CI to fail on this issue on new files, PR #6718
  ) => {
    const firstLabel = useMemo(
      () => (children ? children[0]?.props.label : null),
      [children],
    );
    const initialValue = value || defaultValue || firstLabel;
    const [internalActiveTab, setInternalActiveTab] = useState(
      !controlled ? activeTabProp : initialValue,
    );

    useEffect(() => {
      if (defaultValue && !controlled && !internalActiveTab) {
        setInternalActiveTab(defaultValue);
      }
    }, [defaultValue, controlled]);

    if (controlled && !activeTabProp) {
      throw new Error(
        "Tabs are in controlled mode and activeTab prop isn't passed.",
      );
    }

    const onClickTabItem = (tab: any) => {
      if (!controlled) setInternalActiveTab(tab);
      if (isFunction(onClick)) {
        onClick(tab);
      }
      isFunction(onChange) && onChange(tab);
    };

    useEffect(() => {
      if (!controlled && value !== activeTabProp) {
        setInternalActiveTab(value);
      }
    }, [value, controlled]);

    const activeTab = controlled ? activeTabProp : internalActiveTab;
    return (
      <div
        ref={ref}
        className={classNames(
          "react-tabs",
          className,
          `react-tabs--${String(variant)}`,
          {
            "react-tabs--mobile": mobile,
          },
        )}
      >
        <div className="react-tabs__tabs">
          <nav
            className={classNames("react-tabs__nav", wrapperClassName)}
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role -- Disabled because this is a deprecated component
            role="tablist"
          >
            {!!options &&
              options.map((option: any) => (
                <Tab
                  data-testid={`tab-${String(option.label)}`}
                  className={tabClassName}
                  active={activeTab === option.label}
                  key={option.label}
                  label={option.label}
                  badge={option.badge}
                  onClick={onClickTabItem}
                  variant={variant}
                  {...option}
                />
              ))}
            {!!children &&
              children.map((child: any) => {
                const { label, onClick } = child.props;
                const labelToCompare = getComparableTab(label);

                const onChildClick = () => {
                  onClickTabItem(labelToCompare);
                  onClick?.();
                };

                return (
                  <Tab
                    className={tabClassName}
                    active={activeTab === labelToCompare}
                    key={labelToCompare}
                    label={label}
                    onClick={onChildClick}
                    variant={variant}
                  />
                );
              })}
          </nav>
        </div>
        {children && (
          <div className="react-tabs__tab-content">
            {Children.map(children, child => {
              const isActive = () =>
                getComparableTab(child.props.label) === activeTab;
              if (!isValidElement(child)) return null;
              if (!isActive()) return null;
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- Hard to fix type issue, disabling because this component is deprecated
              // @ts-ignore-next-line
              return child.props.children;
            })}
          </div>
        )}
      </div>
    );
  },
);

Tabs.displayName = "Tabs";
