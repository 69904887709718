import type { ReactNode } from "react";
import { Tab as HeadlessUITab } from "@headlessui/react";
import classNames from "classnames";

interface PanelProps {
  children: ReactNode;
  className?: string;
}

export const Panel = ({ children, className = "", ...props }: PanelProps) => (
  <HeadlessUITab.Panel
    as="div"
    data-testid="tab-panel"
    className={classNames("h-full w-full overflow-auto", className)}
    {...props}
  >
    {children}
  </HeadlessUITab.Panel>
);

Panel.displayName = "Tab.Panel";
