import PropTypes from "prop-types";
import { DndContext, SortableListDataProvider } from "../Provider";

export const Container = ({
  children,
  shouldUseKeyboardSensor = true,
  ...rest
}) => (
  <SortableListDataProvider {...rest}>
    <DndContext shouldUseKeyboardSensor={shouldUseKeyboardSensor}>
      {children}
    </DndContext>
  </SortableListDataProvider>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
  shouldUseKeyboardSensor: PropTypes.bool,
};
