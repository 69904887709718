import { t } from "i18n-js";
import { SanitizeHTMLString } from "@circle-react/components/shared/uikit/SanitizeContent";
import { Icon } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-uikit/Button";
import { CoverImageInput } from "@circle-react-uikit/CoverImageInput";
import { Form } from "@circle-react-uikit/Form";

const translationRoot = "media_settings";

interface ThumbnailButtonMenuProps {
  mediaSettings: {
    id: string;
    custom_thumbnail_signed_id?: string;
    poster_url?: string;
  };
  updateMediaSettings: (values: any) => void;
  closePopover: () => void;
  isUpdatingMediaSettings: boolean;
}

export const ThumbnailButtonMenu = ({
  mediaSettings,
  updateMediaSettings,
  isUpdatingMediaSettings,
  closePopover,
}: ThumbnailButtonMenuProps) => {
  const onAddThumbnailImage = (formValues: any) => {
    updateMediaSettings({ ...formValues, id: mediaSettings.id });
    closePopover();
  };

  return (
    <Form
      id="thumbnail-image-settings"
      onSubmit={onAddThumbnailImage}
      shouldStopPropagation
      defaultValues={{
        custom_thumbnail: mediaSettings.custom_thumbnail_signed_id,
      }}
    >
      <Form.Item
        name="custom_thumbnail"
        translationRoot={translationRoot}
        hideBorder
        fullWidth
        hideDescription
        className="!p-0"
      >
        <CoverImageInput
          name="custom_thumbnail"
          aspectRatio={16 / 9}
          hideUnsplash
          showAddCoverBtn
          changeImageText={t("replace")}
          title={t("media_settings.thumbnail_title")}
          customAddCoverBtn={
            <div className="bg-primary border-primary text-dark my-2 flex w-full cursor-pointer flex-col items-center justify-center rounded-lg border border-dashed px-4 py-5 text-center">
              <div className="bg-tertiary flex h-8 w-8 items-center justify-center rounded-full">
                <Icon type="plus" viewBox="0 0 20 20" />
              </div>
              <span className="mt-0.5 text-xs">
                <SanitizeHTMLString
                  content={t("media_settings.image_upload_description")}
                />
              </span>
            </div>
          }
        />
      </Form.Item>
      <div className="flex justify-end">
        <Button
          type="submit"
          variant="circle"
          disabled={isUpdatingMediaSettings}
          className="mt-3"
        >
          {t("save")}
        </Button>
      </div>
    </Form>
  );
};
