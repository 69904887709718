import classNames from "classnames";
import { times, uniqueId } from "lodash";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { Row } from "@circle-react-uikit/SkeletonLoader/components";

export interface CommunitySwitcherProps {
  count?: number;
}

export const CommunitySwitcherSkeleton = ({
  count = 5,
}: CommunitySwitcherProps) => {
  const { isV3Enabled } = useIsV3();
  return (
    <div
      data-testid="community-switcher-skeleton"
      className={classNames(
        "border-primary bg-primary flex flex-col gap-4 overflow-y-auto border-r",
        {
          "fixed left-0 top-12 h-full w-16 p-3": !isV3Enabled,
          "flex h-screen w-full max-w-[4.5rem] items-center p-4": isV3Enabled,
        },
      )}
    >
      {times(count, () => (
        <Row key={uniqueId()} squareExtraLarge />
      ))}
    </div>
  );
};
