import { forwardRef } from "react";
import { Icon } from "@circle-react-shared/Icon";
import type { IconType } from "@circle-react-shared/Icon";

interface ButtonProps {
  iconType: IconType;
  onClick: () => void;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ iconType, onClick }, ref) => (
    <button
      ref={ref}
      type="button"
      onClick={onClick}
      className="bg-primary text-dark border-light hover:bg-secondary flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border"
    >
      <Icon type={iconType} size={16} />
    </button>
  ),
);

Button.displayName = "Button";

export { Button };
