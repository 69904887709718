import PropTypes from "prop-types";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { Tippy } from "@circle-react/components/shared/Tippy";
import { Content } from "./Content";

const wrapperClassName = "flex min-w-0";

export const TruncateText = ({ className, tooltipOptions, children }) => {
  if (!isEmpty(tooltipOptions) && !isEmpty(tooltipOptions?.content)) {
    return (
      <Tippy
        {...tooltipOptions}
        className={classNames(wrapperClassName, tooltipOptions?.className)}
      >
        <Content>{children}</Content>
      </Tippy>
    );
  }

  return (
    <span className={classNames(wrapperClassName, className)}>
      <Content>{children}</Content>
    </span>
  );
};

TruncateText.propTypes = {
  className: PropTypes.string,
  tooltipOptions: PropTypes.object,
  children: PropTypes.node,
};
