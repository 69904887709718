import { t } from "i18n-js";
import { useThemeContext } from "@circle-react/providers";
import type { MemberTag as MemberTagProps } from "@circle-react/types";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { BadgeV2 } from "../BadgeV2";
import { MemberTag } from "./MemberTag";

interface ShowMoreTagProps {
  memberTags: MemberTagProps[];
  labelVariant?: "words" | "numbers";
}
export const ShowMoreTag = ({
  memberTags,
  labelVariant = "words",
}: ShowMoreTagProps) => {
  const { currentAppearance } = useThemeContext();

  const tooltipContent = (
    <ul className="flex !list-none flex-col gap-1 py-1">
      {memberTags.map((memberTag: MemberTagProps) => (
        <li key={memberTag.id}>
          <MemberTag
            memberTag={{
              ...memberTag,
            }}
            className="border-secondary"
          />
        </li>
      ))}
    </ul>
  );

  const label =
    labelVariant === "words"
      ? t("member_tags.show_more", {
          count: memberTags.length,
        })
      : `+${memberTags.length}`;

  return (
    <div>
      <TippyV2
        content={tooltipContent}
        className="flex"
        theme={currentAppearance}
      >
        <BadgeV2 label={label} />
      </TippyV2>
    </div>
  );
};
