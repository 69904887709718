import type { UseMutationOptions } from "react-query";
import { useMutation } from "react-query";
import {
  reactQueryPost,
  reactQueryPut,
} from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

export interface PollPayload {
  title: string;
  allow_members_to_view_results: boolean;
  closes_at?: string;
  poll_options: {
    value: string;
  };
}

export interface PollOptionType {
  id: number;
  value: string;
}

export interface PollResults {
  total_responses: number;
  detailed_results?: {
    responses: number;
    percentage: number;
    poll_option_id: number;
  }[];
}

export interface PollResponse {
  id: number;
  title: string;
  allow_members_to_view_results: boolean;
  closes_at?: string;
  poll_options: PollOptionType[];
  sgid: string;
  community_member_id: number;
  created_at: string;
  updated_at: string;
  community_member_response_option_id?: number;
  results?: PollResults;
  status: "draft" | "active" | "inactive" | "closed";
}

export const useCreatePoll = (
  options: UseMutationOptions<PollResponse, unknown, PollPayload> = {},
) =>
  useMutation(
    (poll: PollPayload) => reactQueryPost(internalApi.polls.create(), poll),
    options,
  );

interface EditPollPayload extends PollPayload {
  id: number;
}

export const useEditPoll = (
  options: UseMutationOptions<PollResponse, unknown, PollPayload> = {},
) =>
  useMutation(
    (poll: EditPollPayload) =>
      reactQueryPut(internalApi.polls.update(poll.id), poll),
    options,
  );
