import PropTypes from "prop-types";
import { useTipTapEditorContext } from "../index";
import { supportsGalleryPreview } from "../utilities/contentTypeParser";
import { AttachmentItem } from "./AttachmentItem";

export const AttachmentPreviews = ({ attachmentsList, linkMarks }) => {
  const { editor } = useTipTapEditorContext();
  if (!attachmentsList.length) {
    return null;
  }

  const onlyOneAttachment = attachmentsList.length === 1;
  const noLinkPreviews = linkMarks.length === 0;

  const attachmentsForGalleryPreview = attachmentsList.filter(a =>
    supportsGalleryPreview(a.content_type),
  );

  return (
    <div className="flex items-center gap-2">
      {attachmentsList.map((attachment, index) => (
        <AttachmentItem
          index={index}
          attachmentsForGalleryPreview={attachmentsForGalleryPreview}
          key={attachment.filename}
          attachment={attachment}
          shouldShowEnlarged={
            !editor.isEditable && onlyOneAttachment && noLinkPreviews
          }
        />
      ))}
    </div>
  );
};

AttachmentPreviews.propTypes = {
  attachmentsList: PropTypes.array.isRequired,
  linkMarks: PropTypes.array.isRequired,
};
