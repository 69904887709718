import { Node } from "@tiptap/core";
import { ReactNodeViewRenderer, mergeAttributes } from "@tiptap/react";
import { EmbedPreview } from "./EmbedPreview";

export const Embeds = Node.create({
  name: "embed",
  group: "block",
  atom: true,
  addAttributes: () => ({
    sgid: {
      default: null,
      parseHTML: element => element.getAttribute("sgid"),
      renderHTML: attributes =>
        attributes.sgid
          ? {
              sgid: attributes.sgid,
            }
          : {},
    },
  }),
  parseHTML: () => [{ tag: "embed-react" }],
  renderHTML: ({ HTMLAttributes }) => [
    "embed-react",
    mergeAttributes(HTMLAttributes),
  ],
  addNodeView() {
    return ReactNodeViewRenderer(EmbedPreview);
  },
});
