import { sanitizeLinkMarks } from "./getMarks";

const isExternalVideoBlock = block => block.type === "external-video";

const removeUnsupportedBlocks = ({ editor }) => {
  const state = editor.getJSON();
  if (state.content.some(isExternalVideoBlock)) {
    const newState = {
      ...state,
      content: state.content.filter(block => !isExternalVideoBlock(block)),
    };
    editor.commands.setContent(newState);
  }
};

export const sanitizeContent = ({ editor }) => {
  if (editor.isEmpty) return;
  removeUnsupportedBlocks({ editor });
  sanitizeLinkMarks({ editor });
};

export const sanitizeInputJson = inputJson => {
  try {
    if (!inputJson) return;
    const { content } = inputJson;
    const newContent = content.map(item => {
      if (item.type == "paragraph") {
        const contents = item.content?.filter(
          block => !(block.type === "text" && block.text === ""),
        );
        return {
          ...item,
          content: contents,
        };
      }
      return item;
    });

    return {
      ...inputJson,
      content: newContent,
    };
  } catch (err) {
    console.error(`Error while sanitizing jsonContent`, err);
  }
};

export const editorHasNoMentionOrEntityOrMessage = editor => {
  if (editor?.isEmpty) return true;

  const inputJson = editor?.getJSON();
  if (!inputJson) return true;

  const { content } = inputJson || {};
  if (!content) return true;
  return !content.some(item => {
    if (item.type === "paragraph") {
      return item.content?.some(
        block =>
          block.type === "mention" ||
          block.type === "entity" ||
          block.type === "message",
      );
    }
  });
};
