import { create } from "zustand";
import { persist } from "zustand/middleware";

const MAX_ENTRIES = 100;

interface BlobProgress {
  signedId: string;
  progress: number;
}

interface MediaPlaybackStore {
  blobs: BlobProgress[];
  saveProgress: (signedId: string, newProgress: number) => void;
  getProgress: (signedId: string) => number;
  deleteProgress: (signedId: string) => void;
}

export const useMediaPlaybackStore = create<MediaPlaybackStore>()(
  persist(
    (set, get) => ({
      blobs: [],
      saveProgress: (signedId, newProgress) => {
        set(state => ({
          blobs: [
            { signedId, progress: newProgress },
            ...state.blobs
              .filter(v => v.signedId !== signedId)
              .slice(0, MAX_ENTRIES - 1),
          ],
        }));
      },
      getProgress: mediaID => {
        const media = get().blobs.find(v => v.signedId === mediaID);
        return media?.progress ?? 0;
      },
      deleteProgress: mediaID => {
        set(state => ({
          blobs: state.blobs.filter(v => v.signedId !== mediaID),
        }));
      },
    }),
    {
      name: "media-progress",
      version: 1,
    },
  ),
);
