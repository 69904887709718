import { useRef } from "react";
import type { Editor } from "@tiptap/react";

interface TextSource {
  getText: () => string;
  insertText: (text: string, position?: number) => void;
  getCursorPosition: () => number;
  textUpToAnchor: () => string;
}

interface UseTextSourceProps {
  editor?: Editor;
  inputRef?: React.RefObject<HTMLInputElement>;
}

export const useTextSource = ({
  editor,
  inputRef,
}: UseTextSourceProps): TextSource | null => {
  const textSource = useRef<TextSource | null>(null);

  if (editor) {
    textSource.current = {
      getText: () => editor.getText(),
      insertText: (text, position = editor.state.selection.anchor) => {
        editor.chain().focus().insertContentAt(position, text).run();
      },
      getCursorPosition: () => editor.state.selection.anchor,
      textUpToAnchor: () =>
        editor.state.doc.textBetween(0, editor.state.selection.anchor, " "),
    };
  } else if (inputRef?.current) {
    const input = inputRef.current;
    textSource.current = {
      getText: () => input.value,
      insertText: text => {
        const start = input.selectionStart ?? 0;
        const end = input.selectionEnd ?? 0;
        const before = input.value.substring(0, start);
        const after = input.value.substring(end);
        input.value = before + text + after;
        // Setting cursor just after the inserted text
        input.setSelectionRange(start + text.length, start + text.length);
        input.focus();
      },
      getCursorPosition: () => (inputRef?.current?.selectionStart ?? 0) + 1,
      textUpToAnchor: () =>
        input.value.substring(0, (inputRef?.current?.selectionStart ?? 0) + 1),
    };
  }

  return textSource.current;
};
