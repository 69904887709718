import { forwardRef, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";

export const AutoExpandingTextInput = forwardRef((props, ref) => {
  const { name, style = {}, ...passThroughProps } = props;
  const textareaRef = useRef(null);

  const { watch, control } = useFormContext();

  const currentValue = watch(name);

  useEffect(() => {
    textareaRef.current.style.height = "inherit";
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
  }, [currentValue]);

  return (
    <div className="react-auto-expanding-text-input form-input">
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <textarea
            {...passThroughProps}
            value={value}
            onChange={event => {
              event.preventDefault();
              onChange(event.target.value.replaceAll("\n", ""));
            }}
            ref={e => {
              textareaRef.current = e;
              if (ref) {
                ref.current = e;
              }
            }}
            className="form-control placeholder:text-light placeholder:opacity-100"
            rows={1}
            style={{
              ...style,
              resize: "none",
            }}
          />
        )}
      />
    </div>
  );
});

AutoExpandingTextInput.displayName = "AutoExpandingTextInput";

AutoExpandingTextInput.propTypes = {
  name: PropTypes.string,
  style: PropTypes.object,
};
