import { useListItemDataProvider } from "@circle-react-shared/uikit/SortableList";

export const OptionPlaceholder = () => {
  const { item }: any = useListItemDataProvider();
  return (
    <div className="h-auto w-full pl-8 pr-10">
      <input
        disabled
        className="border-primary bg-tertiary z-50 w-full rounded-md border-2 border-dashed px-4 py-[11px] leading-[1.25]"
        value={item?.value}
      />
    </div>
  );
};
