import {
  Avatar,
  AvatarContainer,
  Row,
  RowContainer,
} from "@circle-react-uikit/SkeletonLoader/components";

export const FullPostView = () => (
  <div className="skeleton-loader !bg-primary !border-primary h-[636px] w-full max-w-2xl !rounded-2xl border">
    <div className="bg-tertiary h-80 w-full rounded-t-2xl" />
    <div className="grid p-8">
      <AvatarContainer lg>
        <Avatar />
        <RowContainer>
          <Row />
          <Row sm extraLong />
        </RowContainer>
      </AvatarContainer>
      <div className="skeleton-loader--feed-view__body">
        <RowContainer md>
          <Row md full />
          <Row md full />
          <Row md full />
          <Row md almostFull />
        </RowContainer>
      </div>
      <RowContainer inline>
        <Row lg short />
        <Row lg short />
        <Row lg short />
      </RowContainer>
    </div>
  </div>
);
