import { forwardRef, useEffect, useState } from "react";
import { defer, isArray } from "lodash";
import { useInternalEditorState } from "@circle-react-shared/uikit/TipTap/internalEditorState";
import { useBlockEditorContext } from "@circle-react-shared/uikit/TipTapBlockEditor";
import { useTipTapEditorContext } from "@circle-react-uikit/TipTap/index";
import { useKeyboardHandler } from "../hooks/useKeyboardHandler";
import { MentionItem } from "./MentionItem";
import { getMentionScope } from "./helper";
import { useMentions } from "./hooks/useMentions";

export const MentionList = forwardRef(({ query, command }, ref) => {
  const tiptapEditorContext = useTipTapEditorContext();
  const tiptapBlockEditorContext = useBlockEditorContext();
  const {
    addToLocalSgidToObjectMap,
    spaceId,
    chatProps,
    type: editorType,
    isUpdate,
  } = tiptapEditorContext || tiptapBlockEditorContext;

  const { isThread, isDirectMessaging, disableGroupMentions } = chatProps || {};
  const { setPopupsVisible } = useInternalEditorState();

  const { items, isLoading } = useMentions({
    query,
    spaceId: spaceId,
    scope: getMentionScope({
      spaceId,
      isDirectMessaging,
      isThread,
      editorType,
      isUpdate,
      disableGroupMentions,
    }),
  });

  const hasItems = isArray(items) && items.length > 0;

  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = index => {
    const item = items[index];
    if (item) {
      addToLocalSgidToObjectMap({ sgid: item.sgid, object: item });
      command(item);
    }
  };

  useEffect(() => setSelectedIndex(0), [items]);

  useKeyboardHandler({
    selectedIndex,
    setSelectedIndex,
    ref,
    items,
    selectItem,
  });

  useEffect(() => {
    if (!query || isLoading || !hasItems) {
      setPopupsVisible(false);
    } else {
      setPopupsVisible(true);
    }
  }, [hasItems, query, isLoading, setPopupsVisible]);

  if (!hasItems) return null;

  return (
    <div className="bg-primary border-primary relative max-h-[50vh] min-w-[250px] max-w-[320px] overflow-auto rounded border py-1.5 text-sm shadow-md">
      <div className="flex flex-col space-y-0.5">
        {items.map((member, index) => (
          <MentionItem
            key={member.id}
            member={member}
            handleClick={() => {
              selectItem(index);
              defer(() => setPopupsVisible(false));
            }}
            isSelected={index === selectedIndex}
          />
        ))}
      </div>
    </div>
  );
});

MentionList.displayName = "MentionList";
