import type { RichTextOembed } from "@circle-react/types";

const SIZE_SMALL = "small";
const SIZE_LARGE = "large";
const SIZE_VIDEO = "video";
const SIZE_ASPECT_RATIO = "aspect-ratio";

type EmbedSize =
  | typeof SIZE_SMALL
  | typeof SIZE_LARGE
  | typeof SIZE_VIDEO
  | typeof SIZE_ASPECT_RATIO;

// Embed size overrides that improve previews
const EMBED_SIZES: Record<string, EmbedSize> = {
  SoundCloud: SIZE_SMALL,
  GitHub: SIZE_LARGE,
  Brightcove: SIZE_VIDEO,
  Panopto: SIZE_VIDEO,
  Loom: SIZE_VIDEO,
  "Wistia, Inc.": SIZE_VIDEO,
  YouTube: SIZE_VIDEO,
  Arcade: SIZE_ASPECT_RATIO,
  Canva: SIZE_ASPECT_RATIO,
  Genially: SIZE_ASPECT_RATIO,
  Vimeo: SIZE_ASPECT_RATIO,
};

// We're using the height value returned by Embedly to minimize content
// cutoff. It's not ideal, but appears to give the best overall results
// without adding automatic iframe resizing.
//
// We're explicitly adjusting the iframe size with EMBED_SIZES to handle cases
// when Embedly returns inaccurate height values for embeds, e.g., Loom and
// Vimeo, and to avoid stretched videos when rendering 16/9 videos on small
// screens.
//
// TODO: Add support for manual iframe resizing or add a script that handles
// automatic resizing.
export const getIframeSizeProps = ({ embed }: { embed: RichTextOembed }) => {
  const embedSize = embed.provider_name
    ? EMBED_SIZES[embed.provider_name]
    : null;

  if (embedSize === SIZE_SMALL) {
    return { height: 160 };
  }

  if (embedSize === SIZE_LARGE) {
    return { height: 384 };
  }

  if (embedSize === SIZE_VIDEO) {
    return { style: { aspectRatio: "16 / 9", height: "auto" } };
  }

  if (embedSize === SIZE_ASPECT_RATIO) {
    if (embed.height && embed.width) {
      return {
        style: {
          aspectRatio: `${embed.width} / ${embed.height}`,
          height: "auto",
          maxHeight: "700px", // Ensure the embed still fits the viewport
        },
      };
    }

    return { style: { aspectRatio: "16 / 9", height: "auto" } };
  }

  return embed.height ? { height: embed.height } : {};
};
