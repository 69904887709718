import { useEffect, useRef, useState } from "react";

export const useShouldAutoScroll = () => {
  const containerRef = useRef(null);
  const [shouldAutoScroll, setShouldAutoScroll] = useState(true);
  const containerElement = containerRef.current;

  useEffect(() => {
    const handleWheel = () => setShouldAutoScroll(false);

    containerElement?.addEventListener("mousewheel", handleWheel);
    return () =>
      containerElement?.removeEventListener("mousewheel", handleWheel);
  }, [containerElement]);

  return { containerRef, shouldAutoScroll };
};
