import { toLocaleString } from "@circle-react/helpers/number";

interface CounterProps {
  children: number;
}

const MAX_AMOUNT = 10000;

export const numberFormatter = (amount = 0) =>
  amount >= MAX_AMOUNT
    ? `${toLocaleString(MAX_AMOUNT)}+`
    : toLocaleString(amount);

export const Counter = ({ children }: CounterProps) => {
  if (typeof children !== "number") return null;
  const formatted = numberFormatter(children);

  return <span className="ml-2 font-normal">{formatted}</span>;
};
