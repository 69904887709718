import { useEffect } from "react";
import { Node } from "@tiptap/core";
import {
  NodeViewWrapper,
  ReactNodeViewRenderer,
  mergeAttributes,
} from "@tiptap/react";
import classNames from "classnames";
import { useMutation } from "react-query";
import { reactQueryPost } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { Icon } from "@circle-react-shared/Icon";
import { useBlockEditorContext } from "@circle-react-shared/uikit/TipTapBlockEditor";
import { useFailedEmbedsStore } from "@circle-react-shared/uikit/TipTapBlockEditor/EmbedLoader/useFailedEmbedsStore";

export const EmbedLoaderExtension = Node.create({
  name: "embed-loader",
  group: "inline",
  inline: true,
  atom: true,
  addAttributes: () => ({
    href: {
      default: null,
    },
    text: {
      default: null,
    },
  }),
  parseHTML: () => [{ tag: "embed-loader" }],
  renderHTML: ({ HTMLAttributes }) => [
    "embed-loader",
    mergeAttributes(HTMLAttributes),
  ],
  addNodeView: () => ReactNodeViewRenderer(Renderer),
  addKeyboardShortcuts() {
    return {
      Enter: () => {
        const failedLinkEmbeds =
          useFailedEmbedsStore.getState().failedLinkEmbeds;
        const { state } = this.editor;
        if (state.selection.$from.pos > 0) {
          // Marks at the position before the cursor
          const beforeMarks = state.doc
            .resolve(state.selection.$from.pos - 1)
            .marks();
          // Marks at the current cursor position
          const currentMarks = state.doc
            .resolve(state.selection.$from.pos)
            .marks();

          // Check if cursor is at the end of a link
          if (
            beforeMarks.some(mark => mark.type.name === "link") &&
            !currentMarks.some(mark => mark.type.name === "link")
          ) {
            const linkMark = beforeMarks.find(
              mark => mark.type.name === "link",
            );
            const linkAttrs = linkMark.attrs;
            const node = state.doc.nodeAt(state.selection.$from.pos - 1);

            const plainLink = linkAttrs.href === node.text;

            if (
              failedLinkEmbeds.includes(linkAttrs.href) ||
              !node ||
              !linkMark ||
              !plainLink
            ) {
              return false;
            }

            this.editor
              .chain()
              .focus()
              .setTextSelection(state.selection.$from.pos - 1)
              .extendMarkRange("link")
              .deleteSelection()
              .run();

            // We don't want to add this to history because we're going to add the embed-loader node
            this.editor.view.dispatch(
              this.editor.view.state.tr
                .insert(
                  this.editor.view.state.selection.anchor,
                  this.editor.schema.nodes["embed-loader"].create({
                    href: linkAttrs.href,
                    text: node.text,
                  }),
                )
                .setMeta("addToHistory", false),
            );

            return true;
          }
          return false;
        }
      },
    };
  },
});

const Renderer = ({ node, editor, HTMLAttributes, getPos }) => {
  const { addToLocalSgidToObjectMap } = useBlockEditorContext();
  const { addFailedLinkEmbed } = useFailedEmbedsStore();
  const handleDelete = () => {
    const {
      state,
      view: { dispatch },
    } = editor;
    const position = getPos();
    if (!position) return;
    const tr = state.tr.delete(position, position + node.nodeSize);

    // We don't want to add this to history because this is deleting the embed-loader node
    // If we add this to history, it will cause the embed-loader node to be added back when we undo
    dispatch(tr.setMeta("addToHistory", false));
  };
  const createEmbedMutation = useMutation(
    url =>
      reactQueryPost(
        internalApi.richTextFields.createOembed({ params: { url } }),
      ),
    {
      onSuccess: data => {
        handleDelete();
        editor
          .chain()
          .focus()
          .insertContent({
            type: "embed",
            attrs: {
              sgid: data.sgid,
            },
          })
          .run();
        addToLocalSgidToObjectMap({ sgid: data.sgid, object: data.oembed });
      },
      onError: () => {
        addFailedLinkEmbed(node.attrs.href);
        handleDelete();
        editor
          .chain()
          .focus()
          .insertContent({
            type: "text",
            text: node.attrs.text,
            marks: [{ type: "link", attrs: { href: node.attrs.href } }],
          })
          .run();
      },
    },
  );

  const isEditable = editor.isEditable;

  useEffect(() => {
    if (!isEditable) return;
    const url = node.attrs.href;
    createEmbedMutation.mutate(url);

    return () => {
      createEmbedMutation.reset();
    };
  }, []);

  return (
    <NodeViewWrapper as="span" HTMLAttributes={HTMLAttributes}>
      <a
        href={node.attrs.href}
        className={classNames({
          "pointer-events-none mr-px animate-pulse": isEditable,
        })}
        target="_blank"
        rel="noopener noreferrer"
      >
        {node.attrs.text}
      </a>
      {isEditable && <Icon type="loader" className="mb-[3px]" size={14} />}
    </NodeViewWrapper>
  );
};
