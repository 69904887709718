import { fetchEventSource } from "@microsoft/fetch-event-source";
import { useBoolean } from "@circle-react/hooks/utils/useBoolean";

export const useCompletionsStream = () => {
  const [isLoading, toggleIsLoading] = useBoolean(false);

  const createCompletion = async ({
    type,
    previous_text = "",
    user_prompt,
    title,
    onOpen,
    onMessage,
    onClose,
  }) => {
    toggleIsLoading(true);
    await fetchEventSource("/internal_api/copilot/completions", {
      method: "POST",
      body: JSON.stringify({
        context: {
          title,
          previous_text,
          type,
          user_prompt,
        },
      }),
      headers: {
        Accept: "text/event-stream",
        "Content-Type": "application/json",
      },
      onopen(res) {
        if (res.ok && res.status === 200) {
          onOpen();
        } else if (
          res.status >= 400 &&
          res.status < 500 &&
          res.status !== 429
        ) {
          toggleIsLoading(false);
        }
      },
      onmessage(event) {
        const parsedData = JSON.parse(event.data);
        onMessage(parsedData.content);
      },
      onclose() {
        toggleIsLoading(false);
        onClose();
      },
      onerror(e) {
        toggleIsLoading(false);
        throw e;
      },
    });
  };

  return { createCompletion, isLoading };
};
