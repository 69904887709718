import { Badge } from "@circle-react-uikit/Badge";

export interface BadgeComponentProps {
  badge: any;
}

export const BadgeComponent = ({
  badge: { value, ...otherProps },
}: BadgeComponentProps) => (
  <span className="title-badge">
    <Badge {...otherProps}>{value}</Badge>
  </span>
);
