import { create } from "zustand";

interface Store {
  arePopupsVisible: boolean;
  setPopupsVisible: (visible: boolean) => void;
}

export const useInternalEditorState = create<Store>(set => ({
  arePopupsVisible: false,
  setPopupsVisible: isVisible => set({ arePopupsVisible: isVisible }),
}));
