import { Typography } from "@circle-react-shared/uikit/Typography";
import { textGradientStyles } from "./constants";

export const Text = ({ hasGradient, children }) => (
  <Typography.LabelMd
    weight="medium"
    {...(hasGradient && {
      color: textGradientStyles,
    })}
  >
    {children}
  </Typography.LabelMd>
);
