import { useEffect, useRef, useState } from "react";
import { SanitizeHTMLString } from "@circle-react/components/shared/uikit/SanitizeContent";
import { LoaderPrompt } from "../LoaderPrompt";
import { Prompt } from "../Prompt";
import { useAiBlockContext } from "../hooks/useAiBlockContext";
import { useEditionsStream } from "../hooks/useEditionsStream";
import { useFormSubmitDisabler } from "../hooks/useFormSubmitDisabler";
import { PromptOptions } from "./PromptOptions";
import { ResponseOptions } from "./ResponseOptions";

export const EditionPrompt = () => {
  const inputRef = useRef(null);
  const [userPrompt, setUserPrompt] = useState("");
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const { createEdition, isLoading } = useEditionsStream();
  const [preview, setPreview] = useState("");

  const [promptType, setPromptType] = useState("");

  const { node, editor } = useAiBlockContext();
  const { range } = node.attrs;
  const selectedText = editor.state.doc.textBetween(
    range.from,
    range.to,
    "\n",
    ". ",
  );

  const [shouldShowResponseOptions, setShouldShowResponseOptions] =
    useState(false);

  useFormSubmitDisabler({ isLoading });

  useEffect(() => {
    if (!isLoading && preview) {
      setShouldShowResponseOptions(true);
    }
  }, [isLoading, preview]);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (userPrompt) {
      performEdition();
    }
  }, [promptType]);

  const insertCompletion = data => {
    setPreview(prevContent => prevContent + data);
  };

  const onStreamClose = () => {
    setUserPrompt("");
    setPromptType("");
    setIsInputDisabled(false);
    inputRef.current.focus();
  };

  const performEdition = () => {
    if (!userPrompt || isInputDisabled) {
      return;
    }

    setPreview("");
    setIsInputDisabled(true);

    createEdition({
      type: promptType || "improve_writing",
      user_prompt: userPrompt,
      selected_text: selectedText,
      onMessage: insertCompletion,
      onOpen: () => {
        setUserPrompt("");
      },
      onClose: onStreamClose,
    });
  };

  const handleOnSubmit = event => {
    event.stopPropagation();
    event.preventDefault();

    performEdition();
  };

  const handlePromptOptionsClick = (value, label) => {
    setUserPrompt(label);
    setPromptType(value);
  };

  const handleKeyDown = event => {
    //send the message if the user presses enter
    //if the user presses shift + enter, add a new line
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleOnSubmit(event);
    }
  };

  return (
    <div className="bg-primary rounded-md">
      {preview && (
        <div className="select-none p-4">
          <SanitizeHTMLString content={preview} />
        </div>
      )}
      {isLoading ? (
        <LoaderPrompt />
      ) : (
        <form onSubmit={handleOnSubmit}>
          <Prompt>
            <Prompt.AiIcon />
            <Prompt.Input
              ref={inputRef}
              id={`input-${node.attrs?.id}`}
              placeholder={node.attrs?.placeholder}
              onChange={event => setUserPrompt(event.target.value)}
              value={userPrompt}
              onKeyDown={handleKeyDown}
            />
          </Prompt>
        </form>
      )}
      {!userPrompt && !preview && (
        <PromptOptions onClick={handlePromptOptionsClick} />
      )}
      {shouldShowResponseOptions && !userPrompt && (
        <ResponseOptions promptResponse={preview} />
      )}
    </div>
  );
};
