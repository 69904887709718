import type { LinkOptions } from "@tiptap/extension-link";
import Link from "@tiptap/extension-link";

interface CustomizedLinkOptions extends LinkOptions {
  openInternalLinksInCurrentTab?: boolean;
}

const isInternalURL = (url: string): boolean => {
  try {
    const currentHost = window.location.host;
    const urlHost = new URL(url).host;

    return currentHost === urlHost;
  } catch {
    return false;
  }
};

export const CustomizedLink = Link.extend<CustomizedLinkOptions>({
  inclusive: false,
  addOptions() {
    const defaultOptions = this.parent?.();

    return {
      ...defaultOptions,
      openInternalLinksInCurrentTab: false,
    };
  },

  addAttributes() {
    const defaultAttributes = this.parent?.();

    return {
      ...defaultAttributes,
      target: {
        default: this.options.HTMLAttributes.target,
        renderHTML: attributes => {
          const target =
            this.options.openInternalLinksInCurrentTab &&
            isInternalURL(attributes.href)
              ? null
              : attributes.target;

          return { target };
        },
      },
    };
  },

  // This is a customized version of the default link parser, which excludes
  // Trix mentions since they are copied as links from rendered HTML content.
  //
  // See: https://github.com/ueberdosis/tiptap/blob/193b991acc0394305ab9799dbc656e6dbc6d1e11/packages/extension-link/src/link.ts#L119-L121
  parseHTML() {
    return [
      {
        tag: 'a[href]:not([href *= "javascript:" i]):not([class *= "mention__name"])',
      },
    ];
  },
});
