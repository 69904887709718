import type { ComponentPropsWithoutRef } from "react";
import * as RadixSlider from "@radix-ui/react-slider";
import classNames from "classnames";
import { useButtonVariant } from "@circle-react/helpers/useButtonVariant";
import { SliderThumb } from "./SliderThumb";

function Root({
  className,
  ...rest
}: ComponentPropsWithoutRef<typeof RadixSlider.Root>) {
  return (
    <RadixSlider.Root
      className={classNames(
        "relative flex h-1 w-full touch-none select-none items-center rounded-md bg-gray-200",
        className,
      )}
      {...rest}
    />
  );
}

function Track({
  className,
  ...rest
}: ComponentPropsWithoutRef<typeof RadixSlider.Track>) {
  return (
    <RadixSlider.Track
      className={classNames("relative h-1 grow rounded-full", className)}
      {...rest}
    />
  );
}

function Range({
  className,
  ...rest
}: ComponentPropsWithoutRef<typeof RadixSlider.Range>) {
  const primaryButtonVariant = useButtonVariant();

  return (
    <RadixSlider.Range
      className={classNames(
        "absolute h-full rounded-full",
        {
          "bg-brand": primaryButtonVariant === "primary",
          "bg-circle": primaryButtonVariant === "circle",
        },
        className,
      )}
      {...rest}
    />
  );
}

/**
 * Use same props from @radix-ui/react-slider. Only styling has changed.
 * @docs https://radix-ui.com/primitives/docs/components/slider
 */
export const Slider = Object.assign(
  {},
  { Root, Track, Range, Thumb: SliderThumb },
);
