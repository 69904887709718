import { t } from "i18n-js";
import { Button } from "@circle-react-uikit/Button";
import { Form } from "@circle-react-uikit/Form";

const translationRoot = "media_settings";

interface DownloadButtonMenuProps {
  mediaSettings: {
    id: string;
    make_downloadable: boolean;
    custom_thumbnail_signed_id?: string;
  };
  updateMediaSettings: (values: any) => void;
  isUpdatingMediaSettings: boolean;
  closePopover: () => void;
}

export const DownloadButtonMenu = ({
  mediaSettings,
  updateMediaSettings,
  isUpdatingMediaSettings,
  closePopover,
}: DownloadButtonMenuProps) => {
  const getDefaultValues = () => ({
    make_downloadable: mediaSettings.make_downloadable,
    custom_thumbnail: mediaSettings.custom_thumbnail_signed_id,
  });

  const defaultValues = getDefaultValues();

  const onSubmit = (values: any) => {
    updateMediaSettings({ ...values, id: mediaSettings.id });
    closePopover();
  };

  return (
    <Form
      id="download-settings"
      onSubmit={onSubmit}
      defaultValues={defaultValues}
    >
      <Form.Item
        name="make_downloadable"
        translationRoot={translationRoot}
        hideBorder
        fullWidth
        inline
        className="!p-0"
        isDisabled={isUpdatingMediaSettings}
      >
        <Form.ToggleSwitch variant="small" name="make_downloadable" />
      </Form.Item>
      <div className="flex justify-end">
        <Button
          type="submit"
          variant="circle"
          disabled={isUpdatingMediaSettings}
          className="mt-3"
        >
          {t("save")}
        </Button>
      </div>
    </Form>
  );
};
