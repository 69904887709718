import { useImperativeHandle } from "react";

export const useShortcuts = ({
  editor,
  range,
  activeMenuIndex,
  setActiveMenuIndex,
  items,
  ref,
}) => {
  const handleDown = () => {
    if (activeMenuIndex < items.length - 1) {
      setActiveMenuIndex(activeMenuIndex + 1);
    } else {
      setActiveMenuIndex(0);
    }
  };

  const handleUp = () => {
    if (activeMenuIndex > 0) {
      setActiveMenuIndex(activeMenuIndex - 1);
    } else {
      setActiveMenuIndex(items.length - 1);
    }
  };

  const handleEnter = () => items[activeMenuIndex]?.command({ editor, range });

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (["ArrowDown", "ArrowUp", "Enter"].includes(event.key)) {
        event.preventDefault();
        event.stopPropagation();

        if (document.body.requestPointerLock) {
          document.body.requestPointerLock();
        }

        switch (event.key) {
          case "ArrowDown":
            handleDown();
            break;
          case "ArrowUp":
            handleUp();
            break;
          case "Enter":
            handleEnter();

            if (document.exitPointerLock) {
              document.exitPointerLock();
            }

            break;
        }
        return true;
      }
      return false;
    },
  }));
};
