import { useEffect, useRef, useState } from "react";
import { useBlockEditorContext } from "@circle-react-uikit/TipTapBlockEditor";
import { LoaderPrompt } from "../LoaderPrompt";
import { Prompt } from "../Prompt";
import { AI_BLOCK_CONSTANTS } from "../constants";
import { useAiBlockContext } from "../hooks/useAiBlockContext";
import { useCompletionsStream } from "../hooks/useCompletionStream";
import { useFormSubmitDisabler } from "../hooks/useFormSubmitDisabler";
import { EditOptions } from "./EditOptions";

export const DraftPrompt = () => {
  const [userPrompt, setUserPrompt] = useState("");
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const inputRef = useRef(null);
  const { createCompletion, isLoading } = useCompletionsStream();
  const { aiCopilotTitle } = useBlockEditorContext();
  const { editor, node, getPos, setIsLoadingResponse } = useAiBlockContext();

  const [promptType, setPromptType] = useState(
    AI_BLOCK_CONSTANTS.AI_BLOCK_TYPES.HELP_WRITE,
  );

  useFormSubmitDisabler({ isLoading });

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    setIsLoadingResponse(isLoading);
  }, [isLoading, setIsLoadingResponse]);

  const handleOnSubmit = event => {
    event.stopPropagation();
    event.preventDefault();

    if (!userPrompt || isInputDisabled) {
      return;
    }

    setIsInputDisabled(true);

    createCompletion({
      type: promptType,
      title: aiCopilotTitle,
      user_prompt: userPrompt,
      previous_text: editor.state.doc.textBetween(0, getPos(), "\n", ". "),
      onMessage: insertCompletion,
      onOpen: insertEmptyBlock,
      onClose: onStreamClose,
    });
  };

  const onStreamClose = () => {
    setPromptType(AI_BLOCK_CONSTANTS.AI_BLOCK_TYPES.HELP_WRITE);
    setUserPrompt("");
    setIsInputDisabled(false);
    inputRef.current.focus();
  };

  const insertCompletion = data => {
    editor.commands.insertContent(data);
    scrollContainer();
  };

  const insertEmptyBlock = () => {
    editor.commands.insertContentAt(getPos(), "\n");
    setUserPrompt("");
  };

  const addPromptPrefix = (value, label) => {
    setPromptType(value);
    setUserPrompt(label);
    inputRef.current.focus();
  };

  const scrollContainer = () => {
    const containers = document.querySelectorAll(["[data-ai-scrollable]"]);
    containers.forEach(container => {
      container.scrollTop =
        container.scrollTop + AI_BLOCK_CONSTANTS.GENERATED_BLOCK_HEIGHT;
    });
  };

  const handleKeyDown = event => {
    //send the message if the user presses enter
    //if the user presses shift + enter, add a new line
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleOnSubmit(event);
    }
  };

  if (isLoading) {
    return <LoaderPrompt />;
  }

  return (
    <>
      <form onSubmit={handleOnSubmit}>
        <Prompt>
          <Prompt.AiIcon />
          <Prompt.Input
            ref={inputRef}
            id={`input-${node.attrs?.id}`}
            placeholder={node.attrs?.placeholder}
            onChange={event => setUserPrompt(event.target.value)}
            value={userPrompt}
            onKeyDown={handleKeyDown}
          />
        </Prompt>
      </form>
      {!userPrompt && <EditOptions onClick={addPromptPrefix} />}
    </>
  );
};
