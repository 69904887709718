import { Node } from "@tiptap/core";
import { ReactNodeViewRenderer, mergeAttributes } from "@tiptap/react";
import { ImagePreview } from "./ImagePreview";

export const Image = Node.create({
  name: "image",
  group: "block",
  atom: true,
  addAttributes: () => ({
    url: {
      default: null,
    },
    signed_id: {
      default: null,
    },
    content_type: {
      default: null,
    },
    width: {},
    width_ratio: {},
    alignment: {
      default: "center",
    },
  }),
  parseHTML: () => [{ tag: "img-resizable" }],
  renderHTML: ({ HTMLAttributes }) => [
    "img-resizable",
    mergeAttributes(HTMLAttributes),
  ],
  addNodeView() {
    return ReactNodeViewRenderer(ImagePreview);
  },
});
