import { forwardRef, useCallback, useEffect, useState } from "react";
import { defer, flatten, groupBy, isArray } from "lodash";
import { useQuery } from "react-query";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { useInternalEditorState } from "@circle-react-shared/uikit/TipTap/internalEditorState";
import { useBlockEditorContext } from "@circle-react-shared/uikit/TipTapBlockEditor";
import { useTipTapEditorContext } from "@circle-react-uikit/TipTap/index";
import { useKeyboardHandler } from "../hooks/useKeyboardHandler";
import { EmptyState } from "./EmptyState";
import { EntityItem } from "./EntityItem";
import {
  findGroupIndexAndInsideGroupIndex,
  getEntityTypeLabel,
} from "./helpers";

interface Props {
  query: string;
  command: (item: any) => void;
}

export const EntityList = forwardRef(({ query, command }: Props, ref): any => {
  const tiptapEditorContext = useTipTapEditorContext();
  const tiptapBlockEditorContext = useBlockEditorContext();
  const { addToLocalSgidToObjectMap } =
    tiptapEditorContext || tiptapBlockEditorContext;
  const { setPopupsVisible } = useInternalEditorState();

  const [selectedIndex, setSelectedIndex] = useState(0);

  const fetchEntityItems = () =>
    reactQueryGet(
      internalApi.searchV2.advanced.index({
        params: {
          query,
          per_page: 10,
          page: 1,
          type: "entity_list",
        },
      }),
    );

  const { data: items = [], isLoading } = useQuery(
    ["entities", query],
    fetchEntityItems,
    {
      select: ({ records }: any) =>
        records.map((record: any) => ({ ...record, label: record.name })),
      enabled: !!query,
      keepPreviousData: true,
    },
  );
  const hasItems = isArray(items) && items.length > 0;

  const selectItem = (entity: any) => {
    if (entity) {
      addToLocalSgidToObjectMap({
        sgid: entity.sgid,
        object: entity,
      });
      command(entity);
    }
  };

  useEffect(() => {
    if (!query || isLoading || !hasItems) {
      setPopupsVisible(false);
    } else {
      setPopupsVisible(true);
    }
  }, [hasItems, query, isLoading, setPopupsVisible]);

  const entityGroups = groupBy(items, "type");
  const entityGroupsValues = Object.values(entityGroups);
  const groupSizes = entityGroupsValues.map(group => group.length);
  const flattenedData = flatten(entityGroupsValues);

  const checkIfIndexMatches = useCallback(
    (groupIndex: number, itemIndex: number) => {
      const {
        groupIndex: selectedGroupIndex,
        insideGroupIndex: selectedItemIndex,
      } = findGroupIndexAndInsideGroupIndex(selectedIndex, groupSizes);
      return (
        selectedGroupIndex === groupIndex && selectedItemIndex === itemIndex
      );
    },
    [selectedIndex, groupSizes],
  );

  useKeyboardHandler({
    selectedIndex,
    setSelectedIndex,
    ref,
    items: flattenedData,
    selectItem: (index: any) => {
      const item = flattenedData[index];
      selectItem(item);
    },
  });

  return (
    <div className="bg-primary border-primary relative max-h-[550px] min-w-[250px] max-w-[320px] space-y-6 overflow-auto rounded-lg border pt-4 text-sm shadow-md">
      {items.length ? (
        <>
          {entityGroupsValues.map((group, groupIndex) => {
            const type = group[0].type;
            return (
              <div key={type} className="flex flex-col">
                <div className="px-6 pb-2">
                  <Typography.LabelSm color="text-light" weight="medium">
                    {getEntityTypeLabel(type)}
                  </Typography.LabelSm>
                </div>
                {group.map((entity, itemIndex) => (
                  <EntityItem
                    key={entity.id}
                    entity={entity}
                    handleClick={() => {
                      selectItem(entity);
                      defer(() => setPopupsVisible(false));
                    }}
                    isSelected={checkIfIndexMatches(groupIndex, itemIndex)}
                  />
                ))}
              </div>
            );
          })}
        </>
      ) : (
        <EmptyState isLoading={isLoading} query={query} />
      )}
    </div>
  );
});

EntityList.displayName = "EntityList";
